import React, { useState } from 'react'
import styled from 'styled-components'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import ContactForm from 'components/modals/ContactForm'
import Main from '../Index/Main'
import Start from './start'
import Work from './work'
import Next from './next'
import Footer from '../Footer'
import Header from '../Header'

const Rho: React.FunctionComponent = () => {
  const [formModalOpen, setFormModalOpen] = useState(false)

  const openModal = (): void => {
    setFormModalOpen(true)
  }

  return (
    <Root>
      <ContactForm
        active={formModalOpen}
        onClose={() => setFormModalOpen(false)}
      />
      <Header isMain={false} onClick={() => openModal()} />
      <PageWrapper>
        <Content>
          {' '}
          <Main isMain={false} />
          <Start />
          <Work />
          <Next />
          <Footer onClick={() => openModal()} />
        </Content>
      </PageWrapper>
    </Root>
  )
}

export default Rho

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #272a32;
  overflow: hidden;
`

const Content = styled.div`
  width: 100%;
`
