import React from 'react'
import GlobalStyle from './theme/globalStyle'
import GlobalFonts from './theme/fonts'
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme'
import Router from './pages/Router'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GlobalFonts />
      <Router />
    </ThemeProvider>
  )
}

export default App
