import React from 'react'
import styled, { css } from 'styled-components'
import bg from 'images/rho/work/bg.svg'
import bgSm from 'images/rho/work/bg-sm.svg'
import arrowImg from 'images/rho/work/arrow.svg'
import rectImg from 'images/rho/work/rect.svg'
import textBg from 'images/rho/work/text-bg.svg'
import textBgMd from 'images/rho/work/text-bg-md.svg'

const Work: React.FunctionComponent = () => {
  return (
    <Root>
      <Cont>
        <RedTitle>Reverse Hackathon Offering </RedTitle>
        <Title>HOW DO RHOs WORK?</Title>
        <List>
          <Item>
            <TextBlock>
              <Number>1</Number>
              <Text isBig={true}>
                <TextBg />
                AlgoDAO opens a <span>$150,000</span> RHO Pool;
              </Text>
            </TextBlock>
            <TextBlock>
              <Number>2</Number>
              <Text>
                Community members subscribe to buy RHO tokens at $0.15 per
                token;
              </Text>
            </TextBlock>
            <TextBlock>
              <Number>3</Number>
              <Text>RHO Token sale ends successfully;</Text>
              <Arrow />
            </TextBlock>
          </Item>
          <Item>
            <TextBlock>
              <Number>4</Number>
              <Text>
                AlgoDAO uses raised funds to organize the RHO hackathon event,
                which ends in a big DEMO DAY with three (3) winning projects;
              </Text>
            </TextBlock>
            <TextBlock>
              <Number>5</Number>
              <Text>
                The three (3) winning projects receive prize monies and go
                through AlgoDAO’s incubation in preparation for IDO;
              </Text>
            </TextBlock>
            <Arrow />
          </Item>
          <Item>
            <TextBlock>
              <Number>6</Number>
              <Text>
                The three (3) incubated projects allocate 2% of their token
                supply to RHO investors;
              </Text>
            </TextBlock>
            <TextBlock>
              <Number>7</Number>
              <Text>The three (3) incubated projects have their IDOs;</Text>
            </TextBlock>
            <TextBlock>
              <Number>8</Number>

              <Text>
                RHO investors receive a pro-rata share of the 2%* of tokens from
                the three (3) projects.
              </Text>
            </TextBlock>
            <Rect />
          </Item>
        </List>
        <SmallText>
          *RHO token distribution is subject to vesting schedules which are
          communicated to investors before initial subscription.
        </SmallText>
      </Cont>
    </Root>
  )
}

export default Work

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 113px;
  background: #272a32;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-top: 99px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-top: 93px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-top: 73px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-top: 54px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-top: 48px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding-top: 36px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-top: 38px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1965px;
    height: 1466px;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    background: url('${bg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 1720px;
      height: 1283px;
      top: -79px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 1474px;
      height: 1099px;
      top: -59px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 1269px;
      height: 946px;
      top: -58px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 941px;
      height: 702px;
      top: -43px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 660px;
      height: 687px;
      top: -20px;
      background: url('${bgSm}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      content: unset;
    }
  }
`

const Cont = styled.div`
  width: 1470px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1287px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1103px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 949px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 704px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 561px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 335px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const RedTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #dc4c4c;
  margin-bottom: 9px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 18px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 16px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 14px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 13px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 82px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 65px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 72px;
    margin-bottom: 57px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 62px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 54px;
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 40px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 28px;
  }
`

const List = styled.div`
  position: relative;
  display: flex;
  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
  }
  &:before {
    content: '';
    position: absolute;
    left: 100%;
    top: -30px;
    height: 571px;
    width: 219px;
    background: linear-gradient(
      180deg,
      rgba(244, 244, 244, 0) 0%,
      #f4f4f4 77.6%
    );
    opacity: 0.05;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -27px;
      height: 500px;
      width: 192px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -22px;
      height: 428px;
      width: 164px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -19px;
      height: 369px;
      width: 141px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -14px;
      height: 274px;
      width: 105px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      content: unset;
    }
  }
`

const Number = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 37px;
  line-height: 36px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #dc594d;
  margin-bottom: 8px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 32px;
    line-height: 31px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 28px;
    line-height: 27px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 24px;
    line-height: 23px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 3px;
  }
`

const Text = styled.div<{ isBig?: boolean }>`
  position: relative;
  width: 350px;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 22px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 50px;
  z-index: 2;
  span {
    padding-left: 5px;
    z-index: 2;
    ${({ theme }) => theme.adaptive.index.md} {
      padding: 0 7px;
    }
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 306px;
    font-size: 19px;
    margin-bottom: 44px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 263px;
    font-size: 16px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 226px;
    font-size: 14px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 168px;
    font-size: 12px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 168px;
    margin-bottom: 0;
    margin-right: 15px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 30px;
    margin-right: 0;
    font-size: 14px;
    width: 100% !important;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 12px;
  }
  ${({ isBig }) => {
    if (isBig) {
      return css`
        font-size: 28px;
        ${({ theme }) => theme.adaptive.index.xl} {
          font-size: 25px;
        }
        ${({ theme }) => theme.adaptive.index.lg} {
          font-size: 21px;
        }
        ${({ theme }) => theme.adaptive.index.slg} {
          font-size: 18px;
        }
        ${({ theme }) => theme.adaptive.index.md} {
          width: 163px;
          font-size: 14px;
        }
        ${({ theme }) => theme.adaptive.index.sm} {
          width: 163px;
        }
        ${({ theme }) => theme.adaptive.index.smm} {
          height: auto;
          width: 100%;
        }
      `
    }
  }}
`

const TextBg = styled.div`
  position: absolute;
  width: 128px;
  height: 36px;
  top: 2px;
  right: 3px;
  background: url('${textBg}') center no-repeat;
  background-size: cover;
  z-index: -1;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 112px;
    height: 32px;
    top: 2px;
    right: -1px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 96px;
    height: 27px;
    top: 2px;
    right: 2px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 83px;
    height: 23px;
    top: 1px;
    right: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 68px;
    height: 18px;
    top: 19px;
    right: unset;
    left: 0;
    background: url('${textBgMd}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 67px;
    height: 17px;
    top: 2px;
    right: unset;
    left: 111px;
    background: url('${textBg}') center no-repeat;
    background-size: cover;
  }
`

const Item = styled.div`
  position: relative;
  width: 490px;
  height: 550px;
  padding: 64px 61px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 429px;
    height: 482px;
    padding: 56px 53px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 368px;
    height: 413px;
    padding: 48px 45px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 316px;
    height: 355px;
    padding: 41px 39px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 235px;
    height: 263px;
    padding: 24px 30px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    display: flex;
    width: 561px;
    height: 118px;
    padding: 24px 0 0 30px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    display: block;
    height: auto;
    width: 100%;
    padding: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px 0 0 20px;
    background: linear-gradient(
        180.21deg,
        rgba(149, 149, 149, 0) 72.07%,
        rgba(155, 155, 155, 0.03) 99.82%
      ),
      #e4e4e4;
    opacity: 0.15;
    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 20px 0 0 20px !important;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      background: transparent !important;
      border-radius: 0;
    }
  }
  &:nth-child(2) {
    top: 20px;
    height: 555px;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: 18px;
      height: 486px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: 15px;
      height: 416px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: 13px;
      height: 358px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: 10px;
      height: 266px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: 0;
      height: 118px;
      ${Text} {
        width: 234px;
      }
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      height: auto;
      width: 100%;
    }
    &:before {
      border-radius: 0 0 0 20px;
      background: linear-gradient(
          180.2deg,
          rgba(149, 149, 149, 0) 72.08%,
          rgba(155, 155, 155, 0.02) 99.83%
        ),
        #f4f4f4;
      opacity: 0.05;
      ${({ theme }) => theme.adaptive.index.sm} {
        background: transparent !important;
      }
    }
  }
  &:nth-child(3) {
    height: 595px;
    ${({ theme }) => theme.adaptive.index.xl} {
      height: 521px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      height: 446px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      height: 384px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      height: 285px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      height: 125px;
      ${Text} {
        width: 150px;
      }
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      height: auto;
      width: 100%;
    }
    &:before {
      border-radius: 0 0 20px 20px;
    }
  }
`

const SmallText = styled.div`
  position: relative;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #5c5e63;
  margin-top: 17px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 519px;
    font-size: 12px;
    margin-top: 32px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    margin-top: 30px;
  }
`

const Arrow = styled.div`
  position: absolute;
  bottom: 33px;
  left: 402px;
  width: 55px;
  height: 7px;
  background: url('${arrowImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.index.xl} {
    bottom: 29px;
    left: 352px;
    width: 49px;
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    bottom: 25px;
    left: 302px;
    width: 43px;
    height: 7px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    bottom: 21px;
    left: 260px;
    width: 37px;
    height: 5px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    bottom: 16px;
    left: 193px;
    width: 27px;
    height: 4px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const Rect = styled.div`
  position: absolute;
  bottom: 30px;
  right: 41px;
  width: 14px;
  height: 5px;
  background: url('${rectImg}') center no-repeat;
  background-size: contain;
  ${({ theme }) => theme.adaptive.index.xl} {
    bottom: 27px;
    right: 36px;
    width: 12px;
    height: 5px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    bottom: 23px;
    right: 31px;
    width: 10px;
    height: 4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    bottom: 20px;
    right: 27px;
    width: 9px;
    height: 3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    bottom: 15px;
    right: 20px;
    width: 7px;
    height: 2px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const TextBlock = styled.div``
