import React from 'react'
import styled from 'styled-components'
import teamList from 'store/teamList'
import defaultPhoto from 'images/index/team/core/default-photo.jpg'
import defaultAdvisorPhoto from 'images/index/team/dao/default-photo.jpg'
import { ReactComponent as LinkedInImg } from 'images/index/team/linked-in-icon.svg'
import { ReactComponent as TwitterImg } from 'images/index/team/twitter-icon.svg'
import { ReactComponent as LinkedInWhite } from 'images/index/team/linked-in-white.svg'
import bg from 'images/index/team/core/bg.svg'
import bgAdvisory from 'images/index/team/dao/bg.svg'
import bgAdvisorySm from 'images/index/team/dao/bg-sm.svg'
import { Container } from 'theme/theme'
import Slider from 'react-slick'
import useWindowSize from 'helpers/utils/useWindowSize'

const Team: React.FunctionComponent = () => {
  const { width } = useWindowSize()
  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    rows: 2,
    variableWidth: true,
  }

  const settingsOneRow = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  const CoreListItems = teamList.Core.map((item, idx) => {
    return (
      <Person key={`${idx}PersonSlide`}>
        <Photo src={item.img ? item.img : defaultPhoto} />
        <Info>
          <Name>{item.name}</Name>
          {/*<Role>{item.role}</Role>*/}
          {item.link && (
            <LinkedIn href={item.link} target="_blank">
              <LinkedInIcon>
                <LinkedInImg />
              </LinkedInIcon>
              <LinkedInText>LinkedIn</LinkedInText>
            </LinkedIn>
          )}
        </Info>
      </Person>
    )
  })

  const CoreSlider = (
    <AssociationSliderWrap>
      <Slider {...settings} className="association-slider">
        {CoreListItems}
      </Slider>
    </AssociationSliderWrap>
  )

  const CoreList = <List> {CoreListItems}</List>

  const DaoItems = teamList.Dao.map((item, idx) => {
    return (
      <Person key={`${idx}CoreItem`}>
        <Photo src={item.img ? item.img : defaultAdvisorPhoto} />
        <Info>
          <Name>{item.name}</Name>
          <Role>Advisor</Role>
          <Role>{item.role}</Role>
          {item.link && (
            <LinkedIn href={item.link} target="_blank">
              <LinkedInIcon>
                <LinkedInWhite />
              </LinkedInIcon>
              <LinkedInText>LinkedIn</LinkedInText>
            </LinkedIn>
          )}
        </Info>
      </Person>
    )
  })

  const DaoSlider = (
    <AdvisorySliderWrap>
      <Slider {...settingsOneRow} className="advisory-slider">
        {DaoItems}
      </Slider>
    </AdvisorySliderWrap>
  )

  const DaoList = <List>{DaoItems}</List>

  const AmbassadorsItems = teamList.Ambassadors.map((item, idx) => {
    return (
      <Person key={`${idx}AmbassadorsItem`}>
        <Photo src={item.img} />
        <Info>
          <Name>{item.name}</Name>
          {item.telegram && (
            <Twitter href={item.telegramLink} target="_blank">
              <TwitterIcon>
                <TwitterImg />
              </TwitterIcon>
              <TwitterText>{item.telegram}</TwitterText>
            </Twitter>
          )}
        </Info>
      </Person>
    )
  })

  const AmbassadorsSlider = (
    <AdvisorySliderWrap>
      <Slider {...settingsOneRow} className="advisory-slider">
        {AmbassadorsItems}
      </Slider>
    </AdvisorySliderWrap>
  )

  const AmbassadorsList = <List>{AmbassadorsItems}</List>

  return (
    <Root>
      <Container>
        <Title>Team</Title>
        <Core>
          <SubTitle>CORE Contributors</SubTitle>
          {(() => {
            if (width <= 900) {
              return CoreSlider
            }
            return CoreList
          })()}
        </Core>
        <Dao>
          <SubTitle>Core DAO Contributors</SubTitle>
          {(() => {
            if (width <= 900) {
              return DaoSlider
            }
            return DaoList
          })()}
        </Dao>
        <Ambassadors>
          <SubTitle>Ambassadors</SubTitle>
          {(() => {
            if (width <= 900) {
              return AmbassadorsSlider
            }
            return AmbassadorsList
          })()}
          <AdvisorBg />
        </Ambassadors>
      </Container>
    </Root>
  )
}

export default Team

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 69px;
  margin-top: 164px;
  z-index: 1;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 0 0 60px;
    margin-top: 145px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 0 0 51px;
    margin-top: 124px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 0 0 44px;
    margin-top: 106px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 0 0 32px;
    margin-top: 61px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 0 0 58px;
    margin-top: 108px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 0 0 55px;
    z-index: 2;
    margin-top: 121px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 0 0 37px;
    margin-top: 81px;
  }
  &:before {
    content: '';
    position: absolute;
    top: -550px;
    left: 50%;
    transform: translateX(-50%);
    width: 4100px;
    height: 1167px;
    background: url('${bg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -460px;
      width: 3587px;
      height: 1021px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -400px;
      width: 3075px;
      height: 875px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -330px;
      width: 2648px;
      height: 754px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -260px;
      width: 1964px;
      height: 559px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: -55px;
      width: 1712px;
      height: 487px;
      left: -402px;
      transform: unset;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      top: -50px;
      left: -343px;
      width: 1461px;
      height: 416px;
    }
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 68px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #272a32;
  margin-bottom: 59px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
    margin-bottom: 53px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 51px;
    margin-bottom: 44px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 44px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 41px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 38px;
    margin-bottom: 30px;
    max-width: 330px;
    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      height: 200px;
      top: -200px;
      background: #fafafa;
      z-index: -1;
    }
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 33px;
    margin-bottom: 21px;
    max-width: 280px;
  }
  span {
    color: #dc4c4c;
  }
`

const Core = styled.div`
  position: relative;
  padding-bottom: 78px;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-bottom: 85px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-bottom: 81px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-bottom: 70px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-bottom: 52px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-bottom: 26px;
  }
`

const SubTitle = styled.div`
  font-family: 'MMC';
  font-size: 42px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #272a32;
  margin-bottom: 60px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 37px;
    margin-bottom: 52px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 31px;
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 27px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 20px;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 17px;
    margin-bottom: 25px;
  }
`

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Photo = styled.img`
  display: block;
  width: 140px;
  height: 140px;
  border-radius: 5px;
  margin-right: 31px;
  filter: grayscale(100%);
  transition: 0.4s;
  object-fit: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 122px;
    height: 122px;
    border-radius: 3px;
    margin-right: 28px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 104px;
    height: 104px;
    border-radius: 3px;
    margin-right: 24px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 90px;
    height: 90px;
    border-radius: 2px;
    margin-right: 21px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 66px;
    height: 66px;
    border-radius: 2px;
    margin-right: 15px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 90px;
    height: 90px;
    border-radius: 2px;
    margin-right: 21px;
    filter: unset;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 77px;
    height: 77px;
    border-radius: 2px;
    margin-right: 17px;
  }
`

const Info = styled.div`
  font-family: 'MMC';
  width: 280px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 253px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 218px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 188px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 135px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 177px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 151px;
  }
`

const Name = styled.div`
  font-size: 33px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #272a32;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 30px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 26px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 16px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 18px;
  }
`

const Role = styled.div`
  font-size: 20px;
  line-height: 135%;
  color: #272a32;
  opacity: 0.5;
  margin-bottom: 29px;
  height: 27px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
    margin-bottom: 25px;
    height: 23px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
    margin-bottom: 21px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    margin-bottom: 19px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-bottom: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
    margin-bottom: 18px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 10px;
    margin-bottom: 15px;
    height: 14px;
  }
`

const LinkedIn = styled.a`
  display: flex;
  align-items: center;
  outline: none;
`

const LinkedInIcon = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #272a32;
    transition: 0.4s;
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 8px;
    height: 8px;
    margin-right: 4px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 9px;
    height: 9px;
    margin-right: 4px;
  }
`

const LinkedInText = styled.div`
  font-family: 'Aldrich';
  font-size: 14px;
  line-height: 14px;
  color: #272a32;
  opacity: 0.5;
  margin-top: 4px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 13px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 11px;
    line-height: 11px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 10px;
    line-height: 10px;
    margin-top: 3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 7px;
    line-height: 7px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 10px;
    line-height: 10px;
    margin-top: 3px;
  }
`

const Person = styled.div`
  position: relative;
  display: flex !important;
  margin-right: 107px;
  margin-bottom: 71px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 78px;
    margin-bottom: 63px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 66px;
    margin-bottom: 55px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 57px;
    margin-bottom: 47px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 46px;
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin: 0 0 35px 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 0 0 30px 0;
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:hover {
    ${Photo} {
      filter: unset;
    }
    ${LinkedIn} {
      svg {
        fill: #d55054;
      }
    }
    ${LinkedInText} {
      color: #d55054;
      opacity: 0.5;
    }
  }
`

const Dao = styled.div`
  position: relative;
  padding-bottom: 93px;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-bottom: 92px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-bottom: 76px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-bottom: 66px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-bottom: 47px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-bottom: 37px;
  }
  ${SubTitle} {
    color: #ffffff;
  }
  ${Person} {
    margin-right: 88px;
    margin-bottom: 0;
    ${({ theme }) => theme.adaptive.index.xl} {
      margin-right: 75px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      margin-right: 62px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      margin-right: 42px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      margin-right: 16px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      margin: 0;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      margin: 0;
    }
    &:nth-child(3n) {
      margin-right: 88px;
      ${({ theme }) => theme.adaptive.index.xl} {
        margin-right: 77px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        margin-right: 62px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        margin-right: 42px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        margin-right: 16px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        margin: 0;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        margin: 0;
      }
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  ${Photo} {
    border-radius: 11px;
    width: 140px;
    margin-right: 30px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 123px;
      margin-right: 26px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      border-radius: 9px;
      width: 105px;
      margin-right: 23px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 6px;
      width: 90px;
      margin-right: 20px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 66px;
      margin-right: 15px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 90px;
      margin-right: 20px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 77px;
      margin-right: 17px;
    }
  }
  ${Info} {
    width: 206px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 179px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 155px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 142px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 116px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 155px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 132px;
    }
  }
  ${Name} {
    font-size: 26px;
    line-height: 135%;
    color: #ffffff;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 23px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 20px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 17px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 13px;
      margin-bottom: 2px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      font-size: 17px;
      margin-bottom: 3px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      font-size: 15px;
      margin-bottom: 2px;
    }
  }
  ${Role} {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 0;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 13px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 11px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 10px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 9px;
    }
    &:nth-child(3) {
      font-weight: 600;
      margin-bottom: 19px;
      ${({ theme }) => theme.adaptive.index.xl} {
        margin-bottom: 17px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        margin-bottom: 14px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        margin-bottom: 12px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        margin-bottom: 7px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        margin-bottom: 9px;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        margin-bottom: 8px;
      }
    }
  }
  ${LinkedInText} {
    color: #ffffff;
  }
`

const AdvisorBg = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 4589px;
  height: 1099px;
  background: url('${bgAdvisory}') center no-repeat;
  background-size: contain;
  z-index: -1;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 3537px;
    height: 718px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 3032px;
    height: 608px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 2610px;
    height: 521px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 1937px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 818px;
    height: 462px;
    background: url('${bgAdvisorySm}') center no-repeat;
    background-size: contain;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 702px;
    height: 380px;
  }
`

const sliderCss = styled.div`
  width: 330px;
  position: relative;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const AssociationSliderWrap = styled.div`
  width: 324px;
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 262px;
  }
  ${sliderCss};
  .association-slider {
    div {
      outline: none;
    }
    .slick-slide {
      padding-right: 24px;
      ${({ theme }) => theme.adaptive.index.xs} {
        padding-right: 20px;
      }
    }
    .slick-track {
      display: flex;
    }
  }
`

const AdvisorySliderWrap = styled.div`
  ${sliderCss};
  .advisory-slider {
    .slick-slide {
      padding-right: 11px;
      ${({ theme }) => theme.adaptive.index.xs} {
        padding-right: 9px;
      }
    }
    .slick-list {
      padding-left: 0 !important;
    }

    .slick-track {
      display: flex;
    }
  }
`

const Ambassadors = styled.div`
  position: relative;
  padding-bottom: 91px;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-bottom: 131px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-bottom: 118px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-bottom: 101px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-bottom: 122px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-bottom: 108px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding-bottom: 88px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-bottom: 86px;
  }
  ${SubTitle} {
    color: #ffffff;
  }
  ${Person} {
    margin-right: 40px;
    margin-bottom: 0;
    ${({ theme }) => theme.adaptive.index.xl} {
      margin-right: 35px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      margin-right: 30px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      margin-right: 26px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      margin-right: 20px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      margin: 0;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      margin: 0;
    }
    &:nth-child(3n) {
      margin-right: 40px;
      ${({ theme }) => theme.adaptive.index.xl} {
        margin-right: 35px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        margin-right: 30px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        margin-right: 26px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        margin-right: 20px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        margin: 0;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        margin: 0;
      }
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  ${Photo} {
    border-radius: 5px;
    width: 80px;
    height: 80px;
    margin-right: 30px;
    ${({ theme }) => theme.adaptive.index.xl} {
      border-radius: 4px;
      width: 70px;
      height: 70px;
      margin-right: 26px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 60px;
      height: 60px;
      margin-right: 23px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 3px;
      width: 52px;
      height: 52px;
      margin-right: 20px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      border-radius: 2px;
      width: 38px;
      height: 38px;
      margin-right: 10px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 3px;
      width: 52px;
      height: 52px;
      margin-right: 20px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 45px;
      height: 45px;
      margin-right: 17px;
    }
  }
  ${Info} {
    width: 170px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 166px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 138px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 130px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 102px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 130px;
    }
  }
  ${Name} {
    font-size: 26px;
    line-height: 135%;
    color: #ffffff;
    margin-bottom: 12px;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 23px;
      margin-bottom: 11px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 20px;
      margin-bottom: 9px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 17px;
      margin-bottom: 8px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 13px;
      margin-bottom: 6px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      font-size: 17px;
      margin-bottom: 8px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      font-size: 15px;
      margin-bottom: 7px;
    }
  }
`

const TwitterIcon = styled.div`
  width: 16px;
  height: 14px;
  margin-right: 10px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 15px;
    height: 12px;
    margin-right: 9px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 12px;
    height: 10px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 10px;
    height: 9px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 8px;
    height: 6px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 10px;
    height: 9px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 9px;
    height: 8px;
    margin-right: 5px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: #ffffff;
    transition: 0.4s;
  }
`

const TwitterText = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 15px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 13px;
    line-height: 13px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 12px;
    line-height: 12px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 12px;
  }
`

const Twitter = styled.a`
  display: flex;
  align-items: center;
  outline: none;
  &:hover {
    ${TwitterText} {
      svg {
        fill: #d55054;
      }
      ${TwitterText} {
        color: #d55054;
        opacity: 0.5;
      }
    }
  }
`
