import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse'
import bg from 'images/starHamster/bg.jpg'
import hamster1 from 'images/starHamster/hamsters/ham1.png'
import hamster2 from 'images/starHamster/hamsters/ham2.png'
import hamster3 from 'images/starHamster/hamsters/ham3.png'
import Logo from 'components/Icons/Logo'
import { Stage } from 'canvas/hole'
import Success from './Success'
import { generateRandomColor, randomBetween } from 'utils/generate'
import useWindowSize from 'utils/useWindowSize'
import Player from './Player'

var stage = new Stage('hole', {
  alpha: true,
  antialias: true,
  precision: 'mediump',
})

const PIXEL_SIZE = 3

function StartHamster() {
  const [mainLoaded, setMainLoaded] = useState(false)

  const { width, height } = useWindowSize()
  const [hamsterHidden, setHasmterHidden] = useState(false)
  const [animStarted, setAnimStarted] = useState(false)
  const [randomColor, setRandomColor] = useState('')
  const [pixelHidden, setPixelHidden] = useState(true)
  const [pixelPosition, setPixelposition] = useState({
    x: 0,
    y: 0,
  })
  const holeWrapperRef = useRef(null)

  const TimeoutPixel = () => {
    if (animStarted) return
    setTimeout(() => setPixelHidden(false), 30000)
  }

  useEffect(() => {
    TimeoutPixel()
  }, [])

  useEffect(() => {
    if (!pixelHidden) {
      const interval = setInterval(() => {
        setRandomColor(generateRandomColor())
      }, 100)
      setTimeout(() => {
        clearInterval(interval)
        setPixelHidden(true)
        TimeoutPixel()
      }, 10000)
      setPixelposition({
        x: randomBetween(width / 2, width - PIXEL_SIZE),
        y: randomBetween(height / 2, height - PIXEL_SIZE),
      })
    }
  }, [pixelHidden])

  const startAnimation = () => {
    if (animStarted) return
    setAnimStarted(true)
    setPixelHidden(true)
    window.addEventListener('resize', (e) => {
      stage.onResize(e)
    })
    if (null === holeWrapperRef.current) return
    stage.init(holeWrapperRef.current)
    stage.hyperStart()
    setTimeout(() => {
      stage.hyperStop()
    }, 2500)
    setTimeout(() => {
      setHasmterHidden(true)
    }, 3000)
  }
  return (
    <Root>
      <Hamster hiddenAlpha={hamsterHidden}>
        <MouseParallaxContainer className="parallax-container">
          <MouseParallaxChild
            updateStyles={{ position: 'absolute' }}
            factorX={0.04}
            factorY={0.04}
          >
            <HamsterImage src={hamster2} alt="" />
          </MouseParallaxChild>
          <MouseParallaxChild
            updateStyles={{ position: 'absolute' }}
            factorX={0.035}
            factorY={0.035}
          >
            <HamsterImage src={hamster3} alt="" />
          </MouseParallaxChild>
          <MouseParallaxChild factorX={0.03} factorY={0.03}>
            <HamsterImage
              src={hamster1}
              alt="Start Hamster"
              onLoad={() => setMainLoaded(true)}
            />
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </Hamster>
      <LogoWrapper>
        <Logo />
        <LogoText>Algorithms of the Future</LogoText>
      </LogoWrapper>
      {width > 900 && !animStarted && <Player animStarted={animStarted} />}
      <HoleWrapper ref={holeWrapperRef} />
      <AnimationLayer hiddenAlpha={!hamsterHidden}>
        {hamsterHidden && (
          <SuccessWrapper>
            <Success />
          </SuccessWrapper>
        )}
      </AnimationLayer>
      {!pixelHidden && (
        <Pixel
          style={{
            backgroundColor: randomColor,
            top: pixelPosition.y,
            left: pixelPosition.x,
          }}
          onClick={startAnimation}
        />
      )}
    </Root>
  )
}

export default StartHamster

const Root = styled.div`
  position: relative;
  background: url(${bg}) no-repeat right top;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* #mouse-parallax-container {
    height: 100%;
  } */
  .parallax-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }
`

const Pixel = styled.div`
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  width: ${PIXEL_SIZE}px;
  height: ${PIXEL_SIZE}px;
  z-index: 10;
`

const Hamster = styled.div<{ hiddenAlpha: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  transition: 1.5s ease;
  opacity: ${({ hiddenAlpha }) => (hiddenAlpha ? '0' : '1')};
  transform: scale(${({ hiddenAlpha }) => (hiddenAlpha ? '0' : '1')});
  /* position: absolute;
  width: 50%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%); */
`

const HamsterImage = styled.img`
  /* position: absolute;
  width: 100%;
  left: 0;
  top: 0; */
  /* &:last-child {
    position: relative;
    z-index: 2;
  } */
  width: 762px;
  height: 651px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 635px;
    height: 543px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 571px;
    height: 488px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 501px;
    height: 434px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 406px;
    height: 347px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 508px;
    height: 434px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 351px;
    height: 300px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 274px;
    height: 234px;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 80px;
  top: 64px;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.index.xl} {
    left: 67px;
    top: 53px;
    width: 244px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    left: 60px;
    top: 48px;
    width: 219px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    left: 53px;
    top: 43px;
    width: 195px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    left: 43px;
    top: 34px;
    width: 156px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    left: 50%;
    top: 43px;
    transform: translateX(-50%);
    width: 244px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    top: 34px;
    width: 195px;
  }
  svg,
  path {
    fill: rgba(255, 255, 255, 0.75) !important;
  }
  svg {
    display: block;
    width: 100%;
  }
`

const LogoText = styled.div`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 20px;
  opacity: 0.75;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    line-height: 17px;
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 14px;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 11px;
    line-height: 13px;
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 9px;
    line-height: 11px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 10px;
    line-height: 12px;
    margin-top: 7px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 12px;
    line-height: 14px;
    margin-top: 0;
  }
`

const HoleWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const SuccessWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AnimationLayer = styled.div<{ hiddenAlpha: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 1.4s ease;
  z-index: 5;
  opacity: ${({ hiddenAlpha }) => (hiddenAlpha ? '0' : '1')};
  transform: scale(${({ hiddenAlpha }) => (hiddenAlpha ? '0' : '1')});
`
