import React, { useState } from 'react'
import styled from 'styled-components'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import ContactForm from 'components/modals/ContactForm'
import Main from './Main'
import About from './About'
import Team from './Team'
import Contact from './Contact'
import Footer from '../Footer'
import Hierarchy from './Hierarchy'
import Header from '../Header'

const Index: React.FunctionComponent = () => {
  const [formModalOpen, setFormModalOpen] = useState(false)

  const openModal = (): void => {
    setFormModalOpen(true)
  }

  return (
    <Root>
      <ContactForm
        active={formModalOpen}
        onClose={() => setFormModalOpen(false)}
      />
      <Header isMain={true} onClick={() => openModal()} />
      <PageWrapper>
        <Content>
          <Main isMain={true} />
          <About />
          <Hierarchy />
          <Team />
          <Contact />
          <Footer onClick={() => openModal()} />
        </Content>
      </PageWrapper>
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  background: #272a32;
  overflow: hidden;
`

const Content = styled.div`
  width: 100%;
`
