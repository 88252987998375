import React from 'react'

const Logo: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="294"
      height="29"
      viewBox="0 0 294 29"
      fill="none"
    >
      <g opacity="0.9">
        <path
          d="M12.5416 1C12.682 1 12.7803 1.05617 12.8365 1.16852L24.0832 27.4995C24.1113 27.5276 24.1253 27.5557 24.1253 27.5838C24.1253 27.7804 24.027 27.8787 23.8305 27.8787C23.7181 27.8787 23.6339 27.8225 23.5777 27.7101L12.5416 2.01111L1.54759 27.7101C1.49143 27.8225 1.40719 27.8787 1.29486 27.8787C1.09829 27.8787 1 27.7804 1 27.5838C1 27.5557 1.01404 27.5276 1.04212 27.4995L12.2889 1.16852C12.345 1.05617 12.4293 1 12.5416 1Z"
          fill="white"
        />
        <path
          d="M47.3336 1C47.5021 1 47.5863 1.0983 47.5863 1.29491V27.331H66.1203C66.3168 27.331 66.4151 27.4152 66.4151 27.5838C66.4151 27.7804 66.3168 27.8787 66.1203 27.8787H47.3336C47.137 27.8787 47.0387 27.7804 47.0387 27.5838V1.29491C47.0387 1.0983 47.137 1 47.3336 1Z"
          fill="white"
        />
        <path
          d="M98.3698 1C102.217 1 105.418 2.27793 107.974 4.83379C108.03 4.88996 108.058 4.96018 108.058 5.04444C108.058 5.24104 107.96 5.33934 107.763 5.33934C107.679 5.33934 107.609 5.31126 107.552 5.25509C105.109 2.78348 102.048 1.54768 98.3698 1.54768C94.691 1.54768 91.6161 2.78348 89.1449 5.25509C86.7018 7.6986 85.4802 10.76 85.4802 14.4393C85.4802 18.1186 86.7018 21.1941 89.1449 23.6657C91.6161 26.1092 94.691 27.331 98.3698 27.331C101.992 27.331 105.025 26.1233 107.468 23.7078V14.7342H94.6208C94.4243 14.7342 94.326 14.6359 94.326 14.4393C94.326 14.2427 94.4243 14.1444 94.6208 14.1444H107.763C107.96 14.1444 108.058 14.2427 108.058 14.4393V23.8342C108.058 23.9185 108.03 23.9887 107.974 24.0449C105.418 26.6007 102.217 27.8787 98.3698 27.8787C94.5506 27.8787 91.3493 26.6007 88.7658 24.0449C86.2104 21.4609 84.9326 18.2591 84.9326 14.4393C84.9326 10.6196 86.2104 7.43178 88.7658 4.87592C91.3493 2.29197 94.5506 1 98.3698 1Z"
          fill="white"
        />
        <path
          d="M144.072 1C147.891 1 151.134 2.32006 153.802 4.96018C156.498 7.57221 157.846 10.7319 157.846 14.4393C157.846 18.1467 156.498 21.3205 153.802 23.9606C151.134 26.5726 147.891 27.8787 144.072 27.8787C141.572 27.8787 139.312 27.2748 137.29 26.0671C135.296 24.8594 133.737 23.2304 132.614 21.1801C131.519 19.1298 130.971 16.8828 130.971 14.4393C130.971 11.9958 131.519 9.74891 132.614 7.6986C133.737 5.64829 135.296 4.01928 137.29 2.81157C139.312 1.60386 141.572 1 144.072 1ZM144.072 1.54768C140.449 1.54768 137.444 2.79753 135.057 5.29721C132.698 7.76882 131.519 10.8162 131.519 14.4393C131.519 18.0625 132.698 21.1239 135.057 23.6236C137.444 26.0952 140.449 27.331 144.072 27.331C147.75 27.331 150.867 26.0811 153.423 23.5814C156.006 21.0537 157.298 18.0063 157.298 14.4393C157.298 10.8724 156.006 7.83903 153.423 5.33934C150.867 2.81157 147.75 1.54768 144.072 1.54768Z"
          fill="white"
        />
        <path
          d="M181.048 1H184.797C190.189 1 194.176 2.27793 196.76 4.83379C199.007 7.0807 200.13 10.2825 200.13 14.4393C200.13 18.5961 199.007 21.798 196.76 24.0449C194.176 26.6007 190.189 27.8787 184.797 27.8787H181.048C180.852 27.8787 180.753 27.7804 180.753 27.5838C180.753 27.4152 180.852 27.331 181.048 27.331H184.797C190.048 27.331 193.896 26.0952 196.339 23.6236C198.473 21.489 199.54 18.4276 199.54 14.4393C199.54 10.4511 198.473 7.38965 196.339 5.25509C193.896 2.78348 190.048 1.54768 184.797 1.54768H181.048C180.852 1.54768 180.753 1.46343 180.753 1.29491C180.753 1.0983 180.852 1 181.048 1Z"
          fill="white"
        />
        <path
          d="M233.11 1C233.25 1 233.348 1.05617 233.404 1.16852L244.651 27.4995C244.679 27.5276 244.693 27.5557 244.693 27.5838C244.693 27.7804 244.595 27.8787 244.398 27.8787C244.286 27.8787 244.202 27.8225 244.146 27.7101L233.11 2.01111L222.116 27.7101C222.059 27.8225 221.975 27.8787 221.863 27.8787C221.666 27.8787 221.568 27.7804 221.568 27.5838C221.568 27.5557 221.582 27.5276 221.61 27.4995L232.857 1.16852C232.913 1.05617 232.997 1 233.11 1Z"
          fill="white"
        />
        <path
          d="M279.226 1C283.045 1 286.288 2.32006 288.956 4.96018C291.652 7.57221 293 10.7319 293 14.4393C293 18.1467 291.652 21.3205 288.956 23.9606C286.288 26.5726 283.045 27.8787 279.226 27.8787C276.727 27.8787 274.466 27.2748 272.444 26.0671C270.45 24.8594 268.892 23.2304 267.769 21.1801C266.673 19.1298 266.126 16.8828 266.126 14.4393C266.126 11.9958 266.673 9.74891 267.769 7.6986C268.892 5.64829 270.45 4.01928 272.444 2.81157C274.466 1.60386 276.727 1 279.226 1ZM279.226 1.54768C275.603 1.54768 272.599 2.79753 270.212 5.29721C267.853 7.76882 266.673 10.8162 266.673 14.4393C266.673 18.0625 267.853 21.1239 270.212 23.6236C272.599 26.0952 275.603 27.331 279.226 27.331C282.905 27.331 286.022 26.0811 288.577 23.5814C291.161 21.0537 292.452 18.0063 292.452 14.4393C292.452 10.8724 291.161 7.83903 288.577 5.33934C286.022 2.81157 282.905 1.54768 279.226 1.54768Z"
          fill="white"
        />
        <path
          d="M12.5416 1C12.682 1 12.7803 1.05617 12.8365 1.16852L24.0832 27.4995C24.1113 27.5276 24.1253 27.5557 24.1253 27.5838C24.1253 27.7804 24.027 27.8787 23.8305 27.8787C23.7181 27.8787 23.6339 27.8225 23.5777 27.7101L12.5416 2.01111L1.54759 27.7101C1.49143 27.8225 1.40719 27.8787 1.29486 27.8787C1.09829 27.8787 1 27.7804 1 27.5838C1 27.5557 1.01404 27.5276 1.04212 27.4995L12.2889 1.16852C12.345 1.05617 12.4293 1 12.5416 1Z"
          stroke="white"
        />
        <path
          d="M47.3336 1C47.5021 1 47.5863 1.0983 47.5863 1.29491V27.331H66.1203C66.3168 27.331 66.4151 27.4152 66.4151 27.5838C66.4151 27.7804 66.3168 27.8787 66.1203 27.8787H47.3336C47.137 27.8787 47.0387 27.7804 47.0387 27.5838V1.29491C47.0387 1.0983 47.137 1 47.3336 1Z"
          stroke="white"
        />
        <path
          d="M98.3698 1C102.217 1 105.418 2.27793 107.974 4.83379C108.03 4.88996 108.058 4.96018 108.058 5.04444C108.058 5.24104 107.96 5.33934 107.763 5.33934C107.679 5.33934 107.609 5.31126 107.552 5.25509C105.109 2.78348 102.048 1.54768 98.3698 1.54768C94.691 1.54768 91.6161 2.78348 89.1449 5.25509C86.7018 7.6986 85.4802 10.76 85.4802 14.4393C85.4802 18.1186 86.7018 21.1941 89.1449 23.6657C91.6161 26.1092 94.691 27.331 98.3698 27.331C101.992 27.331 105.025 26.1233 107.468 23.7078V14.7342H94.6208C94.4243 14.7342 94.326 14.6359 94.326 14.4393C94.326 14.2427 94.4243 14.1444 94.6208 14.1444H107.763C107.96 14.1444 108.058 14.2427 108.058 14.4393V23.8342C108.058 23.9185 108.03 23.9887 107.974 24.0449C105.418 26.6007 102.217 27.8787 98.3698 27.8787C94.5506 27.8787 91.3493 26.6007 88.7658 24.0449C86.2104 21.4609 84.9326 18.2591 84.9326 14.4393C84.9326 10.6196 86.2104 7.43178 88.7658 4.87592C91.3493 2.29197 94.5506 1 98.3698 1Z"
          stroke="white"
        />
        <path
          d="M144.072 1C147.891 1 151.134 2.32006 153.802 4.96018C156.498 7.57221 157.846 10.7319 157.846 14.4393C157.846 18.1467 156.498 21.3205 153.802 23.9606C151.134 26.5726 147.891 27.8787 144.072 27.8787C141.572 27.8787 139.312 27.2748 137.29 26.0671C135.296 24.8594 133.737 23.2304 132.614 21.1801C131.519 19.1298 130.971 16.8828 130.971 14.4393C130.971 11.9958 131.519 9.74891 132.614 7.6986C133.737 5.64829 135.296 4.01928 137.29 2.81157C139.312 1.60386 141.572 1 144.072 1ZM144.072 1.54768C140.449 1.54768 137.444 2.79753 135.057 5.29721C132.698 7.76882 131.519 10.8162 131.519 14.4393C131.519 18.0625 132.698 21.1239 135.057 23.6236C137.444 26.0952 140.449 27.331 144.072 27.331C147.75 27.331 150.867 26.0811 153.423 23.5814C156.006 21.0537 157.298 18.0063 157.298 14.4393C157.298 10.8724 156.006 7.83903 153.423 5.33934C150.867 2.81157 147.75 1.54768 144.072 1.54768Z"
          stroke="white"
        />
        <path
          d="M181.048 1H184.797C190.189 1 194.176 2.27793 196.76 4.83379C199.007 7.0807 200.13 10.2825 200.13 14.4393C200.13 18.5961 199.007 21.798 196.76 24.0449C194.176 26.6007 190.189 27.8787 184.797 27.8787H181.048C180.852 27.8787 180.753 27.7804 180.753 27.5838C180.753 27.4152 180.852 27.331 181.048 27.331H184.797C190.048 27.331 193.896 26.0952 196.339 23.6236C198.473 21.489 199.54 18.4276 199.54 14.4393C199.54 10.4511 198.473 7.38965 196.339 5.25509C193.896 2.78348 190.048 1.54768 184.797 1.54768H181.048C180.852 1.54768 180.753 1.46343 180.753 1.29491C180.753 1.0983 180.852 1 181.048 1Z"
          stroke="white"
        />
        <path
          d="M233.11 1C233.25 1 233.348 1.05617 233.404 1.16852L244.651 27.4995C244.679 27.5276 244.693 27.5557 244.693 27.5838C244.693 27.7804 244.595 27.8787 244.398 27.8787C244.286 27.8787 244.202 27.8225 244.146 27.7101L233.11 2.01111L222.116 27.7101C222.059 27.8225 221.975 27.8787 221.863 27.8787C221.666 27.8787 221.568 27.7804 221.568 27.5838C221.568 27.5557 221.582 27.5276 221.61 27.4995L232.857 1.16852C232.913 1.05617 232.997 1 233.11 1Z"
          stroke="white"
        />
        <path
          d="M279.226 1C283.045 1 286.288 2.32006 288.956 4.96018C291.652 7.57221 293 10.7319 293 14.4393C293 18.1467 291.652 21.3205 288.956 23.9606C286.288 26.5726 283.045 27.8787 279.226 27.8787C276.727 27.8787 274.466 27.2748 272.444 26.0671C270.45 24.8594 268.892 23.2304 267.769 21.1801C266.673 19.1298 266.126 16.8828 266.126 14.4393C266.126 11.9958 266.673 9.74891 267.769 7.6986C268.892 5.64829 270.45 4.01928 272.444 2.81157C274.466 1.60386 276.727 1 279.226 1ZM279.226 1.54768C275.603 1.54768 272.599 2.79753 270.212 5.29721C267.853 7.76882 266.673 10.8162 266.673 14.4393C266.673 18.0625 267.853 21.1239 270.212 23.6236C272.599 26.0952 275.603 27.331 279.226 27.331C282.905 27.331 286.022 26.0811 288.577 23.5814C291.161 21.0537 292.452 18.0063 292.452 14.4393C292.452 10.8724 291.161 7.83903 288.577 5.33934C286.022 2.81157 282.905 1.54768 279.226 1.54768Z"
          stroke="white"
        />
      </g>
    </svg>
  )
}

export default Logo
