const stepsList = {
  algadao: [
    {
      number: 'I.',
      text: 'Voting for system parameters',
    },
    {
      number: 'II.',
      text: 'Sign-off on projects entering the incubator',
    },
    {
      number: 'III.',
      text: 'Sign-off on initiation of fundraising',
    },
    {
      number: 'IV.',
      text: 'SIGMA pool liquidity DAO',
    },
    {
      number: 'V.',
      text: 'Stake delegation to access higher DAO levels',
    },
  ],
  sigma: [
    {
      number: 'I.',
      text: 'Scalable access to launchpad allocations for all tiers large and small',
    },
    {
      number: 'II.',
      text: "SIGMA token as Algorand's ecosystem innovation index",
    },
    {
      number: 'III.',
      text: 'Pro-rata distribution rules to reward early participants and small stakers',
    },
    {
      number: 'IV.',
      text: 'DAO-governed liquidity supermarket backed by and serving projects launching through AlgoDAO',
    },
  ],
  algopad: [
    {
      number: 'I.',
      text: 'Algorithmic tiers for fair IDO allocations and access',
    },
    {
      number: 'II.',
      text: 'Burn boosters enabling deflationary tokenomics',
    },
    {
      number: 'III.',
      text: 'Tokenized pre-money rounds access for higher DAO tiers',
    },
    {
      number: 'IV.',
      text: 'Promoting ecosystem inclusivity through SIGMA farming',
    },
  ],
}

export default stepsList
