import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { checkPassword } from 'api/secret'
import differenceInSeconds from 'date-fns/differenceInSeconds/index.js'
import getTime from 'date-fns/getTime'
import { noop } from 'lodash'

interface Props {
  onSuccess(): void
  checkedTimestamp: null | {
    endTimestamp: number
    help: string
  }
}

function Form({ onSuccess = noop, checkedTimestamp = null }: Props) {
  const [pass, setPass] = useState('')
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [timeouted, setTimeouted] = useState(false)
  const [help, setHelp] = useState('')
  const [timer, setTimer] = useState<null | number>(null)
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
    null
  )
  const [endTimestamp, setEndTimestamp] = useState<null | number>(null)

  useEffect(() => {
    if (checkedTimestamp?.endTimestamp) {
      const timestampInfo = checkedTimestamp
      const difference = differenceInSeconds(
        Number(timestampInfo.endTimestamp),
        getTime(new Date())
      )
      setEndTimestamp(Number(timestampInfo.endTimestamp))
      setDisabled(true)
      setTimeouted(true)
      setTimer(difference)
      setHelp(timestampInfo.help)
    }
    return () => {
      if (timerInterval) clearInterval(timerInterval)
    }
  }, [])

  useEffect(() => {
    if (endTimestamp !== null && timerInterval === null) {
      startTimer()
    }
  }, [endTimestamp])

  useEffect(() => {
    if (timer === 0 || (timer !== null && timer < 0)) {
      setDisabled(false)
      setTimeouted(false)
      setTimer(null)
      if (timerInterval) clearInterval(timerInterval)
      setTimerInterval(null)
    }
  }, [timer])

  const startTimer = () => {
    setTimerInterval(
      setInterval(() => {
        const timerCalc = differenceInSeconds(
          Number(endTimestamp),
          getTime(new Date())
        )
        setTimer(timerCalc)
      }, 1000)
    )
  }

  const handleChange = (e: any) => {
    e.preventDefault()
    setPass(e.target.value)
  }

  const submitForm = async () => {
    if (pass === '') return setError(true)
    setError(false)
    const response = await checkPassword(pass)
    if (response.code === 2 || response.code === 3) {
      setPass('')
      setDisabled(true)
      setTimeouted(true)
      setEndTimestamp(Number(response.info.endTimestamp))
      const difference = differenceInSeconds(
        Number(response.info.endTimestamp),
        getTime(new Date())
      )
      if (response.code === 2) setTimer(10)
      if (response.code === 3) setTimer(difference > 0 ? difference : 1)
      if (response.info.help) setHelp(response.info.help)
      return
    }
    if (!response.error) {
      onSuccess()
    }
  }
  return (
    <Root>
      <InputWrapper>
        <Input
          type="password"
          placeholder="enter password"
          value={pass}
          onChange={handleChange}
          disabled={disabled || timeouted}
          error={error}
        />
        {timeouted && <Wrong>WRONG password</Wrong>}
      </InputWrapper>
      <Button
        type="button"
        onClick={submitForm}
        disabled={disabled || timeouted}
      >
        Down the Rabbit Hole
      </Button>
      {timeouted && (
        <>
          <TimeoutedRow>
            <TimeoutedText>The next attempt to enter a password:</TimeoutedText>
            {timer !== null && (
              <TimeoutedTimer>
                00:{timer < 10 ? `0${timer}` : timer} sec
              </TimeoutedTimer>
            )}
          </TimeoutedRow>
          <HelpWrapper>
            <Help>{help}</Help>
          </HelpWrapper>
        </>
      )}
      {error}
    </Root>
  )
}

export default Form

const Root = styled.div`
  position: relative;
  width: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #3c404a;
  border-radius: 6px;
  z-index: 2;
  padding: 45px;
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 435px;
    border-radius: 5px;
    padding: 35px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 351px;
    border-radius: 4px;
    padding: 28px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 529px;
    border-radius: 6px;
    padding: 42px;
    margin-bottom: 90px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 356px;
    border-radius: 4px;
    padding: 28px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 20px;
  }
`

const InputWrapper = styled.div`
  font-family: 'Aldrich';
  position: relative;
  width: 100%;
  margin-bottom: 18px;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 12px;
  }
`

const Input = styled.input<{ error: boolean }>`
  width: 100%;
  height: 51px;
  background: #f2f2f2;
  border: 1px solid ${({ error }) => (error ? '#DC4C4C' : '#f2f2f2')} !important;
  border: 0;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: normal;
  speak: none;
  color: #cf4858;
  //font-size: 16px;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 8px;
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 14px;
    letter-spacing: 7px;
    height: 41px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    letter-spacing: 6px;
    height: 34px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 18px;
    letter-spacing: 8px;
    height: 51px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 12px;
    letter-spacing: 6px;
    height: 34px;
    border-radius: 2px;
  }

  &::placeholder {
    text-transform: uppercase;
    color: ${({ error }) => (error ? '#DC4C4C' : '#c0c0c2')};
    letter-spacing: normal;
  }
`

const Button = styled.button`
  font-family: 'Aldrich';
  width: 100%;
  height: 38px;
  background: linear-gradient(
    90.32deg,
    #cf4858 -23.2%,
    #dc5a4c 52.6%,
    #cf4858 104.91%
  );
  border-radius: 3px;
  color: #ffffff;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 30px;
    font-size: 14px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    height: 24px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    height: 36px;
    font-size: 18px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 24px;
    font-size: 12px;
    border-radius: 2px;
  }
  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`

const TimeoutedRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  font-family: 'Aldrich';
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-top: 12px;
  }
`

const TimeoutedText = styled.span`
  color: #9d9fa4;
  font-size: 14px;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 8px;
  }
`

const TimeoutedTimer = styled.span`
  color: #dc4c4c;
  font-size: 14px;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 8px;
  }
`

const HelpWrapper = styled.div`
  width: 100%;
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #51555e;
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 15px;
    padding-top: 15px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 12px;
    padding-top: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 18px;
    padding-top: 18px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-top: 12px;
    padding-top: 12px;
  }
`

const Help = styled.div`
  width: 100%;
  border-radius: 3px;
  background: #32353e;
  font-size: 14px;
  color: #999a9f;
  padding: 15px 0;
  text-align: center;
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 12px 0;
    font-size: 12px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 10px 0;
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 15px 0;
    font-size: 14px;
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 10px 0;
    font-size: 10px;
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 8px;
  }
`

const Wrong = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 3px;
  color: #d04959;
`
