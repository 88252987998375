import styled from 'styled-components'

const theme = {
  adaptive: {
    index: {
      xl: '@media screen and (max-width: 1880px)',
      lg: '@media screen and (max-width: 1660px)',
      slg: '@media screen and (max-width: 1400px)',
      md: '@media screen and (max-width: 1200px)',
      sm: '@media screen and (max-width: 900px)',
      smm: '@media screen and (max-width: 560px)',
      xs: '@media screen and (max-width: 360px)',
    },
  },
}

export default theme

export const Container = styled.div`
  position: relative;
  width: 1788px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1540px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1320px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1137px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 843px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 330px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`
