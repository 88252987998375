import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import bg from 'images/secret/bg.svg'
import bgSm from 'images/secret/bg-sm.svg'
import Form from './Form'
import Success from './Success'
import Logo from 'components/Icons/Logo'
import { checkSecretStatus } from 'api/secret'
// import { ReactComponent as Logo } from 'images/header/logo.svg'

function Secret() {
  const [status, setStatus] = useState<null | boolean>(null)
  const [endTimestamp, setEndTimestamp] = useState<null | {
    endTimestamp: number
    help: string
  }>(null)
  useEffect(() => {
    async function checkStatus() {
      const response = await checkSecretStatus()
      if (response.info && response.info.endTimestamp) {
        setEndTimestamp(response.info)
      }
      response.error === 'unauth' ? setStatus(false) : setStatus(true)
    }
    checkStatus()
  }, [])

  const handleSuccess = () => {
    setStatus(true)
  }

  return (
    <Root status={status}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {status === false && (
        <Form onSuccess={handleSuccess} checkedTimestamp={endTimestamp} />
      )}
      {status === true && <Success />}
    </Root>
  )
}

const Root = styled.div<{ status: null | boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ status }) => {
    if (status === true) {
      return css`
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.02),
            rgba(255, 255, 255, 0.02)
          ),
          linear-gradient(
            180deg,
            rgba(139, 165, 231, 0.2) 0%,
            rgba(85, 127, 231, 0) 100%
          ),
          #282b32;
      `
    } else {
      return css`
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.02),
            rgba(255, 255, 255, 0.02)
          ),
          linear-gradient(
            180deg,
            rgba(153, 153, 153, 0.1) 0%,
            rgba(153, 153, 153, 0) 100%
          ),
          #282b32;
      `
    }
  }}
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.adaptive.index.sm} {
    align-items: flex-end;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${bg}) no-repeat right top;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.sm} {
      background: url(${bgSm}) no-repeat right top;
      background-size: cover;
    }
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: 80px;
  top: 64px;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.index.xl} {
    left: 67px;
    top: 53px;
    width: 244px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    left: 60px;
    top: 48px;
    width: 219px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    left: 53px;
    top: 43px;
    width: 195px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    left: 43px;
    top: 34px;
    width: 156px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    left: 50%;
    top: 43px;
    transform: translateX(-50%);
    width: 244px;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    top: 34px;
    width: 195px;
  }
  svg,
  path {
    fill: rgba(255, 255, 255, 0.3) !important;
  }
  svg {
    width: 100%;
  }
`

export default Secret
