import React, { useRef } from 'react'
import styled from 'styled-components'
import logoImg from 'images/header/logo.svg'
import btnBorder from 'images/hierarchy/btn-border.svg'
import closeImg from 'images/header/close.svg'
import useOnClickOutside from 'helpers/hooks/useOnClickOutside'
import { DECK_LINK } from '../../configs/app'
import Navigation from './Navigation'

interface menuProps {
  open: boolean
  onClick: () => void
  onContact: () => void
  addRef: (el: any) => void
  isMain: boolean
}

const MobileMenu: React.FunctionComponent<menuProps> = (props) => {
  const menuRef = useRef(null)

  useOnClickOutside({
    ref: menuRef,
    handler: props.onClick,
  })

  const onContactClick = () => {
    props.onClick()
    props.onContact()
  }

  return (
    <Root ref={menuRef} open={props.open}>
      <Cont>
        <Logo />
        <Navigation isMain={props.isMain} onBtnClick={onContactClick} />
        {/*<Btn href={DECK_LINK} target={'_blank'}>*/}
        {/*  Investment <span>Deck</span>*/}
        {/*</Btn>*/}
        <Close onClick={props.onClick} />
      </Cont>
    </Root>
  )
}

export default MobileMenu

interface openProps {
  open: boolean
}

const Root = styled.div<openProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
  opacity: ${({ open }) => (open ? '1' : '0')};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`

const Cont = styled.div`
  position: absolute;
  top: 13px;
  left: 50%;
  transform: translateX(-50%);
  width: 551px;
  height: 630px;
  background: #ffffff;
  border: 1px solid rgba(183, 183, 183, 0.23);
  border-radius: 6px;
  ${({ theme }) => theme.adaptive.index.smm} {
    top: 10px;
    width: 359px;
    height: 636px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    top: 14px;
    width: 304px;
    height: 472px;
  }
`

const Logo = styled.div`
  width: 185px;
  height: 159px;
  margin: 125px auto 0;
  background: url('${logoImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.smm} {
    margin: 131px auto 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 75px auto 0;
  }
`

const Btn = styled.a`
  position: relative;
  display: block;
  width: 260px;
  height: 46px;
  margin: 36px auto 0;
  padding: 17px;
  text-align: center;
  background: linear-gradient(
    90.5deg,
    #353842 0.25%,
    #2d3139 50.65%,
    #353842 99.93%
  );
  box-shadow: 0px 9.62469px 15.7034px rgba(0, 0, 0, 0.15);
  border-radius: 4.0525px;

  font-family: 'Aldrich';
  font-size: 16px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 220px;
    margin: 37px auto 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 27px auto 0;
  }
  span {
    color: #dc5a4c;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 197px;
    height: 2px;
    background: url('${btnBorder}') center no-repeat;
    background-size: contain;
  }
`

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 16px;
  width: 21px;
  height: 21px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.smm} {
    top: 23px;
    right: 14px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 18px;
    height: 18px;
    top: 14px;
    right: 12px;
  }
`
