import blueshiftLogo from 'images/rho/start/partners/blueshift.svg'
import milkomedaLogo from 'images/rho/start/partners/milkomeda.svg'
import eyLogo from 'images/rho/start/partners/ey.svg'
import symbiosisLogo from 'images/rho/start/partners/symbiosis.svg'

const partnersList = [
  {
    img: blueshiftLogo,
    link: 'https://blueshift.fi/',
  },
  {
    img: milkomedaLogo,
    link: 'https://www.milkomeda.com/',
  },
  {
    img: eyLogo,
    link: 'https://www.ey.com/en_gl/strategy/about-ey-parthenon',
  },
  {
    img: symbiosisLogo,
    link: 'https://symbiosis.finance/',
  },
]

export default partnersList
