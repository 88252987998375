import Joi from 'joi'
import { SendEmailParams } from '../../types/mail/mailTypes'

export const schema = Joi.object({
  name: Joi.string()
    .regex(/^[а-яА-ЯёЁa-zA-Z]{2}/)
    .min(2)
    .max(40)
    .required()
    .messages({
      'string.pattern.base': `First two chars must be letters`,
    }),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),

  message: Joi.string().min(10).max(50000).required(),
})

export const validateEmail = (params: SendEmailParams) => {
  return schema.validate(params)
}
