import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import { getSecretFile } from 'api/secret'
import music from 'audio/videoplayback.mp4'
import 'react-h5-audio-player/lib/styles.css'
import { ReactComponent as PauseBtn } from 'images/secret/stop-img.svg'
import { ReactComponent as PlayBtn } from 'images/secret/play-img.svg'
import { ReactComponent as NextPrevImg } from 'images/secret/prev-img.svg'
import { ReactComponent as MuteImg } from 'images/secret/mute-img.svg'
import { ReactComponent as DownloadImg } from 'images/secret/download-img.svg'
import Volume from './Volume'

function Success() {
  const playerRef = useRef<any>()
  const [volume, setVolume] = useState(100)

  const CustomIcons = {
    play: (
      <PlayButton>
        <PlayBtn />
      </PlayButton>
    ),
    pause: (
      <PlayButton>
        <PauseBtn />
      </PlayButton>
    ),
    rewind: (
      <NextPrevBtn>
        <NextPrevImg />
      </NextPrevBtn>
    ),
    forward: (
      <NextPrevBtn next={true}>
        <NextPrevImg />
      </NextPrevBtn>
    ),
    volume: (
      <VolumeBtn>
        <Volume value={volume} />
      </VolumeBtn>
    ),
    volumeMute: (
      <VolumeBtn>
        <MuteImg />
      </VolumeBtn>
    ),
  }

  const DownLoadBtn = (
    <DownloadButton href={music} download>
      <DownloadImg />
    </DownloadButton>
  )

  useEffect(() => {
    playerRef.current.audio.current.addEventListener(
      'volumechange',
      (e: { target: HTMLAudioElement }) =>
        setVolume((e.target as HTMLAudioElement).volume * 100)
    )
  }, [])

  return (
    <Root>
      {/*<Text>Help me, ALGO-Wan Kenobi. You're my only hope...</Text>*/}
      {/*<Button onClick={handleDownloadClick}>DOWNLOAD FILE</Button>*/}
      <AudioPlayer
        ref={playerRef}
        src={music}
        showJumpControls={true}
        customIcons={CustomIcons}
        customVolumeControls={[RHAP_UI.VOLUME, DownLoadBtn]}
      />
    </Root>
  )
}

export default Success

const Root = styled.div`
  position: relative;
  font-family: 'Aldrich';
  z-index: 2;
  max-width: 597px;
  width: 100%;
  background: #f7f7f7;
  border-radius: 6px;
  text-align: center;
  padding: 51px 21px 15px 65px;
  .rhap_progress-container {
    margin: 0 8px;
  }
  .rhap_progress-bar {
    background: #292b33;
  }
  .rhap_progress-indicator {
    width: 16px;
    height: 16px;
    background: #ffffff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1);
    top: 50%;
    transform: translateY(-50%);
    margin-left: 0;
  }
  .rhap_progress-filled {
    background: linear-gradient(
      90.32deg,
      #cf4858 -23.2%,
      #dc5a4c 52.6%,
      #cf4858 104.91%
    );
  }
  .rhap_time {
    width: 45px;
    font-size: 14px;
    line-height: 7px;
    text-align: center;
    color: #3c404a;
  }
  .rhap_download-progress {
    display: none;
  }
  .rhap_play-pause-button,
  .rhap_main-controls-button {
    width: auto;
    height: auto;
    margin: 0 12px;
  }
  .rhap_stacked .rhap_controls-section {
    margin-top: 31px;
  }
  .rhap_volume-container {
    flex: 0 1 65px;
  }
  .rhap_volume-indicator {
    background: #3c404a;
    width: 5px;
    height: 5px;
    top: -2px;
    margin-left: -3px;
  }
  .rhap_volume-button {
    flex: 0 0 12px;
    font-size: 12px;
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
  .rhap_volume-bar {
    height: 1px;
    background: #3c404a;
  }
  .rhap_container {
    background-color: unset;
    box-shadow: none;
    padding: 0;
  }
  .rhap_additional-controls {
    position: relative;
    z-index: -2;
    pointer-events: none;
    opacity: 0;
  }
`

const Text = styled.div`
  font-size: 14px;
  color: #3c404a;
  margin-bottom: 18px;
  text-transform: uppercase;
`

const Button = styled.button`
  color: #ffffff;
  width: 100%;
  text-transform: uppercase;
  background: linear-gradient(122.18deg, #557fe7 -8.19%, #8ba5e7 146.11%);
  border-radius: 3px;
  padding: 10px 0;
`

const Controls = styled.div`
  position: absolute;
  left: 50%;
  top: 97px;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlayButton = styled.div`
  width: 29px;
  height: 25px;
  svg {
    display: block;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`

const NextPrevBtn = styled.div<{ next?: true }>`
  width: 24px;
  height: 14px;
  transform: ${({ next }) => (next ? 'rotate(180deg)' : '')};
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const VolumeBtn = styled.div`
  width: 12px;
  height: 12px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const DownloadButton = styled.a`
  width: 22px;
  height: 22px;
  margin-left: 30px;
  border: 0;
  outline: none;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`
