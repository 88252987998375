import algopadImg from 'images/index/main/rocket-icon.svg'
import sigmaImg from 'images/index/main/maelstorm-icon.svg'
import algodaoImg from 'images/index/main/layer-icon.svg'

export interface mainProps {
  img: string
  name: string
  text: string
}

const mainList: mainProps[] = [
  {
    img: algopadImg,
    name: 'ALGOpad',
    text: 'the boutique launchpad for Algorand’s ecosystem',
  },
  {
    img: sigmaImg,
    name: 'SIGMA pool',
    text: 'tokenized liquidity and Algorand’s innovation index',
  },
  {
    img: algodaoImg,
    name: 'ALGODAO',
    text: 'tokenized incubator and system’s governance layer',
  },
]

export default mainList
