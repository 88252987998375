import React from 'react'
import styled from 'styled-components'
import textBg from 'images/rho/about/text-bg.svg'
import useWindowSize from 'helpers/utils/useWindowSize'

const About: React.FunctionComponent = () => {
  const { width } = useWindowSize()

  return (
    <Root>
      <Wrap>
        <Row>
          <Title>
            What Are <span>RHOs</span>?
          </Title>
          <TextBlock>
            <Text>
              RHO is a unique mechanism to equalize the opportunities available
              to all AlgoDAO members to get involved in early-stage funding
              rounds.
            </Text>
            <Text>
              <TextBgMob />
              RHOs potentially offer <span>significantly HIGHER</span> rewards
              than what you can expect to get from any regular IDOs due to
              effective seed valuations on projects’ tokens.
            </Text>
            <Text>
              <TextBg />
              RHOs <span>unlock about 6%</span> of token supply across{' '}
              <span>three (3) projects.</span>
            </Text>
          </TextBlock>
        </Row>
      </Wrap>
      <BottomRow>
        <Price>
          <Value>$0.15</Value>
          <PriceText>RHO Token Price</PriceText>
        </Price>
        <Price>
          <Value>$75,000</Value>
          <PriceText>RHO SOFT CAP</PriceText>
        </Price>
        {width > 900 && (
          <Price>
            <Value>$150,000</Value>
            <PriceText>RHO Pool Size</PriceText>
          </Price>
        )}
      </BottomRow>
      {width <= 900 && (
        <BottomRow>
          <Price>
            <Value>$150,000</Value>
            <PriceText>RHO Pool Size</PriceText>
          </Price>
        </BottomRow>
      )}
    </Root>
  )
}

export default About

const Root = styled.div`
  position: relative;
  width: 100%;
`

const Wrap = styled.div`
  width: 1758px;
  height: 303px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 67px 0 0 110px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1539px;
    height: 265px;
    border-radius: 11px;
    padding: 59px 0 0 96px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1319px;
    height: 227px;
    border-radius: 9px;
    padding: 50px 0 0 83px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1135px;
    height: 196px;
    border-radius: 8px;
    padding: 43px 0 0 71px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 842px;
    height: 145px;
    border-radius: 6px;
    padding: 32px 0 0 53px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 520px;
    height: 261px;
    padding: 32px 43px 0 43px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 335px;
    height: 282px;
    padding: 33px 20px 0 20px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
    height: 315px;
  }
`

const Row = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.index.smm} {
    display: block;
  }
`

const Title = styled.div`
  min-width: 260px;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-right: 76px;
  ${({ theme }) => theme.adaptive.index.xl} {
    min-width: 228px;
    font-size: 42px;
    margin-right: 66px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    min-width: 195px;
    font-size: 36px;
    margin-right: 57px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    min-width: 168px;
    font-size: 32px;
    margin-right: 49px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    min-width: 125px;
    font-size: 24px;
    margin-right: 36px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 125px;
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    min-width: unset;
    width: 100%;
    font-size: 24px;
    margin-right: 0;
    margin-bottom: 4px;
  }
  span {
    color: #dc4c4c;
  }
`

const TextBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
  }
`

const Text = styled.div`
  position: relative;
  width: 561px;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-right: 63px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 491px;
    font-size: 21px;
    margin-right: 55px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 421px;
    font-size: 18px;
    margin-right: 47px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 362px;
    font-size: 16px;
    margin-right: 41px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 269px;
    font-size: 12px;
    margin-right: 28px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 269px !important;
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 15px;
    z-index: 2;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100% !important;
  }
  span {
    position: relative;
    font-weight: 700;
    padding: 0 20px;
    ${({ theme }) => theme.adaptive.index.xl} {
      padding: 0 15px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      padding: 0 10px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      padding: 0 8px;
    }
  }
  &:nth-child(2) {
    width: 602px;
    margin: 0;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 527px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 452px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 389px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 329px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 246px;
      width: 244px;
      height: 35px;
      background: #dc4c4c;
      z-index: -1;
      ${({ theme }) => theme.adaptive.index.xl} {
        left: 215px;
        width: 214px;
        height: 31px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        left: 181px;
        width: 183px;
        height: 26px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        left: 162px;
        width: 163px;
        height: 23px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        left: 121px;
        width: 121px;
        height: 17px;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        width: 80px;
      }
    }
  }
  &:nth-child(3) {
    width: 100%;
    margin: 49px 0 0 0;
    font-size: 26px;
    ${({ theme }) => theme.adaptive.index.xl} {
      margin: 35px 0 0 0;
      font-size: 23px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      margin: 30px 0 0 0;
      font-size: 20px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      margin: 26px 0 0 0;
      font-size: 18px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      margin: 20px 0 0 0;
      font-size: 14px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      line-height: 180%;
    }
    &:before {
      content: '';
      position: absolute;
      top: 3px;
      left: 77px;
      width: 217px;
      height: 32px;
      background: #dc4c4c;
      z-index: -1;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 3px;
        left: 65px;
        width: 190px;
        height: 28px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        top: 3px;
        left: 54px;
        width: 169px;
        height: 25px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        top: 1px;
        left: 48px;
        width: 154px;
        height: 25px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        top: 1px;
        left: 43px;
        width: 114px;
        height: 19px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        left: 41px;
        top: 3px;
      }
    }
  }
`

const TextBgMob = styled.div`
  ${({ theme }) => theme.adaptive.index.xs} {
    position: absolute;
    width: 53px;
    height: 17px;
    left: -5px;
    top: 17px;
    background: #dc4c4c;
  }
`

const TextBg = styled.div`
  position: absolute;
  width: 252px;
  height: 32px;
  left: 580px;
  top: 3px;
  background: url('${textBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 210px;
    height: 28px;
    left: 510px;
    top: 3px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 178px;
    height: 25px;
    left: 433px;
    top: 3px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    top: 1px;
    left: 390px;
    width: 162px;
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: 1px;
    left: 310px;
    width: 132px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    top: 29px;
    left: 47px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    left: 91px;
  }
`

const Value = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 68px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;

  color: #8b8b8d;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 52px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 45px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 34px;
    margin-bottom: 0px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 26px;
  }
`

const PriceText = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  color: #8b8b8d;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
  }
`

const Price = styled.div`
  margin-right: 75px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 66px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 55px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 47px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 42px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 155px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-right: 43px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-right: 15px;
  }
  &:nth-child(1) {
    ${Value} {
      color: #dc4c4c;
    }
  }
  &:last-child {
    margin: 0;
  }
`

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 38px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 32px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 24px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 16px;
    &:nth-child(3) {
      ${Price} {
        &:nth-child(1) {
          ${Value} {
            color: #8b8b8d;
          }
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    display: flex;
    justify-content: flex-end;
    margin-top: 21px;
    margin-left: 0;
    width: auto;
    &:nth-child(2) {
      justify-content: space-between;
      ${Price} {
        &:nth-child(1) {
          justify-content: flex-start;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-top: 18px;
  }
`
