import React from 'react'

interface rocketProps {
  anim?: boolean
}

const Lines: React.FunctionComponent<rocketProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1920"
    height="703"
    viewBox="0 0 1920 703"
    fill="none"
  >
    <path
      className={'line'}
      d="M959.836 1C820.836 192 549.836 538 0.835938 702"
      stroke="url(#paint0_linear_17_629)"
    />
    <path
      className={'line'}
      d="M960.836 1C1099.84 192 1370.84 538 1919.84 702"
      stroke="url(#paint1_linear_17_629)"
    />
    <g opacity="0.2">
      <path
        d="M1503.5 504.113C1503.64 504.26 1503.75 504.428 1503.83 504.589C1503.89 504.762 1503.94 504.961 1503.95 505.162C1503.94 505.356 1503.93 505.563 1503.85 505.794C1503.78 506.026 1503.67 506.262 1503.51 506.517L1503.28 506.911C1502.53 508.151 1501.54 508.388 1500.28 507.634L1496.08 505.081C1496.08 505.081 1497.25 503.162 1498.42 501.245C1499.59 499.325 1500.75 497.409 1500.75 497.409L1504.72 499.816C1505.97 500.57 1506.21 501.573 1505.45 502.819L1505.36 502.964C1505.1 503.392 1504.83 503.708 1504.52 503.889C1504.22 504.087 1503.89 504.156 1503.5 504.113ZM1499.19 502.394L1497.81 504.657L1500.9 506.538C1501.2 506.716 1501.45 506.785 1501.66 506.71C1501.86 506.663 1502.07 506.489 1502.24 506.193L1502.6 505.595C1502.78 505.309 1502.84 505.052 1502.79 504.85C1502.75 504.642 1502.57 504.45 1502.28 504.265L1499.19 502.394ZM1501.17 499.143L1499.85 501.314L1502.79 503.116C1503.06 503.268 1503.29 503.302 1503.5 503.239C1503.68 503.176 1503.87 503.008 1504.04 502.722L1504.34 502.228C1504.52 501.932 1504.59 501.678 1504.55 501.464C1504.49 501.249 1504.33 501.057 1504.04 500.882L1501.17 499.143Z"
        fill="white"
      />
      <path
        d="M1508.11 501.879L1509.22 502.534C1509.22 502.534 1508.22 504.193 1507.23 505.829C1506.24 507.488 1505.24 509.141 1505.24 509.141L1509.66 511.774C1509.66 511.774 1510.65 510.128 1511.64 508.475C1512.62 506.832 1513.61 505.173 1513.61 505.173L1514.71 505.831C1514.71 505.831 1513.72 507.487 1512.73 509.133C1511.75 510.789 1510.76 512.445 1510.76 512.445C1510.67 512.596 1510.56 512.715 1510.41 512.823C1510.28 512.922 1510.14 512.993 1509.97 513.027C1509.81 513.072 1509.65 513.081 1509.48 513.049C1509.3 513.029 1509.15 512.981 1509 512.876L1504.58 510.233C1504.43 510.137 1504.31 510.032 1504.2 509.88C1504.11 509.757 1504.03 509.596 1503.99 509.439C1503.95 509.282 1503.95 509.119 1503.96 508.957C1504 508.782 1504.05 508.614 1504.14 508.463C1504.14 508.463 1505.14 506.82 1506.14 505.171C1507.12 503.521 1508.11 501.879 1508.11 501.879Z"
        fill="white"
      />
      <path
        d="M1517.46 507.475C1517.46 507.475 1516.32 509.402 1515.17 511.335C1514.02 513.262 1512.87 515.195 1512.87 515.195L1511.77 514.53C1511.77 514.53 1512.92 512.606 1514.07 510.673C1515.22 508.744 1516.36 506.823 1516.36 506.823L1517.46 507.475Z"
        fill="white"
      />
      <path
        d="M1520.22 509.107C1520.22 509.107 1519.24 510.76 1518.26 512.416C1517.28 514.075 1516.3 515.734 1516.3 515.734L1520.29 518.087L1519.63 519.183C1519.63 519.183 1518.36 518.441 1517.07 517.683C1515.81 516.926 1514.54 516.178 1514.54 516.178C1514.54 516.178 1515.68 514.248 1516.82 512.318C1517.98 510.382 1519.11 508.452 1519.11 508.452L1520.22 509.107Z"
        fill="white"
      />
      <path
        d="M1523.18 510.864C1523.18 510.864 1524.74 511.771 1526.28 512.681C1527.83 513.588 1529.38 514.486 1529.38 514.486L1528.74 515.598L1526.18 514.111C1526.18 514.111 1525.21 515.777 1524.24 517.433C1523.27 519.089 1522.29 520.758 1522.29 520.758L1521.19 520.109C1521.19 520.109 1522.16 518.447 1523.13 516.784C1524.11 515.131 1525.08 513.462 1525.08 513.462L1522.53 511.972L1523.18 510.864Z"
        fill="white"
      />
      <path
        d="M1536.19 527.32C1536.1 527.471 1535.98 527.606 1535.85 527.715C1535.72 527.814 1535.57 527.881 1535.4 527.928C1535.24 527.973 1535.08 527.975 1534.91 527.959C1534.75 527.936 1534.58 527.882 1534.43 527.802L1529.97 525.215C1529.81 525.126 1529.69 525.028 1529.59 524.879C1529.48 524.759 1529.41 524.607 1529.37 524.45C1529.33 524.283 1529.31 524.111 1529.33 523.952C1529.36 523.774 1529.41 523.625 1529.5 523.461C1529.5 523.461 1530.31 522.082 1531.11 520.693C1531.91 519.311 1532.72 517.916 1532.72 517.916C1532.8 517.771 1532.92 517.643 1533.05 517.546C1533.18 517.435 1533.33 517.368 1533.5 517.327C1533.66 517.289 1533.81 517.267 1533.98 517.289C1534.15 517.309 1534.31 517.36 1534.47 517.453L1538.91 520.01C1539.06 520.1 1539.19 520.208 1539.27 520.347C1539.38 520.486 1539.45 520.628 1539.51 520.792C1539.55 520.952 1539.55 521.115 1539.54 521.283C1539.52 521.458 1539.46 521.613 1539.38 521.765C1539.38 521.765 1538.58 523.147 1537.79 524.542C1536.98 525.925 1536.19 527.32 1536.19 527.32ZM1533.82 518.561C1533.82 518.561 1533.02 519.95 1532.21 521.339C1531.41 522.721 1530.61 524.104 1530.61 524.104L1535.07 526.69C1535.07 526.69 1535.88 525.292 1536.67 523.906C1537.47 522.514 1538.27 521.116 1538.27 521.116L1533.82 518.561Z"
        fill="white"
      />
      <path
        d="M1538.35 530.045L1537.23 529.39C1537.23 529.39 1538.33 527.443 1539.45 525.493C1540.57 523.547 1541.68 521.6 1541.68 521.6L1542.8 522.24C1542.8 522.24 1543.11 524.418 1543.43 526.59C1543.73 528.771 1544.05 530.965 1544.05 530.965C1544.05 530.965 1544.91 529.444 1545.77 527.922C1546.63 526.417 1547.47 524.889 1547.47 524.889L1548.59 525.534C1548.59 525.534 1547.48 527.487 1546.38 529.437C1545.28 531.397 1544.18 533.344 1544.18 533.344L1543.05 532.72C1543.05 532.72 1542.73 530.525 1542.43 528.351C1542.11 526.169 1541.81 523.988 1541.81 523.988C1541.81 523.988 1540.94 525.503 1540.08 527.015C1539.21 528.527 1538.35 530.045 1538.35 530.045Z"
        fill="white"
      />
      <path
        d="M1556.75 530.117C1556.75 530.117 1556.41 532.492 1556.07 534.885C1555.74 537.263 1555.4 539.647 1555.4 539.647L1554.26 539.017L1554.59 536.821L1551.04 534.834L1549.34 536.258L1548.2 535.627C1548.2 535.627 1550.06 534.092 1551.91 532.548C1553.77 531.013 1555.62 529.484 1555.62 529.484L1556.75 530.117ZM1552.1 533.958L1554.78 535.46L1555.37 531.267L1552.1 533.958Z"
        fill="white"
      />
      <path
        d="M1557.54 540.835L1556.42 540.202C1556.42 540.202 1557.5 538.236 1558.6 536.273C1559.68 534.322 1560.77 532.356 1560.77 532.356L1561.9 532.977C1561.9 532.977 1562.23 535.149 1562.57 537.321C1562.91 539.5 1563.26 541.679 1563.26 541.679C1563.26 541.679 1564.09 540.154 1564.94 538.626C1565.78 537.11 1566.61 535.582 1566.61 535.582L1567.74 536.202C1567.74 536.202 1566.65 538.168 1565.57 540.135C1564.5 542.108 1563.42 544.077 1563.42 544.077L1562.29 543.44C1562.29 543.44 1561.94 541.268 1561.59 539.092C1561.25 536.917 1560.92 534.735 1560.92 534.735C1560.92 534.735 1560.08 536.263 1559.23 537.785C1558.38 539.304 1557.54 540.835 1557.54 540.835Z"
        fill="white"
      />
      <path
        d="M1569.74 546.066C1570.27 546.345 1570.75 546.429 1571.18 546.295C1571.61 546.167 1571.98 545.847 1572.27 545.32L1573.55 542.95C1573.69 542.695 1573.78 542.448 1573.81 542.194C1573.86 541.959 1573.84 541.723 1573.77 541.517C1573.71 541.299 1573.6 541.096 1573.43 540.91C1573.27 540.731 1573.06 540.564 1572.8 540.428L1569.92 538.851C1569.92 538.851 1569.16 540.263 1568.38 541.675C1567.62 543.084 1566.85 544.486 1566.85 544.486L1569.74 546.066ZM1565.1 544.99C1565.1 544.99 1566.19 543.024 1567.26 541.051C1568.33 539.085 1569.42 537.118 1569.42 537.118L1573.42 539.297C1573.84 539.526 1574.18 539.791 1574.45 540.119C1574.72 540.431 1574.9 540.77 1575.02 541.145C1575.12 541.514 1575.16 541.904 1575.1 542.314C1575.04 542.721 1574.91 543.142 1574.69 543.549L1573.4 545.925C1573.17 546.344 1572.9 546.702 1572.58 546.96C1572.27 547.237 1571.93 547.421 1571.55 547.534C1571.19 547.644 1570.79 547.673 1570.38 547.626C1569.97 547.557 1569.55 547.424 1569.13 547.195L1565.1 544.99Z"
        fill="white"
      />
      <path
        d="M1580.69 543.222C1580.69 543.222 1582.13 543.993 1583.56 544.77C1585 545.538 1586.45 546.303 1586.45 546.303L1585.85 547.444L1581.21 544.959L1580 547.222L1583.96 549.332L1583.35 550.476L1579.39 548.366L1577.58 551.745L1576.44 551.137C1576.44 551.137 1577.5 549.164 1578.57 547.181C1579.63 545.202 1580.69 543.222 1580.69 543.222Z"
        fill="white"
      />
      <path
        d="M1590.92 557.371C1590.83 557.531 1590.73 557.663 1590.59 557.778C1590.46 557.877 1590.31 557.951 1590.15 558.005C1590.01 558.049 1589.84 558.065 1589.66 558.045C1589.49 558.035 1589.32 557.987 1589.18 557.904L1584.63 555.504C1584.47 555.421 1584.34 555.325 1584.22 555.182C1584.12 555.059 1584.05 554.908 1583.99 554.753C1583.95 554.59 1583.93 554.433 1583.94 554.271C1583.96 554.092 1584.01 553.928 1584.08 553.77C1584.08 553.77 1584.84 552.352 1585.59 550.942C1586.35 549.527 1587.09 548.095 1587.09 548.095C1587.18 547.951 1587.28 547.829 1587.42 547.717C1587.55 547.608 1587.69 547.538 1587.85 547.481C1588.02 547.433 1588.18 547.414 1588.34 547.427C1588.52 547.441 1588.68 547.492 1588.84 547.569L1593.36 549.962C1593.51 550.032 1593.64 550.15 1593.75 550.286C1593.86 550.416 1593.94 550.558 1593.98 550.722C1594.03 550.87 1594.05 551.036 1594.04 551.214C1594.01 551.376 1593.98 551.538 1593.9 551.689C1593.9 551.689 1593.15 553.114 1592.4 554.533C1591.66 555.955 1590.92 557.371 1590.92 557.371ZM1588.23 548.701C1588.23 548.701 1587.49 550.119 1586.73 551.541C1585.98 552.96 1585.23 554.375 1585.23 554.375L1589.78 556.769C1589.78 556.769 1590.52 555.344 1591.28 553.932C1592.02 552.516 1592.77 551.091 1592.77 551.091L1588.23 548.701Z"
        fill="white"
      />
      <path
        d="M1601 557.569C1600.43 558.692 1599.61 559.07 1598.59 558.729L1598.42 562.727L1597.16 562.078L1597.33 558.131L1594.83 556.814L1593.17 560.005L1592.03 559.413C1592.03 559.413 1593.07 557.417 1594.1 555.427C1595.14 553.432 1596.19 551.455 1596.19 551.455L1600.55 553.727C1601.84 554.383 1602.15 555.368 1601.47 556.664L1601 557.569ZM1595.43 555.686L1598.59 557.335C1598.89 557.489 1599.15 557.529 1599.35 557.479C1599.55 557.417 1599.73 557.229 1599.89 556.927L1600.3 556.135C1600.46 555.826 1600.51 555.575 1600.44 555.373C1600.38 555.161 1600.18 554.979 1599.89 554.826L1596.74 553.176L1595.43 555.686Z"
        fill="white"
      />
      <path
        d="M1631.73 565.286C1631.73 565.286 1631.35 566.052 1630.78 567.21C1630.21 568.358 1629.44 569.898 1628.69 571.441C1627.93 572.974 1627.16 574.514 1626.59 575.662C1626.02 576.82 1625.64 577.593 1625.64 577.593L1623.13 576.352C1623.13 576.352 1623.51 575.58 1624.09 574.425C1624.65 573.27 1625.42 571.734 1626.19 570.198C1626.95 568.658 1627.71 567.115 1628.29 565.97C1628.87 564.816 1629.24 564.055 1629.24 564.055L1631.73 565.286Z"
        fill="white"
      />
      <path
        d="M1639.91 574.276C1639.91 574.276 1638.9 576.358 1637.89 578.444C1636.86 580.533 1635.85 582.618 1635.85 582.618C1635.22 583.912 1634.2 584.706 1633.04 585.029C1631.88 585.358 1630.57 585.208 1629.34 584.601C1628.15 584.018 1627.29 583.142 1626.83 582.134C1626.39 581.132 1626.34 579.996 1626.77 578.915L1629.25 580.167C1628.92 581.088 1629.5 581.872 1630.53 582.376C1631.51 582.84 1632.66 582.773 1633.33 581.416L1633.84 580.356C1633 580.462 1632.06 580.305 1631.14 579.845C1630.01 579.294 1629.21 578.323 1628.83 577.222C1628.44 576.132 1628.48 574.902 1629.01 573.842C1629.62 572.603 1630.58 571.788 1631.68 571.426C1632.78 571.07 1634.01 571.148 1635.14 571.701C1636.04 572.155 1636.74 572.798 1637.19 573.515L1637.41 573.074L1639.91 574.276ZM1635.01 577.967L1636 575.935C1635.86 574.934 1635.29 574.342 1634.58 573.995C1633.35 573.391 1632.1 573.807 1631.48 575.056C1631.02 575.99 1631.4 577.408 1632.61 578.014C1633.31 578.367 1634.15 578.465 1635.01 577.967Z"
        fill="white"
      />
      <path
        d="M1647.93 583.176C1647.32 584.463 1646.31 585.334 1645.15 585.736C1643.98 586.135 1642.65 586.056 1641.37 585.438C1640.09 584.821 1639.19 583.822 1638.77 582.665C1638.36 581.505 1638.42 580.174 1639.05 578.887C1639.67 577.612 1640.68 576.731 1641.85 576.326C1643.01 575.924 1644.34 575.997 1645.62 576.615C1646.89 577.223 1647.77 578.217 1648.19 579.387C1648.6 580.553 1648.54 581.888 1647.93 583.176ZM1645.5 582.001C1646.09 580.789 1645.77 579.433 1644.55 578.84C1643.32 578.245 1642.06 578.846 1641.46 580.055C1640.87 581.304 1641.21 582.613 1642.45 583.217C1643.68 583.779 1644.9 583.247 1645.5 582.001Z"
        fill="white"
      />
      <path
        d="M1665.48 586.456C1665.48 586.456 1664.49 588.549 1663.52 590.648C1662.55 592.738 1661.58 594.828 1661.58 594.828L1659.09 593.677L1659.3 593.216C1658.47 593.326 1657.51 593.206 1656.58 592.775C1655.44 592.237 1654.64 591.257 1654.26 590.113C1653.88 588.964 1653.94 587.658 1654.5 586.449C1655.09 585.234 1656.06 584.352 1657.19 583.914C1658.31 583.473 1659.57 583.462 1660.71 584.01C1661.62 584.431 1662.33 585.087 1662.77 585.795L1662.99 585.331L1665.48 586.456ZM1660.42 590.776L1661.62 588.209C1661.44 587.23 1660.82 586.678 1660.11 586.34C1658.84 585.755 1657.47 586.353 1656.9 587.581C1656.34 588.778 1656.75 590.218 1658 590.797C1658.73 591.141 1659.55 591.277 1660.42 590.776Z"
        fill="white"
      />
      <path
        d="M1673.86 594.994L1671.78 599.553L1669.33 598.439L1671.28 594.183C1671.91 592.8 1671.57 591.788 1670.36 591.229C1669.73 590.932 1668.86 590.971 1667.94 591.397C1667.94 591.397 1667.34 592.707 1666.73 594.037C1666.12 595.353 1665.5 596.673 1665.5 596.673L1663 595.506C1663 595.506 1663.97 593.416 1664.94 591.332C1665.92 589.236 1666.89 587.137 1666.89 587.137L1669.38 588.303L1669.08 588.922C1670.01 588.684 1670.94 588.771 1671.86 589.196C1672.98 589.704 1673.75 590.537 1674.11 591.55C1674.48 592.565 1674.43 593.763 1673.86 594.994Z"
        fill="white"
      />
      <path
        d="M1622 575.754L1619.47 574.494C1619.47 574.494 1619.81 572.748 1620.17 570.995C1620.53 569.253 1620.89 567.513 1620.89 567.513C1620.89 567.513 1619.23 568.6 1617.57 569.696C1615.91 570.789 1614.25 571.872 1614.25 571.872L1611.41 570.436C1611.41 570.436 1612.05 570.02 1613.02 569.394C1613.98 568.748 1615.26 567.908 1616.55 567.072C1617.84 566.22 1619.11 565.37 1620.07 564.731C1621.04 564.105 1621.68 563.685 1621.68 563.685L1622.48 559.898C1622.48 559.898 1621.6 560.479 1620.31 561.337C1619.01 562.189 1617.29 563.335 1615.56 564.477C1613.82 565.61 1612.09 566.753 1610.78 567.601C1609.49 568.456 1608.61 569.024 1608.61 569.024L1605.79 567.573C1605.79 567.573 1606.89 566.86 1608.54 565.789C1609.37 565.253 1610.33 564.616 1611.37 563.937C1612.4 563.271 1613.49 562.552 1614.59 561.826C1615.69 561.107 1616.79 560.381 1617.81 559.705C1618.84 559.029 1619.8 558.393 1620.62 557.849C1622.27 556.766 1623.36 556.053 1623.36 556.053L1625.82 557.295L1624.86 561.882L1627.41 563.145L1624.15 565.336C1624.15 565.336 1623.6 567.938 1623.06 570.548C1622.53 573.157 1622 575.754 1622 575.754Z"
        fill="white"
      />
      <path
        d="M1687.54 591.632C1687.54 591.632 1687.19 592.415 1686.65 593.583C1686.12 594.755 1685.43 596.331 1684.72 597.891C1684.01 599.46 1683.31 601.027 1682.78 602.195C1682.26 603.37 1681.91 604.153 1681.91 604.153L1679.35 603.007L1679.57 602.54C1678.73 602.656 1677.78 602.549 1676.85 602.131C1675.69 601.602 1674.87 600.628 1674.48 599.487C1674.09 598.347 1674.13 597.031 1674.69 595.809C1675.25 594.597 1676.21 593.709 1677.33 593.251C1678.44 592.797 1679.7 592.786 1680.86 593.302C1681.77 593.727 1682.49 594.354 1682.94 595.075L1685.01 590.487L1687.54 591.632ZM1680.68 600.087L1681.84 597.504C1681.67 596.508 1681.01 595.958 1680.28 595.63C1679 595.054 1677.65 595.672 1677.09 596.907C1676.53 598.141 1676.95 599.579 1678.23 600.158C1678.96 600.486 1679.78 600.616 1680.68 600.087Z"
        fill="white"
      />
      <path
        d="M1655.02 584.151C1654.44 583.951 1653.13 583.422 1651.89 584.093L1649.47 589.162L1646.99 587.973C1646.99 587.973 1647.97 585.896 1648.97 583.82C1649.97 581.743 1650.96 579.654 1650.96 579.654L1653.43 580.845L1653.13 581.47C1653.93 581.249 1654.92 581.356 1656.03 582.021"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_17_629"
        x1="959.836"
        y1="1.00002"
        x2="676.336"
        y2="870.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D45151" stopOpacity="0" />
        <stop offset="0.251326" stopColor="#D45151" />
        <stop offset="1" stopColor="#D45151" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_17_629"
        x1="960.836"
        y1="1.00002"
        x2="1244.34"
        y2="870.39"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D45151" stopOpacity="0" />
        <stop offset="0.251326" stopColor="#D45151" />
        <stop offset="1" stopColor="#D45151" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default Lines
