import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import AlgoPadImg from './Items/AlgoPadImg'
import SigmaImg from './Items/SigmaImg'
import AlgoDaoImg from './Items/AlgoDaoImg'
import emptyLine from 'images/hierarchy/empty.svg'
import btnBorder from 'images/hierarchy/btn-border.svg'
import bgFirst from 'images/hierarchy/bg-first.svg'
import bgCenter from 'images/hierarchy/bg-center.svg'
import bgLast from 'images/hierarchy/bg-last.svg'
import firstArrows from 'images/hierarchy/first-arrows.svg'
import firstArrowsMd from 'images/hierarchy/first-arrows-md.svg'
import centerArrows from 'images/hierarchy/center-arrows.svg'
import centerArrowsMd from 'images/hierarchy/center-arrows-md.svg'
import lastArrows from 'images/hierarchy/last-arrows.svg'
import lastArrowsMd from 'images/hierarchy/last-arrows-md.svg'
import { Container } from 'theme/theme'
import Slider from 'react-slick'
import useWindowSize from '../../../helpers/utils/useWindowSize'
import Dots from './dots'

const Hierarchy: React.FunctionComponent = () => {
  const [activeIdx, setActiveIdx] = useState(1)
  const sliderRef = useRef(null)
  const [activeSlideIdx, setActiveSlideIdx] = useState(0)
  const { width } = useWindowSize()

  const settings = {
    centerMode: true,
    infinite: true,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
    beforeChange: (_: any, next: React.SetStateAction<number>) =>
      setActiveSlideIdx(next),
  }

  const changeActive = (id: number) => {
    setActiveIdx(id)
  }

  const MobSlider = (
    <SliderWrap>
      <DotsWrap>
        <Dots activeIdx={activeSlideIdx} />
      </DotsWrap>
      <Slider ref={sliderRef} {...settings} className="slider">
        <SlideWrap>
          <Slide>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              FEDERATION
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO citizens</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>Elligible for AlgoPAD participation</ItemText>
            <EmptyLine />
            <EmptyLine />
            <ItemText>
              <span>Linear</span> voting
            </ItemText>
            <EmptyLine />
            <ItemText>
              Can <span>delegate stakes</span> for High Council seat
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>*/}
            {/*  STAKE <span>ADaO</span>*/}
            {/*</Button>*/}
          </Slide>
        </SlideWrap>
        <SlideWrap>
          <Slide>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              High Council
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO democratic board</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>Project Access voting</ItemText>
            <EmptyLine />
            <ItemText>Creating proposals</ItemText>
            <ItemText>
              <span>Linear</span> voting
            </ItemText>
            <ItemText>Pre-money funding access</ItemText>
            <ItemText>
              <span>Can</span> be stacked
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>*/}
            {/*  STAKE <span>ADaO</span>*/}
            {/*</Button>*/}
          </Slide>
        </SlideWrap>
        <SlideWrap>
          <Slide>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              Vanguard
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO governing council</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>
              Project Access <span>sign-off</span>
            </ItemText>
            <ItemText>Project Fundraising sign-off</ItemText>
            <ItemText>Creating proposals</ItemText>
            <ItemText>
              <span>Quadratic</span> voting
            </ItemText>
            <ItemText>Pre-money funding access</ItemText>
            <ItemText>
              Can<span>not</span> be stacked
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>*/}
            {/*  STAKE <span>ADaO</span>*/}
            {/*</Button>*/}
          </Slide>
        </SlideWrap>
      </Slider>
    </SliderWrap>
  )

  const Desktop = (
    <Container>
      <Cont idx={activeIdx}>
        <Item active={activeIdx === 1}>
          <ItemImg>
            <AlgoPadImg
              active={activeIdx === 1}
              right={activeIdx === 2 || activeIdx === 3}
            />
          </ItemImg>
          <ItemTextBlock onMouseEnter={() => changeActive(1)}>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              FEDERATION
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO citizens</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>Elligible for AlgoPAD participation</ItemText>
            <EmptyLine />
            <EmptyLine />
            <ItemText>
              <span>Linear</span> voting
            </ItemText>
            <EmptyLine />
            <ItemText>
              Can <span>delegate stakes</span> for High Council seat
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>STAKE ADaO</Button>*/}
          </ItemTextBlock>
          <FirstArrows />
        </Item>
        <Item active={activeIdx === 2}>
          <ItemImg>
            <SigmaImg
              left={activeIdx === 1 || activeIdx === 2}
              right={activeIdx === 3 || activeIdx === 2}
            />
          </ItemImg>
          <ItemTextBlock onMouseEnter={() => changeActive(2)}>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              High Council
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO democratic board</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>Project Access voting</ItemText>
            <EmptyLine />
            <ItemText>Creating proposals</ItemText>
            <ItemText>
              <span>Linear</span> voting
            </ItemText>
            <ItemText>Pre-money funding access</ItemText>
            <ItemText>
              <span>Can</span> be stacked
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>STAKE ADaO</Button>*/}
          </ItemTextBlock>
          <CenterArrows />
        </Item>
        <Item active={activeIdx === 3}>
          <ItemImg>
            <AlgoDaoImg
              active={activeIdx === 3}
              left={activeIdx === 2 || activeIdx === 1}
            />
          </ItemImg>
          <ItemTextBlock onMouseEnter={() => changeActive(3)}>
            <ItemLevel>Your level</ItemLevel>
            <ItemTitle>
              Vanguard
              <TitleBorder />
            </ItemTitle>
            <ItemSubTitle>AlgoDAO governing council</ItemSubTitle>
            <GrayText>your advantages</GrayText>
            <ItemText>
              Project Access <span>sign-off</span>
            </ItemText>
            <ItemText>Project Fundraising sign-off</ItemText>
            <ItemText>Creating proposals</ItemText>
            <ItemText>
              <span>Quadratic</span> voting
            </ItemText>
            <ItemText>Pre-money funding access</ItemText>
            <ItemText>
              Can<span>not</span> be stacked
            </ItemText>
            <ItemText>SIGMA pro-rata farming</ItemText>
            {/*<Button>*/}
            {/*  STAKE <span>ADaO</span>*/}
            {/*</Button>*/}
          </ItemTextBlock>
          <LastArrows />
        </Item>
      </Cont>
    </Container>
  )

  return (
    <Root>
      <Container>
        <Title>member hierarchy</Title>
        <SubTitle>A carefully balanced governance framework</SubTitle>
      </Container>
      {(() => {
        if (width <= 900) {
          return MobSlider
        }
        return Desktop
      })()}
    </Root>
  )
}

export default Hierarchy

interface bgProps {
  idx: number
}

interface itemProps {
  active: boolean
}

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 146px 0 82px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 112px 0 72px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 96px 0 62px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 82px 0 53px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 65px 0 30px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 50px 0 128px;
    z-index: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 50px 0 130px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 40px 0 107px;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    bottom: -45px;
    position: absolute;
    background: #fafafa;
    ${({ theme }) => theme.adaptive.index.md} {
      bottom: -35px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      bottom: -61px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      bottom: -63px;
    }
  }
`

const Title = styled.div`
  font-family: 'Aldrich';
  font-size: 68px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 51px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 44px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 41px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 33px;
  }
`

const SubTitle = styled.div`
  font-family: 'Aldrich';
  font-size: 16px;
  line-height: 138%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #2d3037;
  opacity: 0.3;
  margin-bottom: 68px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    margin-bottom: 59px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    margin-bottom: 51px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 11px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-bottom: 28px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    margin-bottom: 59px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 221px;
    margin: 0 auto;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 12px;
    width: 189px;
    margin-bottom: 41px;
  }
`

const Cont = styled.div<bgProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 1760px;
  height: 943px;
  padding: 50px 44px;
  margin: 0 auto;
  transition: 0.3s;
  z-index: 1;
  background: #FFFFFF;
  ${({ idx }) => {
    if (idx === 1) {
      return css`
        border-radius: 20px 20px 20px 0;
      `
    } else if (idx === 2) {
      return css`
        border-radius: 20px;
      `
    } else if (idx === 3) {
      return css`
        border-radius: 20px 20px 0 20px;
      `
    }
  }}
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1540px;
    height: 825px;
    padding: 43px 39px;
    ${({ idx }) => {
      if (idx === 1) {
        return css`
          border-radius: 18px 18px 18px 0;
        `
      } else if (idx === 2) {
        return css`
          border-radius: 18px;
        `
      } else if (idx === 3) {
        return css`
          border-radius: 18px 18px 0 18px;
        `
      }
    }}
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1320px;
    height: 707px;
    padding: 37px 33px;
    ${({ idx }) => {
      if (idx === 1) {
        return css`
          border-radius: 15px 15px 15px 0;
        `
      } else if (idx === 2) {
        return css`
          border-radius: 15px;
        `
      } else if (idx === 3) {
        return css`
          border-radius: 15px 15px 0 15px;
        `
      }
    }}
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1137px;
    height: 609px;
    padding: 32px 28px;
    ${({ idx }) => {
      if (idx === 1) {
        return css`
          border-radius: 13px 13px 13px 0;
        `
      } else if (idx === 2) {
        return css`
          border-radius: 13px;
        `
      } else if (idx === 3) {
        return css`
          border-radius: 13px 13px 0 13px;
        `
      }
    }}
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 843px;
    height: 468px;
    padding: 23px 21px;
    ${({ idx }) => {
      if (idx === 1) {
        return css`
          border-radius: 10px 10px 10px 0;
        `
      } else if (idx === 2) {
        return css`
          border-radius: 10px;
        `
      } else if (idx === 3) {
        return css`
          border-radius: 10px 10px 0 10px;
        `
      }
    }}
  }
  &:before {
    content: '';
    position: absolute;
    width: 590px;
    height: 125px;
    left: 0;
    bottom: -100px;
    transition: 0.3s;
    background: #FFFFFF;
    border-radius: 0 0 20px 20px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 516px;
      height: 109px;
      border-radius: 0 0 18px 18px;
      bottom: -88px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 443px;
      height: 94px;
      border-radius: 0 0 15px 15px;
      bottom: -75px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 381px;
      height: 81px;
      border-radius: 0 0 13px 13px;
      bottom: -65px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 283px;
      height: 62px;
      border-radius: 0 0 10px 10px;
      bottom: -50px;
    }
    ${({ idx }) => {
      if (idx === 1) {
        return css``
      } else if (idx === 2) {
        return css`
          left: 50%;
          transform: translateX(-50%);
        `
      } else if (idx === 3) {
        return css`
          left: unset;
          right: 0;
        `
      }
    }}
`

const ItemImg = styled.div`
  width: 415px;
  height: 356px;
  margin: 0 auto 35px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 360px;
    height: 312px;
    margin: 0 auto 32px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 308px;
    height: 267px;
    margin: 0 auto 27px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 265px;
    height: 230px;
    margin: 0 auto 23px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 197px;
    height: 171px;
    margin: 0 auto 17px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`

const GrayCss = css`
  font-family: 'MMC';
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  text-transform: uppercase;
  color: #bcbcbc;
  margin-bottom: 11px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 11px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 9px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 9px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 8px;
    margin-bottom: 3px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 10px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 9px;
    margin-bottom: 8px;
  }
`

const GrayText = styled.div`
  ${GrayCss}
`

const ItemTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #535354;
  margin-bottom: 19px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 30px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 26px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 22px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 16px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 26px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 22px;
    margin-bottom: 11px;
  }
`

const TitleBorder = styled.div`
  position: absolute;
  width: 163px;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 143px;
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 122px;
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 105px;
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 78px;
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 131px;
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 112px;
  }
`

const ItemSubTitle = styled.div`
  font-family: 'Aldrich';
  font-size: 20px;
  line-height: 138.3%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #d7544f;
  margin-bottom: 26px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 16px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 14px;
    margin-bottom: 18px;
  }
`

const ItemText = styled.div`
  font-family: 'MMC';
  font-size: 20px;
  line-height: 129.5%;
  text-align: center;
  color: #535354;
  margin-bottom: 15px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 16px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 14px;
    margin-bottom: 10px;
  }
  span {
    color: #d7544f;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const EmptyLine = styled.div`
  width: 203px;
  height: 26px;
  background: url('${emptyLine}') center no-repeat;
  background-size: cover;
  margin: 0 auto 15px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 177px;
    height: 23px;
    margin: 0 auto 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 152px;
    height: 20px;
    margin: 0 auto 11px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 130px;
    height: 17px;
    margin: 0 auto 9px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 97px;
    height: 12px;
    margin: 0 auto 9px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 162px;
    height: 21px;
    margin: 0 auto 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 138px;
    height: 18px;
    margin: 0 auto 10px;
  }
`

const Button = styled.button`
  position: relative;
  width: 502px;
  height: 52px;
  border-radius: 7px;
  padding: 14px;
  margin: 49px auto 0;
  outline: none;
  transition: 0.3s;

  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  background: #353842;
  box-shadow: 0px 19px 31px rgba(0, 0, 0, 0.27);
  z-index: 1;
  &:before {
    box-shadow: 0px 19px 31px #d7544f;
  }
  span {
    color: #d7544f;
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 439px;
    height: 52px;
    border-radius: 7px;
    padding: 14px;
    margin: 42px auto 0;

    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 377px;
    height: 44px;
    border-radius: 6px;
    padding: 13px;
    margin: 36px auto 0;

    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 324px;
    height: 38px;
    border-radius: 5px;
    padding: 11px;
    margin: 31px auto 0;

    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 241px;
    height: 28px;
    border-radius: 4px;
    padding: 8px;
    margin: 36px auto 0;

    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    top: 464px;
    left: 50%;
    transform: translate(-50%);
    width: 304px;
    height: 42px;
    border-radius: 5px;
    padding: 12px;

    font-size: 18px;
    transition: 0.4s;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 300px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    top: 396px;
    width: 256px;
    height: 36px;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 289px;
    height: 2px;
    background: url('${btnBorder}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 253px;
      height: 2px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 217px;
      height: 2px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 187px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 139px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 172px;
      height: 2px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 147px;
      height: 2px;
    }
  }
`

const FirstArrows = styled.div`
  position: absolute;
  left: 7px;
  top: 176px;
  width: 1214px;
  height: 645px;
  transition: 0.3s;
  background: url('${firstArrows}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: 154px;
    width: 1062px;
    height: 565px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    top: 132px;
    width: 911px;
    height: 484px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    top: 114px;
    width: 784px;
    height: 417px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: 84px;
    width: 583px;
    height: 322px;
    background: url('${firstArrowsMd}') center no-repeat;
    background-size: cover;
  }
`

const CenterArrows = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 176px;
  width: 768px;
  height: 645px;
  transition: 0.3s;
  background: url('${centerArrows}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: 154px;
    width: 672px;
    height: 564px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    top: 131px;
    width: 576px;
    height: 483px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    top: 114px;
    width: 496px;
    height: 416px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: 84px;
    width: 368px;
    height: 322px;
    background: url('${centerArrowsMd}') center no-repeat;
    background-size: cover;
  }
`

const LastArrows = styled.div`
  position: absolute;
  right: 7px;
  top: 175px;
  width: 1213px;
  height: 643px;
  transition: 0.3s;
  background: url('${lastArrows}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: 153px;
    width: 1061px;
    height: 562px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    top: 130px;
    width: 909px;
    height: 482px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    top: 113px;
    width: 783px;
    height: 415px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: 84px;
    width: 581px;
    height: 322px;
    background: url('${lastArrowsMd}') center no-repeat;
    background-size: cover;
  }
`

const ItemLevel = styled.div`
  ${GrayCss}
`

const Item = styled.div<itemProps>`
  position: relative;
  width: 502px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 439px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 324px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 241px;
  }
  ${Button} {
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  ${ItemText}, ${ItemTitle}, ${ItemSubTitle} {
    transition: 0.3s;
    color: ${({ active }) => (active ? '#535354' : '#D3D3D3')};
    span {
      color: ${({ active }) => (active ? '#D7544F' : '#D3D3D3')};
    }
  }
  ${FirstArrows}, ${CenterArrows}, ${LastArrows} {
    pointer-events: none;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  ${ItemLevel} {
    transition: 0.3s;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  ${TitleBorder} {
    transition: 0.3s;
    background: ${({ active }) =>
      active
        ? css`linear-gradient(
        270deg,
        rgba(236, 72, 79, 0) 0%,
        #ec484f 47.89%,
        rgba(236, 72, 79, 0) 100%
    )`
        : css`linear-gradient(270deg, rgba(154, 154, 154, 0) 0%, #9A9A9A 47.89%, rgba(154, 154, 154, 0) 100%)`};
    opacity: ${({ active }) => (active ? 1 : 0.2)};
  }
`

const ItemTextBlock = styled.div``

const Slide = styled.div`
  position: relative;
  width: 306px;
  height: 436px;
  background: #ffffff;
  border: 1px solid rgba(183, 183, 183, 0.23);
  border-radius: 7px;
  padding: 27px 0;
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 298px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 254px;
    height: 372px;
  }
`

const SliderWrap = styled.div`
  position: relative;
  width: 100vw;
  .slider {
    .slick-slide {
      width: 306px;
      height: 436px;
      transition: 0.4s;
      ${({ theme }) => theme.adaptive.index.smm} {
        width: 298px;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        width: 254px;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-slide:not(.slick-active) {
      transform: scale(0.8);
      ${Slide} {
        opacity: 0.5;
      }
      ${Button} {
        opacity: 0;
      }
      ${ItemText}, ${ItemTitle}, ${ItemSubTitle} {
        transition: 0.3s;
        color: #d3d3d3;
        span {
          color: #d3d3d3;
        }
      }
    }
    .slick-slide.slick-active {
      transform: scale(1);
      ${Slide} {
        opacity: 1;
      }
      ${Button} {
        opacity: 1;
      }
      ${ItemText}, ${ItemTitle}, ${ItemSubTitle} {
        transition: 0.3s;
        color: #535354;
        span {
          color: #d7544f;
        }
      }
    }
    ${Button} {
      color: #ffffff;
      background: #353842;
      box-shadow: 0px 19px 31px rgba(0, 0, 0, 0.27);
      border: 0px;
    }
  }
`

const SlideWrap = styled.div``

const DotsWrap = styled.div`
  position: absolute;
  top: -18px;
  width: 100%;
  ${({ theme }) => theme.adaptive.index.xs} {
    top: -15px;
  }
`
