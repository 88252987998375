import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import titleImg from 'images/index/title-img.svg'
import bgImg from 'images/index/main/bg.svg'
import redBorder from 'images/index/main/red-border.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import mainList from '../../../store/mainList'
import { Container } from '../../../theme/theme'
import rocketIcon from 'images/index/main/rocket-svg.svg'
import starIcon from 'images/rho/main/star.svg'
import { drawCanvas, FadeIn } from './animations'
import RocketFire from './RocketBg'
import About from '../../Rho/about'
import { ReactComponent as BtnStar } from 'images/rho/main/star-btn.svg'
import { Link } from 'react-router-dom'
import starsBg from 'images/rho/main/stars-bg.svg'
import starsBgSm from 'images/rho/main/stars-bg-sm.svg'

interface Props {
  isMain?: boolean
}

const Main: React.FunctionComponent<Props> = (props) => {
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)
  const ItemRef = useRef([])
  const TitleRef = useRef(null)
  const RocketRef = useRef(null)
  const buttonRef = useRef(null)
  const RocketBgRef = useRef<any>(null)
  const SubTitleRef = useRef(null)
  const rhoTitleRef = useRef(null)
  const rhoSubTitleRef = useRef(null)
  const rhoButtonRef = useRef(null)
  ItemRef.current = []
  const { width } = useWindowSize()

  useEffect(() => {
    if (width > 900) {
      const handleResize = () => {
        clearTimeout(resizeTimeout)
        setReszieTimeout(
          setTimeout(() => {
            if (RocketBgRef.current) {
              RocketBgRef.current.style.height = ''
            }
            if (RocketRef.current) {
              FadeIn(RocketRef.current, 0, 200)
            }
            if (rhoTitleRef.current) {
              FadeIn(rhoTitleRef.current, 0.45, 50)
            }
            if (rhoSubTitleRef.current) {
              FadeIn(rhoSubTitleRef.current, 0.5, 50)
            }
            if (rhoButtonRef.current) {
              FadeIn(rhoButtonRef.current, 0.55, 50)
            }
            if (TitleRef.current) {
              FadeIn(TitleRef.current, 0.6, 50)
            }
            if (SubTitleRef.current) {
              FadeIn(SubTitleRef.current, 0.65, 50)
            }
            if (buttonRef.current) {
              FadeIn(buttonRef.current, 0.55, 50)
            }
            if (ItemRef.current) {
              let delay = 1.7
              ItemRef.current.forEach((el) => {
                delay += 0.1
                FadeIn(el, delay, 50)
              })
            }
            if (RocketBgRef.current) {
              drawCanvas(RocketBgRef.current, RocketBgRef.current.offsetHeight)
            }
          }, 300)
        )
      }
      handleResize()
    }
  }, [width])

  const addToItemRefs = (el: any) => {
    // @ts-ignore
    if (el && !ItemRef.current.includes(el)) {
      // @ts-ignore
      ItemRef.current.push(el)
    }
  }

  return (
    <Root isMain={props.isMain}>
      <Container>
        <TitleWrap>
          {/*{props.isMain && (*/}
          {/*  <RHOWrap>*/}
          {/*    <RHOTitle ref={rhoTitleRef}>*/}
          {/*      <Stars />*/}
          {/*      Reverse Hackathon Offering (RHO)*/}
          {/*    </RHOTitle>*/}
          {/*    <RHOSubTitle ref={rhoSubTitleRef}>*/}
          {/*      A Community-Driven Mechanism for Early-Stage Funding on AlgoDAO*/}
          {/*    </RHOSubTitle>*/}
          {/*    <RHOButton ref={rhoButtonRef}>*/}
          {/*      <Link to={'/rho'}>*/}
          {/*        Learn more*/}
          {/*        <BtnStar />*/}
          {/*      </Link>*/}
          {/*    </RHOButton>*/}
          {/*  </RHOWrap>*/}
          {/*)}*/}
          {props.isMain ? (
            <Title ref={TitleRef} />
          ) : (
            <TextTitle ref={rhoTitleRef}>
              Reverse Hackathon Offering (RHO)
            </TextTitle>
          )}

          <Rocket isMain={props.isMain} ref={RocketRef}>
            <RocketBgAnim ref={RocketBgRef}>
              <RocketBgWrap>
                <RocketFire />
              </RocketBgWrap>
            </RocketBgAnim>
          </Rocket>
        </TitleWrap>
        {props.isMain ? (
          <IndexSubTitle ref={SubTitleRef}>
            {width > 900
              ? 'A DAO-governed  INCUBATOR  for Algorand'
              : 'DAO-governed primary markets infrastructure Built on and FOR'}
          </IndexSubTitle>
        ) : (
          <SubTitle ref={rhoSubTitleRef}>
            A Community-Driven Mechanism for Early-Stage Funding on AlgoDAO
          </SubTitle>
        )}
        {!props.isMain && (
          <Button
            href={'https://app.algodao.fi/project/830309475'}
            target={'_blank'}
            isDisable={false}
            ref={buttonRef}
          >
            Buy RHO-1 Tokens
          </Button>
        )}
        {props.isMain ? (
          <Row>
            {mainList.map((item, idx) => {
              return (
                <Item key={idx} ref={addToItemRefs}>
                  <ItemIcon src={item.img} />
                  <ItemTitle>{item.name}</ItemTitle>
                  <ItemText>{item.text}</ItemText>
                </Item>
              )
            })}
          </Row>
        ) : (
          <AboutWrap ref={addToItemRefs}>
            <About />
          </AboutWrap>
        )}
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div<{ isMain?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 244px;
  padding-bottom: 109px;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-top: 211px;
    padding-bottom: 96px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-top: 181px;
    padding-bottom: 59px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-top: 156px;
    padding-bottom: 47px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-top: 105px;
    padding-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-top: 133px;
    padding-bottom: ${({ isMain }) => (isMain ? '54px' : ' 0')};
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding-top: 248px;
    padding-bottom: ${({ isMain }) => (isMain ? ' 97px' : ' 0')};
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-top: 208px;
    padding-bottom: ${({ isMain }) => (isMain ? ' 60px' : ' 0')};
  }
  &:before {
    content: '';
    position: absolute;
    top: -137px;
    left: 50%;
    transform: translateX(-50%);
    width: 6049px;
    height: 2279px;
    background: url('${bgImg}') center no-repeat;
    mix-blend-mode: screen;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -123px;
      width: 5293px;
      height: 1994px;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -104px;
      width: 4537px;
      height: 1709px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -90px;
      width: 3907px;
      height: 1472px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -75px;
      width: 2898px;
      height: 1092px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: -61px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      top: -56px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      top: -56px;
      width: 2473px;
      height: 979px;
    }
  }
`

const TitleWrap = styled.div`
  position: relative;
  width: 1207px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1056px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 905px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 780px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 578px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 421px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
  }
`

const Title = styled.div`
  position: relative;
  width: 100%;
  height: 111px;
  background: url('${titleImg}') center no-repeat;
  background-size: cover;
  margin-bottom: 49px;
  opacity: 0;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    height: 97px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 83px;
    margin-bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    height: 72px;
    margin-bottom: 32px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    height: 53px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    height: 39px;
    margin-bottom: 16px;
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 299px;
    height: 27px;
    margin: 0 auto;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 255px;
    height: 23px;
    margin-bottom: 18px;
  }
`

const Rocket = styled.div<{ isMain?: boolean }>`
  position: absolute;
  width: ${({ isMain }) => (isMain ? '95px' : '162px')};
  height: ${({ isMain }) => (isMain ? '118px' : '162px')};
  top: -228px;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ isMain }) =>
      isMain ? `url(${rocketIcon})` : `url(${starIcon})`}
    center no-repeat;
  opacity: 0;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: ${({ isMain }) => (isMain ? '83px' : '142px')};
    height: ${({ isMain }) => (isMain ? '103px' : '142px')};
    top: -200px;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: ${({ isMain }) => (isMain ? '71px' : '122px')};
    height: ${({ isMain }) => (isMain ? '88px' : '122px')};
    top: -171px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: ${({ isMain }) => (isMain ? '61px' : '105px')};
    height: ${({ isMain }) => (isMain ? '76px' : '105px')};
    top: -147px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 45px;
    height: 56px;
    top: -100px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    top: -125px;
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 101px;
    height: 126px;
    top: -168px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 87px;
    height: 107px;
    top: -143px;
  }
`

const Anim = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const RocketBgAnim = styled.div`
  position: absolute;
  width: 2958px;
  height: 1702px;
  top: 59px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 2590px;
    height: 1489px;
    top: 46px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 2220px;
    height: 1276px;
    top: 45px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1912px;
    height: 1099px;
    top: 38px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 1418px;
    height: 815px;
    top: 29px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 3171px;
    height: 1822px;
    top: 56px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 2706px;
    height: 1555px;
    top: 48px;
  }
`

const RocketBgWrap = styled.div`
  position: absolute;
  width: 2958px;
  height: 1702px;
  top: 0;
  left: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    height: 1489px;
    left: -185px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 1276px;
    left: -370px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    height: 1099px;
    left: -524px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    left: -770px;
    height: 815px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 1822px;
    left: 105px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    height: 1555px;
    left: -126px;
  }
`

const IndexSubTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 37px;
  line-height: 133%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 27px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 15px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 129px 0 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin: 113px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin: 97px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin: 83px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin: 59px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin: 81px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    display: block;
    margin: 117px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 129px 0 0;
  }
`

const Item = styled.div`
  position: relative;
  opacity: 0;
  will-change: transform;
  
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
    }
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    opacity: 1;
    margin-left: 113px;
    margin-bottom: 37px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ItemIcon = styled.img`
  display: block;
  width: 78px;
  height: 60px;
  margin: 0 auto 14px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 73px;
    height: 56px;
    margin: 0 auto 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 65px;
    height: 50px;
    margin: 0 auto 11px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 52px;
    height: 40px;
    margin: 0 auto 10px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 40px;
    height: 31px;
    margin: 0 auto 12px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    position: absolute;
    left: -113px;
    top: 50%;
    transform: translateY(-50%);
    width: 73px;
    height: 56px;
    margin: 0;
  }
`

const ItemTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  margin-bottom: 16px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 23px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 20px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 17px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 14px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 16px;
    margin-bottom: 12px;
    text-align: left;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 124px;
    height: 2px;
    background: url('${redBorder}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 109px;
      height: 2px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 93px;
      height: 2px;
      bottom: -4px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 80px;
      height: 2px;
      bottom: -3px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 61px;
      height: 1px;
      bottom: -3px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      width: 60px;
      height: 1px;
      bottom: -4px;
      left: 31px;
    }
  }
`

const ItemText = styled.div`
  font-family: 'MMC';
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  opacity: 0.3;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 14px;
    max-width: 260px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    max-width: 224px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    max-width: 166px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    max-width: 120px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 14px;
    width: 180px;
    max-width: unset;
    text-align: left;
  }
`

const TextTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 64px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  width: 889px;
  margin: 0 auto 35px;
  opacity: 0;
  z-index: 2;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin: 0 auto 30px;
    font-size: 56px;
    width: 778px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin: 0 auto 26px;
    font-size: 48px;
    width: 667px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin: 0 auto 22px;
    font-size: 42px;
    width: 574px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin: 0 auto 15px;
    font-size: 32px;
    width: 426px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 332px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 134%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  opacity: 0;
  background: linear-gradient(
    90.21deg,
    #bcbcbc -5.72%,
    #c4c4c4 49.33%,
    #bcbcbc 99.97%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 1012px;
  margin: 0 auto 35px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin: 0 auto 30px;
    font-size: 32px;
    width: 886px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin: 0 auto 26px;
    font-size: 26px;
    width: 759px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin: 0 auto 22px;
    font-size: 22px;
    width: 635px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin: 0 auto 15px;
    font-size: 16px;
    width: 485px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 265px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const Button = styled.a<{ isDisable: boolean }>`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 429px;
  height: 56px;
  padding: 20px 0 16px;
  opacity: 0;
  background: linear-gradient(
    90.32deg,
    #cf4858 -23.2%,
    #dc5a4c 52.6%,
    #cf4858 104.91%
  );
  box-shadow:
    0px 12px 30px -9px rgba(219, 84, 78, 0.28),
    0px 17px 13px -16px rgba(219, 84, 78, 0.51);
  border-radius: 5px;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.4s;
  cursor: ${({ isDisable }) => (isDisable ? 'no-drop' : 'pointer')};
  &:hover {
    ${({ isDisable }) => {
      if (!isDisable) {
        return css`
          background: #ffffff;
          color: #d65251;
        `
      }
    }}
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 375px;
    height: 49px;
    padding: 18px 0 14px;
    font-size: 18px;
    box-shadow:
      0px 10.5053px 26.2634px -7.87901px rgba(219, 84, 78, 0.28),
      0px 14.8826px 11.3808px -14.0071px rgba(219, 84, 78, 0.51);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 322px;
    height: 42px;
    padding: 15px 0 11px;
    font-size: 16px;
    box-shadow:
      0px 9.00051px 22.5013px -6.75038px rgba(219, 84, 78, 0.28),
      0px 12.7507px 9.75055px -12.0007px rgba(219, 84, 78, 0.51);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 277px;
    height: 36px;
    padding: 13px 0 7px;
    font-size: 16px;
    box-shadow:
      0px 7.74891px 19.3723px -5.81168px rgba(219, 84, 78, 0.28),
      0px 10.9776px 8.39465px -10.3319px rgba(219, 84, 78, 0.51);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 205px;
    height: 31px;
    padding: 10px 0 7px;
    font-size: 14px;
    box-shadow:
      0px 5.74812px 14.3703px -4.31109px rgba(219, 84, 78, 0.28),
      0px 8.14317px 6.22713px -7.66416px rgba(219, 84, 78, 0.51);
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
`

const AboutWrap = styled.div`
  margin-top: 124px;
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 110px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 94px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 81px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 59px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 35px;
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-top: 47px;
  }
`

const RHOWrap = styled.div`
  position: relative;
  width: 723px;
  margin: 0 auto;
  margin-bottom: 279px;
  padding-top: 50px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 633px;
    margin-bottom: 271px;
    padding-top: 0;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-top: 30px;
    margin-bottom: 237px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 573px;
    margin-bottom: 240px;
    padding-top: 39px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 164px;
    padding-top: 68px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 326px;
    margin-bottom: 121px;
    padding-top: 8px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 275px;
    margin-bottom: 126px;
    padding-top: 0;
    margin-top: -50px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 240px;
    margin-bottom: 116px;
    margin-top: -56px;
  }
`

const RHOTitle = styled.div`
  position: relative;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 48px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 30px;
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 42px;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 38px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 32px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 28px;
    margin-bottom: 16px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 174px;
    height: 1px;
    background: linear-gradient(
      270deg,
      rgba(236, 72, 79, 0) 0%,
      #ec484f 47.89%,
      rgba(236, 72, 79, 0) 100%
    );
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 148px;
      bottom: -14px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 132px;
      bottom: -12px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      width: 110px;
      bottom: -10px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 93px;
      bottom: -9px;
    }
  }
`

const Stars = styled.div`
  position: absolute;
  width: 2404px;
  height: 397px;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  background: url('${starsBg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1540px;
    height: 255px;
    top: 72px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1459px;
    height: 241px;
    top: -9px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1264px;
    height: 209px;
    top: -23px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 1034px;
    height: 171px;
    top: -12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 519px;
    height: 89px;
    top: -60px;
    background: url('${starsBgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    display: none;
  }
`

const RHOSubTitle = styled.div`
  position: relative;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-bottom: 35px;
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 18px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 16px;
    margin-bottom: 27px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 14px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 13px;
    margin-bottom: 19px;
  }
`

const RHOButton = styled.div`
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  a {
    position: relative;
    display: block;
    width: 296px;
    height: 44px;
    padding: 12px 110px 12px 80px;
    background: #ffffff;
    border-radius: 5px;
    font-family: 'Aldrich';
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #292b33;
    transition: 0.4s;
    cursor: pointer;
    margin: 0 auto;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 253px;
      height: 38px;
      padding: 10px 94px 10px 68px;
      font-size: 13px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 226px;
      height: 34px;
      padding: 9px 84px 9px 61px;
      font-size: 12px;
      border-radius: 4px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      width: 210px;
      height: 34px;
      padding: 10px 69px 10px 50px;
      border-radius: 3px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 185px;
      height: 32px;
      padding: 8px 59px 10px 43px;
      border-radius: 3px;
    }
    svg {
      position: absolute;
      top: 12px;
      right: 80px;
      transition: 0.4s;
      width: 20px;
      height: 20px;
      fill: url('#paint0_linear_4655_18');
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 10px;
        right: 68px;
        width: 17px;
        height: 17px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        top: 9px;
        right: 61px;
        width: 15px;
        height: 15px;
      }
      ${({ theme }) => theme.adaptive.index.smm} {
        top: 10px;
        right: 50px;
        width: 13px;
        height: 13px;
      }
      ${({ theme }) => theme.adaptive.index.xs} {
        top: 10px;
        right: 43px;
        width: 11px;
        height: 11px;
      }
    }
    &:hover {
      background: #d65251;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
`
