import gsap, { Linear, Power4 } from 'gsap'
gsap.config({ force3D: false })

export const FadeIn = (
  elem: gsap.TweenTarget,
  delay: number,
  yVal: number,
  opacity?: number
) => {
  gsap.fromTo(
    elem,
    { y: yVal, opacity: 0 },
    {
      delay: delay,
      y: 0,
      opacity: opacity ? opacity : 1,
      duration: 0.4,
      ease: Power4.easeOut,
    }
  )
}

export const drawCanvas = (elem: gsap.DOMTarget, height: number) => {
  gsap.fromTo(
    elem,
    { height: 0 },
    {
      delay: 0.4,
      height: height,
      duration: 0.7,
      ease: Linear.easeOut,
    }
  )
}
