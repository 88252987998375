import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import bgImg from 'images/index/about/bg.png'
import bgImgMd from 'images/index/about/bg-md.png'
import bgImgSm from 'images/index/about/bg-sm.svg'
import algodaoBg from 'images/index/about/algodao-steps-bg.svg'
import algodaoBgSm from 'images/index/about/algodao-bg-sm.svg'
import algodaoBgSmm from 'images/index/about/algodao-bg-smm.svg'
import algodaoImgMob from 'images/index/about/algodao-mob-img.svg'
import sigmaImgMob from 'images/index/about/sigma-mob-img.svg'
import sigmaMobBg from 'images/index/about/sigma-mob-bg.svg'
import algopadMobBg from 'images/index/about/algopad/algopad-mob-bg.svg'
import algopadMobImg from 'images/index/about/algopad/algopad-rocket-img.svg'
import grayRocket from 'images/index/about/algopad/gray-rocket.svg'
import { ReactComponent as SigmaImgBg } from 'images/index/about/sigma-bg-img.svg'
import { ReactComponent as AlgodaoImgBg } from 'images/index/about/algodao-bg-img.svg'
import { ReactComponent as AlgoPadImgBg } from 'images/index/about/algopad/algopad-bg-img.svg'
import stepsList from 'store/steps'
import redStepImg from 'images/index/about/red-step-bg.svg'
import redStepSmImg from 'images/index/about/algopad/red-step-sm.svg'
import redStepXsImg from 'images/index/about/algopad/red-step-xs.svg'
import blackStepImg from 'images/index/about/black-steps-bg.svg'
import blackStepImgSm from 'images/index/about/black-step-bg-sm.svg'
import blackStepImgXs from 'images/index/about/black-step-bg-xs.svg'
import rocketImg from 'images/index/about/rocket-img.svg'
import firstCircleBg from 'images/index/about/algopad/first-circle.svg'
import secondCircleBg from 'images/index/about/algopad/second-circle.svg'
import thirdCircleBg from 'images/index/about/algopad/third-circle.svg'
import fourthCircleBg from 'images/index/about/algopad/fourth-circle.svg'
import fifthCircleBg from 'images/index/about/algopad/fifth-circle.svg'
import arrowImg from 'images/index/about/arrow.svg'
import btnBorder from 'images/hierarchy/btn-border.svg'
import { Container } from 'theme/theme'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import AlgoDaoAnimData from 'lottie/algadao.json'
import lottie from 'lottie-web'
import Lines from '../../Images/Lines'
import useWindowSize from 'helpers/utils/useWindowSize'
import tiersList from '../../../store/tiers'
import Slider from 'react-slick'

import {
  Draw,
  FadeIn,
  FadeInStep,
  FadeInTier,
  FadeOutTier,
  RotateAlgoPadBg,
  RotateAnim,
  ScaleAnim,
} from './animations'
import DashedLine from '../../Icons/about/Dashed'
import Round from '../../Icons/about/Round'
import { FAQ_LINK, FAQ_SIGMA_LINK } from '../../../configs/app'

const About: React.FunctionComponent = () => {
  const lottieAlgoDaoRef = React.useRef<HTMLDivElement>(null)
  const rocketRef = useRef(null)
  const contRef = useRef(null)
  const linesRef = useRef(null)
  const ellipseRef = useRef([])
  ellipseRef.current = []
  const circlesRef = useRef([])
  circlesRef.current = []
  const stepsRef = useRef([])
  stepsRef.current = []
  const algopadBgRef = useRef(null)
  const { width, height } = useWindowSize()
  const [activeTier, setActiveTier] = useState(1)
  const [tierRotate, setTierRotate] = useState(182)
  const [activeItem, setActiveItem] = useState(0)
  const [odds, setOdds] = useState('')
  const [pool, setPool] = useState('')
  const [tierImg, setTierImg] = useState('')
  const [tierName, setTierName] = useState('')
  const [tierDesc, setTierDesc] = useState('')
  const algoPadRef = useRef(null)
  const [animEnd, setAnimEnd] = useState(true)

  const settings = {
    centerMode: false,
    infinite: false,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    variableWidth: true,
  }

  React.useEffect(() => {
    let animDuration = 2000
    if (lottieAlgoDaoRef.current) {
      animDuration =
        lottieAlgoDaoRef.current.getBoundingClientRect().top + height / 2
    }
    const anim = lottie.loadAnimation({
      container: lottieAlgoDaoRef.current!,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: AlgoDaoAnimData,
    })

    const animateBodyMovin = (duration: number) => {
      const scrollPosition = window.scrollY
      const maxFrames = anim.totalFrames

      const frame = (maxFrames / 100) * (scrollPosition / (duration / 100))

      anim.goToAndStop(frame, true)
    }

    const onScroll = () => {
      animateBodyMovin(animDuration)
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      anim.destroy()
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    if (width > 900) {
      if (rocketRef.current) {
        ScrollTrigger.create({
          trigger: rocketRef.current,
          start: 'bottom bottom',
          onEnter: () => {
            FadeIn(rocketRef.current, 0, 150)
          },
        })
        ScrollTrigger.create({
          trigger: rocketRef.current,
          start: 'top bottom',
          onLeaveBack: () => {
            gsap.to(rocketRef.current, { opacity: 0 })
          },
        })
      }
      if (linesRef.current) {
        let elems = document.querySelectorAll('.line')
        elems.forEach((el) => {
          Draw(el)
        })
      }
      if (stepsRef.current) {
        stepsRef.current.forEach((el, idx) => {
          ScrollTrigger.create({
            trigger: el,
            start: 'top center',
            onEnter: () => {
              FadeInStep(el)
            },
          })
          ScrollTrigger.create({
            trigger: el,
            start: 'top bottom',
            onLeaveBack: () => {
              gsap.to(el, { y: 50, opacity: 0 })
            },
          })
        })
      }
      if (ellipseRef.current) {
        let delay = 0.1
        ellipseRef.current.forEach((el, idx) => {
          ScrollTrigger.create({
            trigger: el,
            start: 'center center',
            onEnter: () => {
              if (idx === 0) {
                ScaleAnim(el, (idx + 1) * delay, 0.3)
              } else {
                ScaleAnim(el, (idx + 1) * delay, 0.1)
              }
            },
          })
          ScrollTrigger.create({
            trigger: el,
            start: 'top bottom',
            onLeaveBack: () => {
              gsap.to(el, { width: 0, height: 0, opacity: 0 })
            },
          })
        })
      }
    }
  }, [])

  useEffect(() => {
    if (circlesRef.current) {
      let delay = 0
      circlesRef.current.forEach((el, idx) => {
        delay += 0.05
        if (idx === 1 || idx === 4) {
          delay -= 0.1
          RotateAnim(el, delay, tierRotate, true)
        } else {
          RotateAnim(el, delay, tierRotate, false)
        }
      })
    }
    if (algopadBgRef.current) {
      RotateAlgoPadBg(algopadBgRef.current, tierRotate)
    }
  }, [tierRotate])

  const addToEllipseRefs = (el: any) => {
    // @ts-ignore
    if (el && !ellipseRef.current.includes(el)) {
      // @ts-ignore
      ellipseRef.current.push(el)
    }
  }

  const addToCirclesRefs = (el: any) => {
    // @ts-ignore
    if (el && !circlesRef.current.includes(el)) {
      // @ts-ignore
      circlesRef.current.push(el)
    }
  }

  const addToStepsRefs = (el: any) => {
    // @ts-ignore
    if (el && !stepsRef.current.includes(el)) {
      // @ts-ignore
      stepsRef.current.push(el)
    }
  }

  const onTierClick = (id: number, rotate: number) => {
    if (width > 900) {
      setTierRotate(rotate)
    }
    setActiveTier(id)
    setAnimEnd(false)
  }

  useEffect(() => {
    if (algoPadRef.current) {
      FadeOutTier(algoPadRef.current, () => {
        if (activeTier !== 1) {
          setOdds(tiersList[activeTier - 1].odds)
          setPool(tiersList[activeTier - 1].pool)
          setTierDesc(tiersList[activeTier - 1].desc)
          setTierImg(tiersList[activeTier - 1].img)
          setTierName(tiersList[activeTier - 1].name)
        } else {
          setOdds('')
          setPool('')
          setTierDesc('')
          setTierImg('')
          setTierName('')
        }
        setAnimEnd(true)
        FadeInTier(algoPadRef.current)
      })
    }
  }, [activeTier])

  const AlgoPadStepsDiv = (
    <AlgoPadSteps>
      {stepsList.algopad.map((item, idx) => {
        return (
          <AlgoPadStep key={`${idx}AlgoPadStep`}>
            <StepNumber>{item.number}</StepNumber>
            <StepText>{item.text}</StepText>
          </AlgoPadStep>
        )
      })}
    </AlgoPadSteps>
  )

  return (
    <Root>
      <MobileBg />
      <LinesWrap ref={linesRef}>
        <Lines />
      </LinesWrap>
      <Container ref={contRef}>
        <Algodao>
          {width <= 900 && <AlgodaoMobBg />}
          <AlgodaoBg>
            <AlgodaoImgBg />
          </AlgodaoBg>
          <AlgodaoAnimBg ref={lottieAlgoDaoRef} />
          <Rocket ref={rocketRef} />

          {width <= 900 && <AlgodaoMobImg />}
          <Title>
            Algo<span>DAO</span>
          </Title>
          <SubTitle>tokenized incubator and system’s governance layer</SubTitle>
          <Steps>
            {stepsList.algadao.map((item, idx) => {
              return (
                <AlgadaoStep ref={addToStepsRefs} key={`${idx}AlgadaoStep`}>
                  <StepNumber>{item.number}</StepNumber>
                  <StepText>{item.text}</StepText>
                </AlgadaoStep>
              )
            })}
          </Steps>
          <LearnMoreRow
            onMouseEnter={() => setActiveItem(1)}
            onMouseLeave={() => setActiveItem(0)}
          >
            <LearnMoreLine>
              <DashedLine active={activeItem === 1} />
            </LearnMoreLine>
            <LearnMore href={FAQ_LINK} target={'_blank'}>
              Learn more
              <RoundWrap>
                <Round active={activeItem === 1} />
              </RoundWrap>
            </LearnMore>
          </LearnMoreRow>
        </Algodao>
        <Sigma>
          {width <= 900 && <SigmaMobBg />}
          {width <= 900 && <SigmaMobImg />}

          <SigmaBgWrap>
            <SigmaImgBg />
          </SigmaBgWrap>
          <Title>Sigma</Title>
          <SubTitle>
            tokenized liquidity and Algorand’s innovation index
          </SubTitle>
          <SigmaSteps>
            {stepsList.sigma.map((item, idx) => {
              return (
                <SigmaStep ref={addToStepsRefs} key={`${idx}SigmaStep`}>
                  <SigmaStepNumber>{item.number}</SigmaStepNumber>
                  <StepText>{item.text}</StepText>
                </SigmaStep>
              )
            })}
          </SigmaSteps>
          <LearnMoreRow
            onMouseEnter={() => setActiveItem(2)}
            onMouseLeave={() => setActiveItem(0)}
          >
            <LearnMoreLine>
              <DashedLine active={activeItem === 2} />
            </LearnMoreLine>
            <LearnMore href={FAQ_SIGMA_LINK} target={'_blank'}>
              Learn more
              <RoundWrap>
                <Round active={activeItem === 2} />
              </RoundWrap>
            </LearnMore>
          </LearnMoreRow>
          <Ellipses>
            <EllipseWrap>
              <Ellipse ref={addToEllipseRefs} />
            </EllipseWrap>
            <EllipseWrap>
              <Ellipse ref={addToEllipseRefs} />
            </EllipseWrap>
            <EllipseWrap>
              <Ellipse ref={addToEllipseRefs} />
            </EllipseWrap>
            <EllipseWrap>
              <Ellipse ref={addToEllipseRefs} />
            </EllipseWrap>
          </Ellipses>
        </Sigma>
        <AlgoPad id={'tiers'}>
          {width <= 900 && <AlgoPadMobBg />}
          {activeTier === 1 && width <= 900 && <AlgoPadMobImg />}

          <AlgoPadBgWrap ref={algopadBgRef}>
            <AlgoPadImgBg />
          </AlgoPadBgWrap>
          <Circles>
            <Circle ref={addToCirclesRefs} />
            <Circle ref={addToCirclesRefs} />
            <Circle ref={addToCirclesRefs} />
            <Circle ref={addToCirclesRefs} />
            <Circle ref={addToCirclesRefs} />
          </Circles>
          <AlgoPadCont ref={algoPadRef}>
            <AlgoPadInfo active={activeTier !== 1 && animEnd}>
              <TierAboutImg src={tierImg} />
              <div>
                <TierAboutTitle>{tierName}</TierAboutTitle>
                <TierAboutSubTitle>{tierDesc}</TierAboutSubTitle>
                <TierAboutBlock>
                  <TierAboutRow>
                    <TierAboutText>
                      Participation Odds Multiple -&nbsp;
                    </TierAboutText>
                    <TierAboutVal>{odds}</TierAboutVal>
                  </TierAboutRow>
                  <TierAboutRow>
                    <TierAboutText>
                      Pool Weight Multiplier -&nbsp;
                    </TierAboutText>
                    <TierAboutVal>{pool}</TierAboutVal>
                  </TierAboutRow>
                  {/* <StakeBtn>
                    STAKE <span>ADAO</span>
                  </StakeBtn> */}
                </TierAboutBlock>
                {width <= 900 && AlgoPadStepsDiv}
                {width <= 900 && (
                  <LearnMoreRow
                    onMouseEnter={() => setActiveItem(3)}
                    onMouseLeave={() => setActiveItem(0)}
                  >
                    <LearnMoreLine>
                      <DashedLine active={activeItem === 3} />
                    </LearnMoreLine>
                    <LearnMore href={FAQ_LINK} target={'_blank'}>
                      Learn more
                      <RoundWrap>
                        <Round active={activeItem === 3} />
                      </RoundWrap>
                    </LearnMore>
                  </LearnMoreRow>
                )}
              </div>
            </AlgoPadInfo>
            <AlgoPadInfo active={activeTier === 1 && animEnd}>
              <Title>
                Algo<span>PAD</span>
              </Title>
              <SubTitle>
                the boutique launchpad for Algorand’s ecosystem
              </SubTitle>
              {AlgoPadStepsDiv}
              <LearnMoreRow
                onMouseEnter={() => setActiveItem(3)}
                onMouseLeave={() => setActiveItem(0)}
              >
                <LearnMoreLine>
                  <DashedLine active={activeItem === 3} />
                </LearnMoreLine>
                <LearnMore href={FAQ_LINK} target={'_blank'}>
                  Learn more
                  <RoundWrap>
                    <Round active={activeItem === 3} />
                  </RoundWrap>
                </LearnMore>
              </LearnMoreRow>
            </AlgoPadInfo>
          </AlgoPadCont>
          <Tiers>
            {tiersList.map((item, idx) => {
              return (
                <Tier
                  key={`${idx}Tier`}
                  active={activeTier === idx + 1}
                  onClick={() => onTierClick(idx + 1, item.rotate)}
                >
                  <TierTitle>{item.name}</TierTitle>
                  <TierSubTitle>{item.desc}</TierSubTitle>
                  <TierIcon>{item.getIcon()}</TierIcon>
                </Tier>
              )
            })}
          </Tiers>
        </AlgoPad>
        {width <= 900 && (
          <AlgoPadSliderWrap>
            <AlgoPadSlider>
              <Slider {...settings} className="tier-slider">
                {tiersList.map((item, idx) => {
                  return (
                    <AlgoPadSlide
                      active={activeTier === idx + 1}
                      onClick={() => onTierClick(idx + 1, item.rotate)}
                      key={`${idx}AlgoPadSlide`}
                    >
                      <TierTitle>{item.name}</TierTitle>
                      <TierSubTitle>{item.desc}</TierSubTitle>
                    </AlgoPadSlide>
                  )
                })}
              </Slider>
            </AlgoPadSlider>
          </AlgoPadSliderWrap>
        )}
      </Container>
    </Root>
  )
}

export default About

interface tierProps {
  active: boolean
}

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 591px 0 120px 0;

  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 517px 0 105px 0;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 443px 0 100px 0;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 382px 0 90px 0;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    padding: 307px 0 80px 0;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 221px 0 50px 0;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 288px 0 50px 0;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 246px 0 40px 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4046px;
    height: 4588px;
    background: url('${bgImg}') center no-repeat;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 3554px;
      height: 4027px;
      background-size: cover;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 3047px;
      height: 3453px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 2623px;
      height: 2975px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 1938px;
      height: 2946px;
      background: url('${bgImgMd}') center no-repeat;
      background-size: cover;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      width: 1946px;
      height: 448px;
      background: url('${bgImgSm}') center no-repeat;
      background-size: cover;
    }

    ${({ theme }) => theme.adaptive.index.smm} {
      width: 1764px;
      height: 402px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      width: 1504px;
      height: 343px;
    }
  }
`

const MobileBg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    top: 448px;
    width: 100%;
    height: 100%;
    background: #fafafa;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    top: 402px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 343px;
  }
`

const LinesWrap = styled.div`
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 1920px;
  height: 703px;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    top: -53px;
    width: 1679px;
    height: 613px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    top: -45px;
    width: 1439px;
    height: 526px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    top: -39px;
    width: 1239px;
    height: 453px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    top: -29px;
    width: 920px;
    height: 336px;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    top: -32px;
    width: 822px;
    height: 314px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: -27px;
    width: 701px;
    height: 268px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`

const LearnMoreRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 478px;
  margin: 0 auto;
  margin-top: 47px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 418px;
    margin-top: 41px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 359px;
    margin-top: 35px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 309px;
    margin-top: 30px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 285px;
    margin-top: 28px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    top: 577px;
    left: calc(50% - 19px);
    transform: translate(-50%);
    width: fit-content;
    margin-top: 0;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 536px;
  }
`

const LearnMoreLine = styled.div`
  width: 291px;
  height: 17px;
  z-index: 2;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 255px;
    height: 15px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 218px;
    height: 13px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 188px;
    height: 11px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 173px;
    height: 10px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 199px;
    height: 12px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 170px;
    height: 10px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;
  }
`

const LearnMore = styled.a`
  position: relative;
  display: block;
  font-family: 'MMC';
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #d7544f;
  cursor: pointer;
  margin-left: 27px;
  outline: none;
  z-index: 2;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    margin-left: 24px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    margin-left: 20px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 11px;
    margin-left: 13px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-left: 12px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    margin-left: 8px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 12px;
    margin-left: 7px;
  }

  &:after {
    content: '';
    position: absolute;
    right: -24px;
    top: 50%;
    transform: translateY(-50%);
    width: 19px;
    height: 8px;
    background: url('${arrowImg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 16px;
      height: 7px;
      right: -21px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 14px;
      height: 6px;
      right: -19px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 12px;
      height: 5px;
      right: -17px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 11px;
      height: 5px;
      right: -15px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      width: 12px;
      height: 5px;
      right: -19px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      width: 11px;
      height: 5px;
      right: -17px;
    }
  }
`

const RoundWrap = styled.div`
  position: absolute;
  right: -34px;
  top: 50%;
  transform: translateY(-50%);
  width: 57px;
  height: 57px;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 50px;
    height: 50px;
    right: -30px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 43px;
    height: 43px;
    right: -26px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 37px;
    height: 37px;
    right: -22px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 34px;
    height: 34px;
    right: -21px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 39px;
    height: 39px;
    right: -28px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 33px;
    height: 33px;
    right: -23px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.4s;
  }
`

const Algodao = styled.div`
  position: relative;
  width: 712px;
  height: 712px;
  margin: 0 auto;
  padding-top: 100px;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 623px;
    height: 623px;
    padding-top: 88px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 534px;
    height: 534px;
    padding-top: 75px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 460px;
    height: 460px;
    padding-top: 65px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 424px;
    height: 424px;
    padding-top: 60px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 303px;
    height: 303px;
    padding-top: 135px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 258px;
    height: 258px;
    padding-top: 115px;
  }
`

const AlgodaoBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 38px rgba(30, 14, 12, 0.12));

    ${({ theme }) => theme.adaptive.index.xl} {
      filter: drop-shadow(0px 0px 34px rgba(30, 14, 12, 0.12));
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      filter: drop-shadow(0px 0px 29.0774px rgba(30, 14, 12, 0.12));
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      filter: drop-shadow(0px 0px 25.0388px rgba(30, 14, 12, 0.12));
    }

    ${({ theme }) => theme.adaptive.index.md} {
      filter: drop-shadow(0px 0px 23.1089px rgba(30, 14, 12, 0.12));
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      filter: drop-shadow(0px 0px 16.4854px rgba(30, 14, 12, 0.12));
    }
  }
`

const AlgodaoAnimBg = styled.div`
  position: absolute;
  top: -278px;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  height: 1200px;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    top: -250px;
    width: 1050px;
    height: 1050px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    top: -215px;
    width: 900px;
    height: 900px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    top: -180px;
    width: 770px;
    height: 770px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    top: -167px;
    width: 710px;
    height: 710px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const AlgodaoMobBg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    display: block;
    width: 355px;
    height: 400px;
    top: -71px;
    left: 50%;
    transform: translate(-50%);
    background: url('${algodaoBgSm}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    position: absolute;
    display: block;
    width: 355px;
    height: 418px;
    top: -89px;
    left: 50%;
    transform: translate(-50%);
    background: url('${algodaoBgSmm}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 303px;
    height: 357px;
    top: -76px;
  }
`

const AlgodaoMobImg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    display: block;
    width: 125px;
    height: 135px;
    left: 50%;
    top: 25px;
    transform: translateX(-50%);
    background: url('${algodaoImgMob}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 21px;
    width: 107px;
    height: 115px;
  }
`

const Rocket = styled.div`
  position: absolute;
  top: -410px;
  left: 50%;
  transform: translateX(-50%);
  width: 108px;
  height: 135px;
  background: url('${rocketImg}') center no-repeat;
  background-size: cover;
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: -359px;
    width: 95px;
    height: 118px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    top: -308px;
    width: 81px;
    height: 101px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    top: -265px;
    width: 70px;
    height: 87px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    top: -221px;
    width: 52px;
    height: 65px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    top: -135px;
    opacity: 1;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    top: -186px;
    width: 80px;
    height: 99px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: -159px;
    width: 68px;
    height: 85px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 68px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #292b33;
  margin-bottom: 8px;

  v ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
    margin-bottom: 7px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 51px;
    margin-bottom: 6px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 44px;
    margin-bottom: 5px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 41px;
    margin-bottom: 5px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 42px;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 36px;
    margin-bottom: 7px;
  }

  span {
    color: #dc4c4c;
  }
`

const SubTitle = styled.div`
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 138%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.3;
  margin-bottom: 40px;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    margin-bottom: 35px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 11px;
    margin-bottom: 25px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 10px;
    margin-bottom: 23px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    margin: 0 auto;
    width: 213px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 12px;
    width: 182px;
  }
`

const Steps = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 478px;
  height: 278px;
  background: url('${algodaoBg}') center no-repeat;
  background-size: cover;
  margin: 0 auto;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 418px;
    height: 243px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 359px;
    height: 209px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 309px;
    height: 180px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 285px;
    height: 166px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    top: 358px;
    left: 50%;
    transform: translateX(-50%);
    width: 330px;
    height: 190px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 304px;
    width: 280px;
    height: 203px;
  }
`

const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 12px;
  background: #f6f6f6;
  padding-top: 11px;
  padding-left: 4px;

  font-family: 'Aldrich';
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #d7544f;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 37px;
    height: 37px;
    margin-right: 11px;

    font-size: 19px;
    line-height: 19px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 32px;
    height: 32px;
    margin-right: 9px;

    font-size: 16px;
    line-height: 16px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 27px;
    height: 27px;
    margin-right: 8px;

    font-size: 14px;
    line-height: 14px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 25px;
    height: 25px;
    margin-right: 8px;

    font-size: 12px;
    line-height: 12px;
    padding-top: 8px;
    padding-left: 3px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 29px;
    height: 29px;
    margin-right: 8px;

    font-size: 15px;
    line-height: 15px;
    padding-top: 9px;
    padding-left: 3px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 25px;
    height: 25px;
    margin-right: 7px;

    font-size: 13px;
    line-height: 13px;
    padding-top: 8px;
    padding-left: 3px;
  }
`

const StepText = styled.div`
  font-family: 'MMC';
  font-size: 20px;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: #292b33;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
  }
`

const AlgadaoStep = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  transform: translate(0px, 50px);
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 11px;
    opacity: 1;
    transform: unset;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 11px;
  }

  &:first-child ${StepNumber} {
    border-radius: 20px 0 0 0;

    ${({ theme }) => theme.adaptive.index.xl} {
      border-radius: 18px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      border-radius: 15px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 13px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      border-radius: 12px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 14px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      border-radius: 12px 0 0 0;
    }
  }

  &:nth-child(4) ${StepNumber} {
    background: #dc4c4c;
    color: #f6f6f6;
  }

  &:nth-child(4) ${StepText} {
    width: 262px;
    background: url('${redStepImg}') center no-repeat;
    background-size: cover;
    padding-left: 10px;
    color: #f6f6f6;
    font-weight: 500;
    line-height: 33px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 229px;
      padding-left: 9px;
      line-height: 29px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 197px;
      padding-left: 8px;
      line-height: 25px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 169px;
      padding-left: 6px;
      line-height: 21px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 156px;
      padding-left: 6px;
      line-height: 20px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      width: 179px;
      padding-left: 7px;
      line-height: 23px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      width: 183px;
      padding-left: 6px;
      line-height: 19px;
    }
  }

  &:last-child ${StepNumber} {
    border-radius: 0 0 0 20px;

    ${({ theme }) => theme.adaptive.index.xl} {
      border-radius: 0 0 0 18px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      border-radius: 0 0 0 15px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 0 0 0 13px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      border-radius: 0 0 0 12px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 0 0 0 14px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      border-radius: 0 0 0 12px;
    }
  }
`

const Sigma = styled.div`
  position: relative;
  width: 712px;
  height: 712px;
  margin: 292px 0 0 172px;
  padding-top: 100px;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 623px;
    height: 623px;
    margin: 255px 0 0 149px;
    padding-top: 87px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 534px;
    height: 534px;
    margin: 219px 0 0 119px;
    padding-top: 75px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 460px;
    height: 460px;
    margin: 188px 0 0 102px;
    padding-top: 64px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 424px;
    height: 424px;
    margin: 178px 0 0 4px;
    padding-top: 59px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 302px;
    height: 302px;
    margin: 431px auto 0;
    padding-top: 133px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 256px;
    height: 256px;
    margin: 406px auto 0;
    padding-top: 113px;
  }

  ${LearnMoreRow} {
    margin-top: 45px;

    ${({ theme }) => theme.adaptive.index.xl} {
      margin-top: 39px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      margin-top: 34px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      margin-top: 29px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      margin-top: 27px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      margin-top: 0;
      top: 632px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      top: 580px;
    }
  }
`

const SigmaMobImg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    display: block;
    width: 88px;
    height: 88px;
    left: 50%;
    top: 32px;
    transform: translateX(-50%);
    background: url('${sigmaImgMob}') center no-repeat;
    background-size: cover;
    z-index: 2;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 71px;
    height: 71px;
    top: 29px;
  }
`

const SigmaBgWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(12px 39px 85px rgba(255, 255, 255, 0.36))
      drop-shadow(4px 4px 16px rgba(177, 177, 177, 0.5));

    ${({ theme }) => theme.adaptive.index.xl} {
      filter: drop-shadow(10.5px 34.125px 74.375px rgba(255, 255, 255, 0.36))
        drop-shadow(3.5px 3.5px 14px rgba(177, 177, 177, 0.5));
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      filter: drop-shadow(9px 29.25px 63.75px rgba(255, 255, 255, 0.36))
        drop-shadow(3px 3px 12px rgba(177, 177, 177, 0.5));
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      filter: drop-shadow(7.75px 25.1875px 54.8958px rgba(255, 255, 255, 0.36))
        drop-shadow(2.58333px 2.58333px 10.3333px rgba(177, 177, 177, 0.5));
    }

    ${({ theme }) => theme.adaptive.index.md} {
      filter: drop-shadow(
          7.15266px 23.2461px 50.6647px rgba(255, 255, 255, 0.36)
        )
        drop-shadow(2.38422px 2.38422px 9.53688px rgba(177, 177, 177, 0.5));
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      filter: drop-shadow(
          5.09011px 16.5429px 36.055px rgba(255, 255, 255, 0.36)
        )
        drop-shadow(1.69671px 1.69671px 6.78682px rgba(177, 177, 177, 0.5));
    }
  }
`

const Ellipses = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const SigmaMobBg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 356px;
    height: 356px;
    background: url('${sigmaMobBg}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 304px;
    height: 304px;
  }
`

const Ellipse = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid #e1e0e1;
  opacity: 0.1;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const EllipseWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }

  &:nth-child(1) {
    width: 763px;
    height: 763px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 668px;
      height: 668px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 572px;
      height: 572px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 493px;
      height: 493px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 455px;
      height: 455px;
    }

    ${Ellipse} {
      border: 3px solid #e1e0e1;
      opacity: 0.3;

      ${({ theme }) => theme.adaptive.index.lg} {
        border: 2px solid #e1e0e1;
      }
    }
  }

  &:nth-child(2) {
    width: 809px;
    height: 809px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 709px;
      height: 709px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 607px;
      height: 607px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 522px;
      height: 522px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 482px;
      height: 482px;
    }
  }

  &:nth-child(3) {
    width: 857px;
    height: 857px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 750px;
      height: 750px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 643px;
      height: 643px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 553px;
      height: 553px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 511px;
      height: 511px;
    }
  }

  &:nth-child(4) {
    width: 905px;
    height: 905px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 792px;
      height: 792px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 679px;
      height: 679px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 584px;
      height: 584px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 539px;
      height: 539px;
    }
  }
`

const SigmaSteps = styled(Steps)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  width: 571px;
  background: unset;
  height: auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 499px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 428px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 368px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 340px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    top: 372px;
    width: 330px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 313px;
    width: 280px;
  }
`

const SigmaStepNumber = styled(StepNumber)`
  color: #292b33;
`

const SigmaStep = styled.div`
  display: flex;
  margin-bottom: 34px;
  opacity: 0;
  transform: translate(0px, 50px);
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 16px;
    opacity: 1;
    transform: unset;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 11px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 10px;
  }

  ${StepText} {
    max-width: 440px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 382px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 327px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 282px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 260px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 278px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 237px;
    }
  }

  &:nth-child(1) ${SigmaStepNumber} {
    color: #ffffff;
    background: #292b33;
  }

  &:nth-child(1) ${StepText} {
    width: 235px;
    height: 81px;
    background: url('${blackStepImg}') center no-repeat;
    background-size: cover;
    color: #ffffff;
    padding-left: 3px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 206px;
      height: 69px;
      padding-left: 3px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 176px;
      height: 59px;
      padding-left: 2px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 152px;
      height: 51px;
      padding-left: 2px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 140px;
      height: 47px;
      padding-left: 2px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      background: url('${blackStepImgSm}') center no-repeat;
      background-size: cover;
      width: 281px;
      height: 36px;
      padding-left: 2px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      background: url('${blackStepImgXs}') center no-repeat;
      background-size: cover;
      width: 251px;
      height: 35px;
      padding-left: 1px;
    }
  }

  &:nth-child(2) ${StepText} {
    max-width: 195px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 171px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 146px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 126px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 116px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 278px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 237px;
    }
  }

  &:nth-child(4) ${StepText} {
    font-weight: 600;
  }
`

const AlgoPad = styled.div`
  position: relative;
  width: 712px;
  height: 712px;
  margin: 292px 0 0 684px;
  padding-top: 100px;

  ${({ theme }) => theme.adaptive.index.xl} {
    margin: 240px 0 0 590px;
    width: 623px;
    height: 623px;
    padding-top: 87px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    margin: 210px 0 0 495px;
    width: 534px;
    height: 534px;
    padding-top: 75px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    margin: 180px 0 0 425px;
    width: 460px;
    height: 460px;
    padding-top: 64px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    margin: 235px 0 0 190px;
    width: 424px;
    height: 424px;
    padding-top: 59px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    margin: 486px auto 70px;
    width: 302px;
    height: 302px;
    padding-top: 134px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 447px auto 54px;
    width: 258px;
    height: 258px;
    padding-top: 114px;
  }

  ${LearnMoreRow} {
    margin-top: 52px;

    ${({ theme }) => theme.adaptive.index.xl} {
      margin-top: 39px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      margin-top: 34px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      margin-top: 29px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      margin-top: 27px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      top: 710px;
      margin-top: 0;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      top: 618px;
    }
  }
`

const AlgoPadCont = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.adaptive.index.sm} {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const AlgoPadBgWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 999px;
  height: 999px;
  will-change: transform;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 874px;
    height: 874px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 749px;
    height: 749px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 645px;
    height: 645px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 596px;
    height: 596px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    width: 441px;
    height: 441px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 373px;
    height: 373px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const Circle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;

  &:nth-child(1) {
    width: 828px;
    height: 828px;
    background: url('${firstCircleBg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 724px;
      height: 724px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 621px;
      height: 621px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 535px;
      height: 535px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 493px;
      height: 493px;
    }
  }

  &:nth-child(2) {
    width: 868px;
    height: 868px;
    background: url('${secondCircleBg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 759px;
      height: 759px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 652px;
      height: 651px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 560px;
      height: 560px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 517px;
      height: 517px;
    }
  }

  &:nth-child(3) {
    width: 899px;
    height: 899px;
    background: url('${thirdCircleBg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 787px;
      height: 787px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 674px;
      height: 674px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 581px;
      height: 581px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 536px;
      height: 536px;
    }
  }

  &:nth-child(4) {
    width: 953px;
    height: 953px;
    background: url('${fourthCircleBg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 834px;
      height: 834px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 714px;
      height: 714px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 615px;
      height: 615px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 568px;
      height: 568px;
    }
  }

  &:nth-child(5) {
    width: 1028px;
    height: 1028px;
    background: url('${fifthCircleBg}') center no-repeat;
    background-size: cover;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 900px;
      height: 900px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 771px;
      height: 771px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 664px;
      height: 664px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 613px;
      height: 613px;
    }
  }
`

const Circles = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1028px;
  height: 1028px;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 900px;
    height: 900px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 771px;
    height: 771px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 664px;
    height: 664px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 613px;
    height: 613px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const AlgoPadMobBg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 356px;
    height: 356px;
    transform: translate(-50%, -50%);
    background: url('${algopadMobBg}') center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    width: 304px;
    height: 304px;
  }
`

const AlgoPadMobImg = styled.div`
  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
    position: absolute;
    top: 29px;
    left: 50%;
    width: 81px;
    height: 93px;
    transform: translateX(-50%);
    background: url('${algopadMobImg}') center no-repeat;
    background-size: cover;
    z-index: 2;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 25px;
    width: 69px;
    height: 79px;
  }
`

const AlgoPadSteps = styled(SigmaSteps)`
  width: 546px;
  height: auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 478px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 410px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 353px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 325px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
    top: 446px;
    width: 330px;
    height: 306px;
    background: #efefef;
    border-radius: 8px;
    padding: 21px 14px 23px 23px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    top: 380px;
    width: 280px;
    height: 277px;
    background: #efefef;
    border-radius: 7px;
    padding: 18px 12px 23px 19px;
  }
`

const AlgoPadStep = styled.div`
  display: flex;
  margin-bottom: 40px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 11px;
  }

  ${StepText} {
    max-width: 440px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 431px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 369px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 318px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 293px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 247px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 210px;
    }
  }

  &:first-child {
    align-items: center;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(1) ${StepNumber} {
    color: #ffffff;
    background: #dc4c4c;
    border-radius: 20px 0 0 0;

    ${({ theme }) => theme.adaptive.index.xl} {
      border-radius: 18px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      border-radius: 15px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 13px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      border-radius: 12px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 14px 0 0 0;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      border-radius: 12px 0 0 0;
    }
  }

  &:nth-child(1) ${StepText} {
    width: 492px;
    height: 33px;
    background: url('${redStepImg}') center no-repeat;
    background-size: cover;
    color: #ffffff;
    padding-left: 7px;

    ${({ theme }) => theme.adaptive.index.xl} {
      width: 431px;
      height: 29px;
      padding-left: 6px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      width: 369px;
      height: 25px;
      padding-left: 5px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      width: 318px;
      height: 21px;
      padding-left: 5px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      width: 293px;
      height: 20px;
      padding-left: 4px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      width: 255px;
      height: 35px;
      padding-left: 5px;
      background: url('${redStepSmImg}') center no-repeat;
      background-size: cover;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      width: 217px;
      height: 36px;
      padding-left: 4px;
      background: url('${redStepXsImg}') center no-repeat;
      background-size: cover;
    }
  }

  &:nth-child(2) ${StepText} {
    max-width: 188px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 165px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 141px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 121px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 112px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 247px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 210px;
    }
  }

  &:nth-child(3) ${StepText} {
    max-width: 196px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 172px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 147px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 127px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 117px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 247px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 210px;
    }
  }

  &:nth-child(4) ${StepText} {
    max-width: 300px;

    ${({ theme }) => theme.adaptive.index.xl} {
      max-width: 246px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 215px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 185px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 171px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 247px;
      background: unset;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 210px;
    }
  }

  &:nth-child(4) ${StepNumber} {
    border-radius: 0 0 0 20px;

    ${({ theme }) => theme.adaptive.index.xl} {
      border-radius: 0 0 0 18px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      border-radius: 0 0 0 15px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      border-radius: 0 0 0 13px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      border-radius: 0 0 0 12px;
    }

    ${({ theme }) => theme.adaptive.index.sm} {
      border-radius: 0 0 0 14px;
    }

    ${({ theme }) => theme.adaptive.index.xs} {
      border-radius: 0 0 0 12px;
    }
  }
`

const Tiers = styled.div`
  position: absolute;
  width: 1028px;
  height: 1028px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 900px;
    height: 900px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    width: 771px;
    height: 771px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    width: 664px;
    height: 664px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    width: 613px;
    height: 613px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    display: none;
  }
`

const TierTitle = styled.div`
  font-family: 'MMC';
  font-size: 26px;
  line-height: 132%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.35;
  margin-bottom: 5px;
  transition: 0.4s;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 23px;
    margin-bottom: 4px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 20px;
    margin-bottom: 3px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 17px;
    margin-bottom: 3px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 16px;
    margin-bottom: 2px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 1px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const TierSubTitle = styled.div`
  font-family: 'MMC';
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.2;
  transition: 0.4s;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
  }

  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 13px;
  }

  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 12px;
  }

  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 11px;
  }

  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
  }

  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 11px;
  }
`

const TierIcon = styled.div`
  svg {
    width: 100%;
    height: 100%;
  }
`

const Tier = styled.div<tierProps>`
  position: absolute;
  text-align: left;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    background: linear-gradient(180deg, #dc5a4c 0%, #cf4858 100%);
    transition: 0.4s;
    transition-delay: 1s;
    opacity: ${({ active }) => (active ? '1' : '0.5')};
  }

  &:nth-child(1) {
    top: 510px;
    left: -174px;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 445px;
      left: -164px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 382px;
      left: -126px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 325px;
      left: -105px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 305px;
      left: -90px;
    }

    ${TierIcon} {
      position: absolute;
      right: -39px;
      top: 0;
      width: 29px;
      height: 36px;
      background: url('${grayRocket}') center no-repeat;
      background-size: cover;
      ${({ theme }) => theme.adaptive.index.xl} {
        right: -34px;
        top: 0;
        width: 25px;
        height: 32px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        right: -29px;
        top: 0;
        width: 22px;
        height: 27px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        right: -25px;
        top: 0;
        width: 19px;
        height: 23px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        right: -23px;
        top: 0;
        width: 17px;
        height: 21px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -13px;
      right: -65px;
      width: 54px;
      height: 2px;
      background: linear-gradient(180deg, #dc5a4c 0%, #cf4858 100%);
      transition: 0.4s;
      transition-delay: 1s;
      opacity: ${({ active }) => (active ? '1' : '0.5')};
      transform: rotate(-1.07deg);

      ${({ theme }) => theme.adaptive.index.xl} {
        top: -7px;
        right: -69px;
        width: 47px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        top: -6px;
        right: -43px;
        width: 40px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        top: -6px;
        right: -35px;
        width: 34px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        top: -5px;
        width: 32px;
        right: -24px;
      }
    }
  }

  &:nth-child(2) {
    top: 340px;
    left: -112px;
    text-align: right;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 298px;
      left: -98px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 250px;
      left: -84px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 220px;
      left: -72px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 202px;
      left: -67px;
    }

    ${TierIcon} {
      position: absolute;
      top: 5px;
      right: -31px;
      width: 25px;
      height: 21px;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 4px;
        right: -27px;
        width: 22px;
        height: 19px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        right: -24px;
        width: 19px;
        height: 16px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        right: -20px;
        width: 16px;
        height: 14px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        right: -19px;
        width: 15px;
        height: 13px;
      }
      .changeStroke {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#f1f1f1')};
      }
      .changeFill {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#282A32' : '#C7C7C7')};
      }
    }

    &:before {
      content: '';
      top: -6px;
      right: -78px;
      width: 67px;
      transform: rotate(19.16deg);

      ${({ theme }) => theme.adaptive.index.xl} {
        width: 58px;
        top: 0px;
        right: -67px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        width: 50px;
        top: -3px;
        right: -59px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        width: 43px;
        right: -51px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        width: 37px;
        right: -47px;
      }
    }

    &:hover {
      ${TierIcon} {
        .changeStroke {
          stroke: #dc4c4c;
        }
        .changeFill {
          fill: #282a32;
        }
      }
    }
  }

  &:nth-child(3) {
    top: 159px;
    left: -63px;
    text-align: right;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 140px;
      left: -55px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 119px;
      left: -47px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 103px;
      left: -41px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 95px;
      left: -38px;
    }

    ${TierIcon} {
      content: '';
      position: absolute;
      top: 5px;
      right: -36px;
      width: 21px;
      height: 21px;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 4px;
        right: -31px;
        width: 18px;
        height: 18px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        right: -27px;
        width: 16px;
        height: 16px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        right: -22px;
        width: 14px;
        height: 14px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        right: -20px;
        width: 13px;
        height: 13px;
      }
      .changeStroke {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#CFCFCF')};
      }
      .changeFill {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#DC4C4C' : '#CFCFCF')};
      }
      .changeBg {
        transition: 0.4s;
        opacity: ${({ active }) => (active ? '1' : '0.5')};
        fill: ${({ active }) => (active ? '#282A32' : '#DBDBDB')};
      }
    }

    &:hover {
      ${TierIcon} {
        .changeStroke {
          transition: 0.4s;
          stroke: #dc4c4c;
        }
        .changeFill {
          transition: 0.4s;
          fill: #dc4c4c;
        }
        .changeBg {
          transition: 0.4s;
          opacity: 1;
          fill: #282a32;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 20px;
      right: -90px;
      width: 43px;
      transform: rotate(37.88deg);

      ${({ theme }) => theme.adaptive.index.xl} {
        width: 38px;
        right: -77px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        top: 18px;
        right: -61px;
        width: 32px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        top: 14px;
        right: -48px;
        width: 28px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        right: -45px;
        width: 26px;
      }
    }
  }

  &:nth-child(4) {
    position: absolute;
    top: 25px;
    left: 865px;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 22px;
      left: 760px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 18px;
      left: 648px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 16px;
      left: 559px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 15px;
      left: 515px;
    }

    ${TierIcon} {
      content: '';
      position: absolute;
      top: 5px;
      left: -32px;
      width: 22px;
      height: 22px;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 4px;
        left: -28px;
        width: 19px;
        height: 19px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        left: -23px;
        width: 16px;
        height: 16px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        left: -20px;
        width: 14px;
        height: 14px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        left: -19px;
        width: 13px;
        height: 13px;
      }
      .changeStroke {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#CFCFCF')};
      }
      .changeFill {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#DC4C4C' : '#CFCFCF')};
      }
      .changeBg {
        transition: 0.4s;
        opacity: ${({ active }) => (active ? '1' : '0.5')};
        fill: ${({ active }) => (active ? '#282A32' : '#DBDBDB')};
      }
    }

    &:hover {
      ${TierIcon} {
        .changeStroke {
          transition: 0.4s;
          stroke: #dc4c4c;
        }
        .changeFill {
          transition: 0.4s;
          fill: #dc4c4c;
        }
        .changeBg {
          transition: 0.4s;
          opacity: 1;
          fill: #282a32;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 37px;
      left: -80px;
      width: 52px;
      transform: matrix(-0.56, 0.83, 0.83, 0.56, 0, 0);

      ${({ theme }) => theme.adaptive.index.xl} {
        width: 45px;
        top: 32px;
        left: -75px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        width: 39px;
        top: 27px;
        left: -61px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        width: 33px;
        top: 23px;
        left: -55px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        width: 31px;
        top: 21px;
        left: -50px;
      }
    }
  }

  &:nth-child(5) {
    position: absolute;
    top: 159px;
    right: -142px;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 140px;
      right: -124px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 119px;
      right: -106px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 103px;
      right: -92px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 95px;
      right: -84px;
    }

    ${TierIcon} {
      content: '';
      position: absolute;
      top: 5px;
      left: -31px;
      width: 21px;
      height: 24px;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 4px;
        left: -27px;
        width: 18px;
        height: 21px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        left: -23px;
        width: 15px;
        height: 18px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        left: -20px;
        width: 13px;
        height: 15px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        left: -18px;
        width: 12px;
        height: 14px;
      }
      .changeStroke {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#fff')};
      }
      .changeFill {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#DC4C4C' : '#fff')};
      }
      .changeBg {
        transition: 0.4s;
        fill-opacity: ${({ active }) => (active ? '1' : '0.1')};
        fill: ${({ active }) => (active ? '#282A32' : '#fff')};
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#fff')};
      }
    }

    &:hover {
      ${TierIcon} {
        .changeStroke {
          transition: 0.4s;
          stroke: #dc4c4c;
        }

        .changeFill {
          transition: 0.4s;
          fill: #dc4c4c;
        }

        .changeBg {
          transition: 0.4s;
          fill-opacity: 1;
          fill: #282a32;
          stroke: #dc4c4c;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: -92px;
      width: 79px;
      transform: matrix(-0.78, 0.63, 0.63, 0.78, 0, 0);

      ${({ theme }) => theme.adaptive.index.xl} {
        width: 69px;
        top: 13px;
        left: -79px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        top: 6px;
        left: -62px;
        width: 59px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        top: 2px;
        left: -47px;
        width: 51px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        left: -44px;
        width: 47px;
      }
    }
  }

  &:nth-child(6) {
    position: absolute;
    top: 350px;
    right: -187px;

    ${({ theme }) => theme.adaptive.index.xl} {
      top: 298px;
      right: -164px;
    }

    ${({ theme }) => theme.adaptive.index.lg} {
      top: 260px;
      right: -140px;
    }

    ${({ theme }) => theme.adaptive.index.slg} {
      top: 220px;
      right: -120px;
    }

    ${({ theme }) => theme.adaptive.index.md} {
      top: 208px;
      right: -110px;
    }

    ${TierIcon} {
      content: '';
      position: absolute;
      top: 5px;
      left: -32px;
      width: 21px;
      height: 21px;
      ${({ theme }) => theme.adaptive.index.xl} {
        top: 4px;
        left: -28px;
        width: 18px;
        height: 18px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        left: -24px;
        width: 16px;
        height: 16px;
      }
      ${({ theme }) => theme.adaptive.index.slg} {
        left: -21px;
        width: 14px;
        height: 14px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        left: -20px;
        width: 13px;
        height: 13px;
      }
      .changeStroke {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#fff')};
      }
      .changeStroke2 {
        transition: 0.4s;
        stroke: ${({ active }) => (active ? '#DC4C4C' : '#f1f1f1')};
      }
      .changeFill {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#DC4C4C' : '#fff')};
      }
      .changeBg {
        transition: 0.4s;
        fill: ${({ active }) => (active ? '#282A32' : '#D3D3D3')};
      }
    }

    &:hover {
      ${TierIcon} {
        .changeStroke {
          transition: 0.4s;
          stroke: #dc4c4c;
        }

        .changeStroke2 {
          transition: 0.4s;
          stroke: #dc4c4c;
        }

        .changeFill {
          transition: 0.4s;
          fill: #dc4c4c;
        }

        .changeBg {
          transition: 0.4s;
          fill: #282a32;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: -70px;
      width: 42px;
      transform: matrix(-0.94, 0.33, 0.33, 0.94, 0, 0);

      ${({ theme }) => theme.adaptive.index.xl} {
        width: 36px;
        left: -65px;
      }

      ${({ theme }) => theme.adaptive.index.lg} {
        width: 31px;
        left: -44px;
      }

      ${({ theme }) => theme.adaptive.index.slg} {
        width: 27px;
        left: -33px;
      }

      ${({ theme }) => theme.adaptive.index.md} {
        width: 25px;
        left: -28px;
      }
    }
  }

  &:hover {
    ${TierTitle} {
      color: #d34d55;
      opacity: 1;
    }

    ${TierSubTitle} {
      opacity: 1;
    }
  }

  ${TierTitle} {
    color: ${({ active }) => (active ? '#d34d55' : '#1d1e1f')};
    opacity: ${({ active }) => (active ? '1' : '0.35')};
  }

  ${TierSubTitle} {
    opacity: ${({ active }) => (active ? '1' : '0.2')};
  }
`

const AlgoPadSliderWrap = styled.div`
  width: 330px;
  margin: 0 auto;

  .tier-slider {
    .slick-slide {
      width: fit-content;
      margin-right: 20px;

      ${({ theme }) => theme.adaptive.index.xs} {
        margin-right: 17px;
      }
    }

    .slick-list {
      padding-left: 0 !important;
    }

    .slick-track {
      display: flex;
    }
  }
`

const AlgoPadSlider = styled.div`
  position: relative;
  width: 100vw;
  margin-top: 70px;
  margin-bottom: 380px;

  ${({ theme }) => theme.adaptive.index.xs} {
    margin-top: 54px;
    margin-bottom: 302px;
  }
`

const AlgoPadSlide = styled.div<tierProps>`
  border-radius: 7px;
  padding: 5px 15px;
  height: 50px;

  ${({ theme }) => theme.adaptive.index.xs} {
    border-radius: 6px;
    padding: 4px 14px;
    height: 43px;
  }

  border: ${({ active }) =>
    active ? '1px solid #d34d55' : '1px solid #E6E6E6'};
  transition: 0.4s;

  ${TierTitle} {
    width: 100%;
    color: ${({ active }) => (active ? '#d34d55' : '#1d1e1f')};
    opacity: ${({ active }) => (active ? '1' : '0.35')};
  }

  ${TierSubTitle} {
    opacity: ${({ active }) => (active ? '1' : '0.2')};
  }

  &:nth-child(1) {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
  }
`

const TierAboutBlock = styled.div`
  position: relative;
  width: 100%;
  margin-top: 209px;

  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 184px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 157px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 136px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 125px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 73px;
  }
`

const TierAboutRow = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  margin-bottom: 21px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 7px;
  }
`

const TierAboutText = styled.div`
  font-family: 'MMC';
  font-size: 33px;
  line-height: 39px;
  letter-spacing: -0.03em;
  color: #1d1e1f;
  text-align: center;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 28px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 24px;
    line-height: 29px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 21px;
    line-height: 25px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 20px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 13px;
    line-height: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const TierAboutVal = styled.div`
  font-family: 'Aldrich';
  font-size: 53px;
  line-height: 51px;
  letter-spacing: -0.03em;
  color: #d7544f;

  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 46px;
    line-height: 45px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 40px;
    line-height: 39px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 34px;
    line-height: 33px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 32px;
    line-height: 31px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 18px;
    line-height: 18px;
  }
`

const StakeBtn = styled.button`
  position: relative;
  display: block;
  width: 330px;
  height: 60px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  padding: 18px;
  margin: 0 auto;
  margin-top: 40px;
  outline: none;
  z-index: 2;
  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 290px;
    height: 53px;
    border-radius: 6px;
    padding: 15px;
    font-size: 26px;
    margin-top: 35px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 247px;
    height: 45px;
    border-radius: 5px;
    padding: 12px;
    font-size: 20px;
    margin-top: 30px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 213px;
    height: 39px;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-top: 26px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 197px;
    height: 36px;
    border-radius: 4px;
    padding: 9px;
    font-size: 16px;
    margin-top: 24px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 152px;
    height: 28px;
    border-radius: 3px;
    padding: 6px;
    font-size: 13px;
    margin-top: 18px;
  }
  span {
    color: #d7544f;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 289px;
    height: 2px;
    background: url('${btnBorder}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 249px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 213px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 184px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 169px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 96px;
    }
  }
`

const TierAboutImg = styled.img`
  position: absolute;
  display: block;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 350px;
  ${({ theme }) => theme.adaptive.index.xl} {
    height: 305px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 260px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    height: 220px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    height: 210px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    top: 50%;
    height: 164px;
  }
`

const TierAboutTitle = styled(Title)`
  line-height: 100%;
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 24px;
    margin-top: 45px;
    margin-bottom: 4px;
  }
`

const TierAboutSubTitle = styled.div`
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  opacity: 0.35;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 10px;
  }
`

const AlgoPadInfo = styled.div<tierProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  ${Title} {
    ${({ theme }) => theme.adaptive.index.sm} {
      margin-top: 134px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      margin-top: 115px;
    }
  }
  ${TierAboutTitle} {
    ${({ theme }) => theme.adaptive.index.sm} {
      margin-top: 45px;
    }
  }
`
