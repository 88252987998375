import React from 'react'
import styled from 'styled-components'

interface dotsProps {
  activeIdx: number
}

interface dotProps {
  active: boolean
}

const Dots: React.FunctionComponent<dotsProps> = (props) => {
  return (
    <Root>
      <Dot active={props.activeIdx === 0} />
      <Dot active={props.activeIdx === 1} />
      <Dot active={props.activeIdx === 2} />
    </Root>
  )
}

export default Dots

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

const Dot = styled.div<dotProps>`
  width: ${({ active }) => (active ? '19px' : '8px')};
  height: 4px;
  background: ${({ active }) => (active ? '#D7544F' : '#D9D9D9')};
  border-radius: 11px;
  transition: 0.4s;
  margin: 0 4px;
  ${({ theme }) => theme.adaptive.index.xs} {
    width: ${({ active }) => (active ? '16px' : '7px')};
    height: 3px;
    border-radius: 9px;
    margin: 0 3px;
  }
`
