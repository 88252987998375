import { Base64 } from 'js-base64'
import { API_URL, API_BASIC_AUTH_LOGIN, API_BASIC_AUTH_PASS } from 'configs/app'

export const checkPassword = async (password: string) => {
  let auth = ''
  if (API_BASIC_AUTH_LOGIN && API_BASIC_AUTH_PASS) {
    const authString = `${API_BASIC_AUTH_LOGIN}:${API_BASIC_AUTH_PASS}`
    auth = `Basic ${Base64.encode(authString)}`
  }
  const response = await fetch(`${API_URL}/secret`, {
    method: 'POST',
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  })
  return response.json()
}

export const checkSecretStatus = async () => {
  let auth = ''
  if (API_BASIC_AUTH_LOGIN && API_BASIC_AUTH_PASS) {
    const authString = `${API_BASIC_AUTH_LOGIN}:${API_BASIC_AUTH_PASS}`
    auth = `Basic ${Base64.encode(authString)}`
  }
  const response = await fetch(`${API_URL}/secret/check`, {
    method: 'GET',
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
  })
  return response.json()
}

export const getSecretFile = async () => {
  let auth = ''
  if (API_BASIC_AUTH_LOGIN && API_BASIC_AUTH_PASS) {
    const authString = `${API_BASIC_AUTH_LOGIN}:${API_BASIC_AUTH_PASS}`
    auth = `Basic ${Base64.encode(authString)}`
  }
  const response = await fetch(`${API_URL}/secret/file`, {
    method: 'GET',
    headers: {
      Authorization: auth,
      'Content-Type': 'application/json',
    },
  })
  return response
}
