import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import titleLine from '../../images/form/title-line.svg'
import areaLines from '../../images/form/area-lines.svg'
import starImg from '../../images/form/star.svg'
import bigStar from '../../images/form/big-star.svg'
import closeImg from '../../images/form/close.svg'
import { has, clone } from 'lodash'
import { sendEmail } from '../../helpers/sendEmail'
import { SendEmailErrors } from '../../types/mail/mailTypes'

export interface ModalProps {
  onClose(): void
}

const DEFAULT_ERRORS_STATE: SendEmailErrors = {
  name: false,
  email: false,
  message: false,
}

interface ErrorProps {
  error?: boolean
}

const ContactForm: React.FunctionComponent<ModalProps> = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [fieldErrors, setFieldErrors] = useState(DEFAULT_ERRORS_STATE)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const error = <Error>{errorMessage}</Error>

  const resetForm = () => {
    setMessage('')
    setName('')
    setEmail('')
    setFieldErrors(DEFAULT_ERRORS_STATE)
    setErrorMessage('')
  }

  const handleSubmit = async () => {
    setIsSending(true)
    const response = await sendEmail({ name, email, message })
    setIsSending(false)
    if (response.error) {
      console.log(response)
      setErrorMessage(response.error.details[0].message)
      const defaultState = clone(DEFAULT_ERRORS_STATE)
      const errorField: keyof SendEmailErrors =
        response.error.details[0].context.key
      if (has(fieldErrors, errorField)) {
        defaultState[errorField] = true
        setFieldErrors({ ...defaultState })
      }
      return
    }
    setIsSuccess(true)
    setTimeout(() => {
      setIsSuccess(false)
    }, 5000)
    resetForm()
  }

  return (
    <Form>
      <Close onClick={() => props.onClose()} />
      <Title>Contact Us</Title>
      <Container>
        <Column>
          <Field error={fieldErrors.name}>
            <Line>
              <Label>Name*</Label>
              {error}
            </Line>
            <Input
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Field>
          <Field error={fieldErrors.email}>
            <Line>
              <Label>Email*</Label>
              {error}
            </Line>
            <Input
              type="text"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Field>
        </Column>
        <Column>
          <Field error={fieldErrors.message}>
            <Line>
              <Label>Message*</Label>
              {error}
            </Line>
            <TextArea
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Field>
        </Column>
      </Container>
      <Button onClick={handleSubmit}>Send Message</Button>
      <Success active={isSuccess}>
        <SuccessTitle>
          Thank you for reaching out. We will revert shortly.
        </SuccessTitle>
        <SuccessImg src={bigStar} />
        <Close onClick={() => props.onClose()} />
      </Success>
      <FormLines>
        <FormLine />
        <FormLine />
      </FormLines>
    </Form>
  )
}

export default ContactForm

export interface SuccessProps {
  active?: boolean
}

const Form = styled.div`
  position: relative;
  width: 1238px;
  height: 598px;
  padding: 108px 186px;
  background: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1115px;
    height: 538px;
    padding: 97px 168px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 941px;
    height: 454px;
    padding: 82px 141px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 811px;
    height: 454px;
    padding: 82px 76px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 799px;
    height: 386px;
    padding: 70px 120px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    width: 710px;
    height: 398px;
    padding: 72px 67px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 315px;
    height: 559px;
    padding: 32px 36px 74px 26px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 290px;
    height: 516px;
    padding: 30px 32px 69px 25px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 260px;
    height: 445px;
    padding: 27px 29px 63px 22px;
  }
`

const Title = styled.div`
  position: relative;
  font-size: 62px;
  line-height: 137%;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-bottom: 69px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 56px;
    margin-bottom: 62px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 48px;
    margin-bottom: 53px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 42px;
    margin-bottom: 45px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    font-size: 42px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 38px;
    margin-bottom: 38px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 35px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 31px;
    margin-bottom: 32px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -22px;
    left: 50%;
    transform: translateX(-50%);
    width: 98px;
    height: 2px;
    background: url('${titleLine}') center no-repeat;
    background-size: contain;
    ${({ theme }) => theme.adaptive.index.xl} {
      bottom: -20px;
      width: 88px;
      height: 2px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      bottom: -19px;
      width: 75px;
      height: 1.5px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      bottom: -15px;
      width: 75px;
      height: 1.5px;
    }
    ${({ theme }) => theme.adaptive.index.smd} {
      bottom: -12px;
      width: 63px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      bottom: -10px;
      width: 65px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      bottom: -9px;
      width: 60px;
      height: 1px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      bottom: -11px;
      width: 54px;
      height: 1px;
    }
  }
`

const Label = styled.div`
  font-family: 'MMC';
  font-size: 16px;
  line-height: 144%;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    line-height: 100%;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 13px;
  }
`

const Error = styled(Label)`
  color: #ff2e2e;
  font-size: 12px;
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 10px;
  }
`

const Container = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.index.sm} {
    flex-wrap: wrap;
  }
`

const Field = styled.div<ErrorProps>`
  width: 100%;
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 12px;
  }
  ${Error} {
    opacity: ${({ error }) => (error ? '1' : 0)};
  }
`

const Column = styled.div`
  position: relative;
  width: 407px;
  height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 367px;
    height: 158px;
    margin-right: 36px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 309px;
    height: 134px;
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 263px;
    height: 117px;
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    width: 271px;
    height: 118px;
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 100%;
    height: auto;
    margin-right: 0;
    &:last-child {
      ${Field} {
        margin: 0;
      }
    }
  }
  &:last-child {
    margin-right: 0;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: -15px;
      width: 13px;
      height: 140px;
      background: url('${areaLines}') center no-repeat;
      background-size: cover;
      ${({ theme }) => theme.adaptive.index.xl} {
        right: -12px;
        width: 10px;
        height: 126px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        right: -10px;
        width: 8px;
        height: 106px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        right: -9px;
        width: 7px;
        height: 90px;
      }
      ${({ theme }) => theme.adaptive.index.smd} {
        right: -9px;
        width: 7px;
        height: 93px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        content: unset;
      }
    }
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 8px;
  }
`

const InputsCss = css`
  width: 100%;
  padding: 8px 13px;
  font-size: 14px;
  line-height: 164%;
  background: #ebebeb;
  color: #1d1e1f;
  border: 0;
  border-radius: 0;
  outline: none;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 6px 12px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 5px 10px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 13px;
    padding: 4px 8px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    font-size: 12px;
    padding: 6px 9px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    padding: 9px 9px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 13px;
    padding: 9px 8px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 6px 8px;
  }
`

const Input = styled.input`
  height: 40px;
  ${InputsCss};
  ${({ theme }) => theme.adaptive.index.xl} {
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    height: 27px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    height: 36px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 33px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    height: 29px;
  }
`

const TextArea = styled.textarea`
  position: relative;
  height: 140px;
  ${InputsCss};
  resize: none;
  ${({ theme }) => theme.adaptive.index.xl} {
    height: 126px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    height: 106px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    height: 90px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    height: 93px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    height: 110px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 101px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    height: 72px;
  }
`

const Button = styled.button`
  font-family: 'Aldrich';
  position: relative;
  display: block;
  width: 254px;
  height: 40px;
  margin: 36px auto 0;
  background: #dc4d4d;
  padding: 9px 72px 9px 64px;
  text-align: left;
  font-size: 16px;
  line-height: 144%;
  color: #ffffff;
  outline: none;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 229px;
    height: 36px;
    margin: 33px auto 0;
    font-size: 14px;
    padding: 9px 65px 8px 58px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 193px;
    height: 30px;
    margin: 27px auto 0;
    font-size: 14px;
    padding: 6px 48px 6px 42px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 164px;
    height: 26px;
    margin: 23px auto 0;
    font-size: 13px;
    padding: 6px 37px 3px 30px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    width: 169px;
    height: 27px;
    margin: 24px auto 0;
    font-size: 12px;
    padding: 5px 42px 5px 36px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 100%;
    height: 46px;
    margin: 28px auto 0;
    font-size: 14px;
    padding: 16px 82px 16px 67px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 42px;
    margin: 26px auto 0;
    font-size: 13px;
    padding: 15px 75px 12px 61px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    height: 38px;
    font-size: 13px;
    padding: 13px 63px 11px 50px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 7px;
    top: 17px;
    right: 49px;
    background: url('${starImg}') center no-repeat;
    ${({ theme }) => theme.adaptive.index.xl} {
      background-size: cover;
      width: 14px;
      height: 7px;
      top: 15px;
      right: 44px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 12px;
      height: 6px;
      top: 12px;
      right: 31px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 10px;
      height: 5px;
      top: 12px;
      right: 23px;
    }
    ${({ theme }) => theme.adaptive.index.smd} {
      width: 10px;
      height: 5px;
      top: 11px;
      right: 27px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 19px;
      height: 9px;
      top: 21px;
      right: 55px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      width: 18px;
      height: 8px;
      top: 19px;
      right: 50px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 16px;
      height: 7px;
      top: 17px;
      right: 43px;
    }
  }
`

const Close = styled.button`
  position: absolute;
  top: 42px;
  right: 53px;
  width: 33px;
  height: 33px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: 37px;
    right: 47px;
    width: 30px;
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    top: 32px;
    right: 40px;
    width: 25px;
    height: 25px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: 27px;
    right: 34px;
    width: 22px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    top: 27px;
    right: 31px;
    width: 22px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    top: unset;
    bottom: 26px;
    right: 50%;
    transform: translateX(50%);
    width: 22px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    bottom: 24px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 18px;
    height: 18px;
  }
`

const Success = styled.div<SuccessProps>`
  position: absolute;
  display: ${({ active }) => (active ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 109px 78px 88px;
  background: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 97px 66px 73px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 87px 36px 61px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 91px 60px 79px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 76px 59px 68px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    padding: 78px 12px 26px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 46px 25px 46px 26px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 40px 21px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 37px 20px 24px;
  }
`

const SuccessTitle = styled.div`
  font-family: 'Aldrich';
  margin-bottom: 52px;
  font-size: 62px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 47px;
    font-size: 56px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 30px;
    font-size: 48px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 30px;
    font-size: 39px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 39px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    margin-bottom: 33px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 30px;
    margin-bottom: 51px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 28px;
    margin-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 25px;
    margin-bottom: 40px;
  }
`

const SuccessImg = styled.img`
  display: block;
  width: 64px;
  height: 88px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 61px;
    height: 88px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 50px;
    height: 72px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 78px;
    height: 112px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 78px;
    height: 112px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 64px;
    height: 98px;
  }
`

const FormLines = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  left: calc(100% + 5px);
  ${({ theme }) => theme.adaptive.index.lg} {
    left: calc(100% + 4px);
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    left: calc(100% + 3px);
  }
  ${({ theme }) => theme.adaptive.index.md} {
    left: calc(100% + 3px);
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    left: calc(100% + 2px);
  }
`

const FormLine = styled.div`
  height: 100%;
  width: 3px;
  margin-right: 4px;
  background: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 2px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.index.smd} {
    width: 1px;
  }
  &:last-child {
    width: 1px;
    margin: 0;
    ${({ theme }) => theme.adaptive.index.smd} {
      width: 2px;
    }
  }
`
