// @ts-nocheck
import * as THREE from 'three'

export class Warplines {
  constructor(stage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.count = 8000
    this.acceleration = 0.03
    this.accelerationTail = 0.005
    this.hyperDelay = 1500
    this.warplinesPos = 100
    this.geometry = null
    this.group = null

    this.ready = false
    this.stage = stage
  }

  onInit() {
    this.group = new THREE.Object3D()

    this.create()
    this.ready = true
  }

  onFrame() {
    if (this.ready && this.hyper) {
      for (let i = 0; i < this.count; i++) {
        const positions = this.geometry.getAttribute('position').array
        const velocities = this.geometry.getAttribute('velocity').array
        const vStart = 2 * i
        const vEnd = 2 * i + 1
        const zStart = 6 * i + 2
        const zEnd = 6 * i + 5
        velocities[vStart] += this.acceleration //bump up the velocity by the acceleration amount
        velocities[vEnd] += this.acceleration - this.accelerationTail

        positions[zStart] += velocities[vStart] //z
        positions[zEnd] += velocities[vEnd] //z end

        if (positions[zEnd] > this.warplinesPos) {
          const z = THREE.MathUtils.randFloatSpread(500)
          positions[zStart] = z
          positions[zEnd] = z
          velocities[vStart] = 0
          velocities[vEnd] = 0
        }
      }

      this.geometry.getAttribute('position').needsUpdate = true
    }
  }

  create() {
    this.geometry = new THREE.BufferGeometry()
    this.geometry.setAttribute(
      'position',
      new THREE.BufferAttribute(new Float32Array(6 * this.count), 3)
    )
    this.geometry.setAttribute(
      'velocity',
      new THREE.BufferAttribute(new Float32Array(2 * this.count), 1)
    )

    for (let i = 0; i < this.count; i++) {
      var x = THREE.MathUtils.randFloatSpread(400)
      var y = THREE.MathUtils.randFloatSpread(200)
      var z = THREE.MathUtils.randFloatSpread(500)
      var xx = x
      var yy = y
      var zz = z
      const pa = this.geometry.getAttribute('position').array
      const va = this.geometry.getAttribute('velocity').array
      //line start
      const idxOffset = i * 6
      pa[idxOffset] = x
      pa[idxOffset + 1] = y
      pa[idxOffset + 2] = z
      //line end
      pa[idxOffset + 3] = xx
      pa[idxOffset + 4] = yy
      pa[idxOffset + 5] = zz

      va[2 * i] = va[2 * i + 1] = 0
    }

    let mat = new THREE.LineBasicMaterial({ color: 0xffffff })
    let lines = new THREE.LineSegments(this.geometry, mat)
    this.group.add(lines)
  }

  hyperStart() {
    this.hyper = true
    this.stage.scene.add(this.group)
  }

  hyperStop() {
    setTimeout(() => {
      this.hyper = false
      this.stage.scene.remove(this.group)
    }, this.hyperDelay)
  }
}

class Wormhole {
  constructor(stage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.group = null
    this.texture = null

    this.ease = 10
    this.hyperDelay = 1500
    this.hype = {
      normalize: 0.001,
      scale: 0.15,
      speed: 6.26,
    }

    this.ready = false
    this.hyper = false
    this.stage = stage
  }

  // NO CONSOLE LOG STATEMENTS HERE
  onFrame() {
    const { hype, stage } = this
    if (this.ready) {
      if (this.hyper) {
        this.texture.offset.y -= hype.normalize * hype.speed
        this.texture.needsUpdate = true

        this.group.scale.set(hype.scale, hype.scale, 1)

        const { rotation, position } = stage.camera
        this.group.rotation.set(rotation.x, rotation.y, rotation.z)
        this.group.position.set(position.x, position.y, position.z)

        this.group.rotation.z -= 0.008

        return
      }
    }
  }

  onInit() {
    this.create()
    this.ready = true
  }

  create() {
    this.group = new THREE.Object3D()
    this.group.position.z = this.stage.camera.position.z
    const loader = new THREE.TextureLoader()
    loader.crossOrigin = ''
    this.texture = loader.load(
      'https://cdn.rawgit.com/devildrey33/devildrey33/ddb01d71/Web/Graficos/Banner_WormHole11.jpg'
    )
    this.texture.wrapT = THREE.RepeatWrapping
    this.texture.wrapS = THREE.RepeatWrapping

    var geometry = new THREE.CylinderGeometry(100, 5, 300, 40, 40, true)
    var material = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      opacity: 1,
      map: this.texture,
      blending: THREE.AdditiveBlending,
      side: THREE.BackSide,
      transparent: true,
      depthTest: true,
    })

    var color = new THREE.Color()
    color.setHSL(Math.random(), 1, 0.8)

    var light = new THREE.PointLight(color, 4, 50)
    light.position.set(0, 0, 0)

    var cylinder = new THREE.Mesh(geometry, material)
    cylinder.position.set(0, 0, 0)
    cylinder.rotation.x = Math.PI / 2

    this.group.add(cylinder)
    this.group.add(light)
  }

  hyperStart() {
    this.hyper = true
    setTimeout(() => {
      this.stage.scene.add(this.group)
    }, this.hyperDelay)
  }

  hyperStop() {
    setTimeout(() => {
      this.hyper = false
    }, this.hyperDelay)
  }
}

class Starfield {
  constructor(stage) {
    this.onInit = this.onInit.bind(this)
    this.onFrame = this.onFrame.bind(this)
    this.create = this.create.bind(this)
    this.total = 600
    this.spread = 2000
    this.size = 45
    this.destinationSize = 75

    this.distance = -3000
    this.destinationPos = 200

    this.hyperDistance = 5000
    this.hyperEnd = this.hyperDistance - this.distance - this.destinationPos
    this.ease = 15
    this.move = { x: 0, y: 0, z: this.distance }
    this.look = { x: 0, y: 0, z: 0 }
    this.group = null

    this.ready = false
    this.hyper = false
    this.stage = stage
  }

  onInit() {
    this.group = new THREE.Object3D()
    this.group.position.set(
      this.move.x,
      this.move.y,
      this.move.z - this.hyperDistance
    )

    this.group.rotation.set(this.look.x, this.look.y, this.look.z)

    this.create()
    this.stage.scene.add(this.group)
    this.ready = true
  }

  onFrame() {
    if (this.ready) {
      this.group.position.z += (this.move.z - this.group.position.z) / this.ease
    }
  }

  // create new star field group
  create() {
    const geometry = new THREE.BufferGeometry()
    const loader = new THREE.TextureLoader()
    loader.crossOrigin = ''
    const texture = loader.load(
      'https://rainner.github.io/wormhole-extreme/dist/images/star.png'
    )
    const material = new THREE.PointsMaterial({
      size: this.size,
      color: 0xffffff,
      opacity: 0.8,
      // blending: THREE.AdditiveBlending,
      transparent: true,
      // depthTest: true,
      map: texture,
    })
    const positions = new Float32Array((this.total + 1) * 3)

    const vertex = new THREE.Vector3()
    for (var i = 0; i < this.total; i++) {
      vertex.x = THREE.MathUtils.randFloatSpread(this.spread)
      vertex.y = THREE.MathUtils.randFloatSpread(this.spread) // this is the "distance" of the starfield
      vertex.z = THREE.MathUtils.randFloatSpread(this.hyperEnd)
      vertex.toArray(positions, i * 3)
    }
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    const stars = new THREE.Points(geometry, material)
    this.group.add(stars)
  }

  createLandingStar() {
    const loader = new THREE.TextureLoader()
    loader.crossOrigin = ''
    const bgTexture = loader.load(
      'https://rainner.github.io/wormhole-extreme/dist/images/star.png'
    )

    const sphereGeo = new THREE.CircleGeometry(this.destinationSize, 32)
    const sphereMat = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      opacity: 1,
      blending: THREE.AdditiveBlending,
      transparent: true,
      map: bgTexture,
    })

    const northStar = new THREE.Mesh(sphereGeo, sphereMat)
    northStar.position.set(0, 0, this.destinationPos)

    return northStar
  }

  hyperStart() {
    this.move = {
      x: 0,
      y: 0,
      z: this.distance,
    }
    this.group.scale.set(1, 1, 1)
    this.ease += 100
    this.move.z -= this.hyperDistance
    this.hyper = true

    const northStar = this.createLandingStar()
    this.group.add(northStar)
  }

  // on hyper stop
  hyperStop() {
    this.group.scale.set(1, 1, 1.99)
    this.ease -= 100
    this.move.z += this.hyperEnd
    this.hyper = false
  }
}
var _w = window,
  _b = document.body,
  _d = document.documentElement
export class Stage {
  constructor(id, opts) {
    this.triggerEvent = this.triggerEvent.bind(this)
    this.drawFrame = this.drawFrame.bind(this)
    this.onResize = this.onResize.bind(this)
    this.init = this.init.bind(this)

    this.objects = [
      new Starfield(this),
      new Wormhole(this),
      new Warplines(this),
    ]

    this.width = 0 // is set later onResize
    this.height = 0 // is set later onResize
    this.ease = 20
    this.move = { x: 0, y: 0, z: 200 }
    this.look = { x: 0, y: 0, z: 0 }

    this.id = id || 'threejs-stage'

    this.renderer = this.resolveRenderer(opts || {})
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    )
    this.light = new THREE.SpotLight(0xffffff, 1, 3000, 0.4, 0.5, 1)

    this.scene = new THREE.Scene()

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(0x000000, 0)
    this.renderer.sortObjects = false

    this.camera.position.set(this.move.x, this.move.y, this.move.z)
    this.camera.rotation.set(this.look.x, this.look.y, this.look.z)
    this.camera.lookAt(this.scene.position)
    this.light.position.set(this.move.x, this.move.y, this.move.z)

    this.scene.add(this.light)

    this.ready = true
  }
  // check WebGL support - https://github.com/adolfintel/warpspeed
  webglSupport() {
    try {
      var canvas = document.createElement('canvas')
      return !!(
        window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'))
      )
    } catch (e) {
      return false
    }
  }

  resolveRenderer(params) {
    params = params || {}

    if (this.webglSupport() && THREE.WebGLRenderer) {
      return new THREE.WebGLRenderer(params)
    }
  }

  triggerEvent(name, data) {
    if (this.ready) {
      this.objects.forEach((o) => {
        if (typeof o[name] === 'function') {
          o[name].call(o, this, data)
        }
      })
    }
  }

  drawFrame() {
    if (this.ready) {
      this.triggerEvent('onFrame', this.screenInfo())

      this.renderer.render(this.scene, this.camera)
    }
    requestAnimationFrame(this.drawFrame)
  }

  screenInfo() {
    const screenWidth = Math.max(
      0,
      _w.innerWidth || _d.clientWidth || _b.clientWidth || 0
    )
    const screenHeight = Math.max(
      0,
      _w.innerHeight || _d.clientHeight || _b.clientHeight || 0
    )
    return {
      width: screenWidth,
      height: screenHeight,
      ratio: screenWidth / screenHeight,
      centerX: screenWidth / 2,
      centerY: screenHeight / 2,
    }
  }

  onResize(e) {
    var info = this.screenInfo()
    this.width = info.width
    this.height = info.height

    if (this.ready) {
      this.renderer.setSize(this.width, this.height)
      this.camera.aspect = this.width / this.height
      this.camera.updateProjectionMatrix()
    }
  }

  // start hyper travel
  hyperStart() {
    this.triggerEvent('hyperStart')
  }

  hyperStop() {
    this.triggerEvent('hyperStop')
  }

  init(canvasWrapper: HTMLElement) {
    if (this.renderer && this.renderer.domElement) {
      this.renderer.domElement.setAttribute('id', this.id)
      canvasWrapper.appendChild(this.renderer.domElement)
    }
    this.onResize()
    this.triggerEvent('onInit')
    this.drawFrame()
  }
}
