import gradientList, { gradientListProps } from 'store/gradientList'

export const drawFire = (canvas: HTMLCanvasElement) => {
  if (canvas) {
    const context = canvas.getContext('2d')

    if (context) {
      createCanvasPath(context, 0.2)
      context.moveTo(1478.44995, 211.983)
      context.lineTo(1460.33997, 47.0209)
      context.lineTo(1478.83997, 0.49402)
      context.lineTo(1498.33997, 47.0209)
      context.lineTo(1478.44995, 211.983)
      context.fillStyle = createGradient(gradientList[0], context)
      context.fill()
      endCanvasPath(context)

      createCanvasPath(context, 0.1)
      context.moveTo(855.27698, 641.99902)
      context.bezierCurveTo(
        1386.16003,
        502.99799,
        1475.47998,
        28.9978,
        1475.47998,
        28.9978
      )
      context.bezierCurveTo(
        1565.78003,
        282.99799,
        1662.56995,
        494.99701,
        2094.65991,
        635.99701
      )
      context.bezierCurveTo(
        2214.68994,
        675.164,
        3010.71997,
        962.995,
        3075.09009,
        1396.98999
      )
      context.bezierCurveTo(
        2974.30005,
        1882.98999,
        138.08501,
        1712,
        117.833,
        1397
      )
      context.bezierCurveTo(
        81.4103,
        830.5,
        706.40302,
        680.979,
        855.27698,
        641.99902
      )
      context.fillStyle = createGradient(gradientList[1], context)
      context.translate(1040.404, 36.8725)
      context.rotate(0.4)
      context.scale(2481.25, 1100.365)
      context.fill()
      endCanvasPath(context)

      createCanvasPath(context, 0.1)
      context.moveTo(1393.87, 166.343)
      context.bezierCurveTo(
        1451.05005,
        119.219,
        1458.41003,
        32.8355,
        1458.41003,
        32.8355
      )
      context.lineTo(1458.41003, 32.8355)
      context.lineTo(1467.07996, 87.7931)
      context.lineTo(1495.81006, 31.9747)
      context.bezierCurveTo(
        1495.81006,
        31.9747,
        1485.76001,
        57.6476,
        1467.07996,
        87.7931
      )
      context.bezierCurveTo(
        1449.73999,
        115.774,
        1424.95996,
        147.60899,
        1393.87,
        166.343
      )
      context.fillStyle = createGradient(gradientList[2], context)
      context.fill()
      endCanvasPath(context)

      createCanvasPath(context, 0.1)
      context.moveTo(1567.82996, 162.338)
      context.bezierCurveTo(
        1508.55005,
        118.006,
        1495.81006,
        28.974,
        1495.81006,
        28.974
      )
      context.lineTo(1495.81006, 28.974)
      context.lineTo(1491.54004, 88.0819)
      context.lineTo(1459.82996, 33.1212)
      context.bezierCurveTo(
        1459.82996,
        33.1212,
        1471.20996,
        58.621,
        1491.54004,
        88.0819
      )
      context.bezierCurveTo(
        1510.12,
        115.006,
        1536.17004,
        145.23801,
        1567.82996,
        162.338
      )

      context.fillStyle = createGradient(gradientList[3], context)
      context.fill()
      endCanvasPath(context)
    }
  }
}

export const drawLines = (canvas: HTMLCanvasElement) => {
  if (canvas) {
    const context = canvas.getContext('2d')

    if (context) {
      createCanvasPath(context, 1)
      context.moveTo(1470.83997, 50)
      context.bezierCurveTo(
        1408.85999,
        226.368,
        1315.20996,
        468.341,
        926.836,
        620
      )
      context.strokeStyle = createGradient(gradientList[4], context)
      context.stroke()
      endCanvasPath(context)

      createCanvasPath(context, 0.3)
      context.moveTo(1324.56006, 383.142)
      context.bezierCurveTo(
        1302.92004,
        408.08301,
        1292.81006,
        418.61499,
        1277.10999,
        434.61099
      )
      context.strokeStyle = createGradient(gradientList[5], context)
      context.stroke()
      endCanvasPath(context)

      createCanvasPath(context, 0.3)
      context.moveTo(1149.06006, 568.34601)
      context.bezierCurveTo(
        1123.75,
        589.54303,
        1112.08997,
        598.33899,
        1094.06006,
        611.64398
      )
      context.strokeStyle = createGradient(gradientList[6], context)
      context.stroke()
      endCanvasPath(context)

      createCanvasPath(context, 1)
      context.moveTo(1483.83997, 50)
      context.bezierCurveTo(
        1545.81006,
        226.368,
        1639.45996,
        468.341,
        2027.83997,
        620
      )
      context.strokeStyle = createGradient(gradientList[7], context)
      context.stroke()
      endCanvasPath(context)

      createCanvasPath(context, 0.3)
      context.moveTo(1760.32996, 507.5)
      context.bezierCurveTo(
        1792.82996,
        539.5,
        1804.82996,
        549.5,
        1825.82996,
        565
      )
      context.strokeStyle = createGradient(gradientList[11], context)
      context.stroke()
      endCanvasPath(context)

      createCanvasPath(context, 0.3)
      context.moveTo(1428.55005, 261.315)
      context.bezierCurveTo(
        1419.64001,
        284.948,
        1415.93005,
        292.76099,
        1408.96997,
        305.42899
      )

      context.strokeStyle = createGradient(gradientList[12], context)
      context.stroke()
      endCanvasPath(context)
    }
  }
}

const createCanvasPath = (
  context: CanvasRenderingContext2D,
  opacity: number = 1
) => {
  context.save()
  context.globalAlpha = opacity
  context.beginPath()
}

const endCanvasPath = (context: CanvasRenderingContext2D) => {
  context.closePath()
  context.restore()
}

const createGradient = (
  data: gradientListProps,
  context: CanvasRenderingContext2D
) => {
  let gradient: CanvasGradient
  if (!data.radial) {
    gradient = context.createLinearGradient(
      data.coordinates.x0,
      data.coordinates.y0,
      data.coordinates.x1,
      data.coordinates.y1
    )
  } else {
    gradient = context.createRadialGradient(
      data.coordinates.x0,
      data.coordinates.y0,
      data.coordinates.r0 as number,
      data.coordinates.x1,
      data.coordinates.y1,
      data.coordinates.r1 as number
    )
  }
  data.colors.forEach((e) => {
    gradient.addColorStop(e.breakpoint, e.color)
  })

  return gradient
}
