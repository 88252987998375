import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { FAQ_SIGMA_LINK } from '../../configs/app'
import { Link } from 'react-scroll'
import LaunchBtn from './LaunchBtn'
import BuyBtn from './BuyBtn'
import gsap, { Linear } from 'gsap'
import useWindowSize from '../../helpers/utils/useWindowSize'

interface menuProps {
  onBtnClick: () => void
  isMain: boolean
}

interface colorProps {
  red?: boolean
}

const Navigation: React.FunctionComponent<menuProps> = (props) => {
  const { width } = useWindowSize()
  const ItemRef = useRef([])
  ItemRef.current = []

  const addToItemRefs = (el: any) => {
    // @ts-ignore
    if (el && !ItemRef.current.includes(el)) {
      // @ts-ignore
      ItemRef.current.push(el)
    }
  }

  const FadeIn = (elem: gsap.TweenTarget, delay: number, yVal: number) => {
    gsap.fromTo(
      elem,
      { y: yVal, opacity: 0 },
      {
        delay: 0.1 + delay,
        y: 0,
        opacity: 1,
        duration: 0.4,
        ease: Linear.easeNone,
      }
    )
  }

  useEffect(() => {
    if (width > 900) {
      if (ItemRef.current) {
        let delay = 0.9
        ItemRef.current.forEach((el) => {
          delay += 0.1
          FadeIn(el, delay, -50)
        })
      }
    }
  }, [])

  return (
    <Root>
      <Row>
        {/*<LinkWrap ref={addToItemRefs}>*/}
        {/*  <NavItem href={'/'} red={true}>*/}
        {/*    DEX*/}
        {/*  </NavItem>*/}
        {/*</LinkWrap>*/}
        <LinkWrap ref={addToItemRefs}>
          {props.isMain && (
            <NavItem>
              <Link duration={300} smooth={true} to={'tiers'}>
                Tiers
              </Link>
            </NavItem>
          )}
        </LinkWrap>
        <LinkWrap ref={addToItemRefs}>
          <NavItem onClick={props.onBtnClick}>
            <span>Contact us</span>
          </NavItem>
        </LinkWrap>
        {/*<LinkWrap ref={addToItemRefs}>*/}
        {/*  <NavItem href={'/'}>Buy Sigma</NavItem>*/}
        {/*</LinkWrap>*/}
        {/*<BtnWrap ref={props.addRef}>*/}
        {/*  <ContactBtn onClick={props.onBtnClick} />*/}
        {/*</BtnWrap>*/}
        <BtnWrap ref={addToItemRefs}>
          <LaunchBtn />
        </BtnWrap>
        <BtnWrap ref={addToItemRefs}>
          <BuyBtn />
        </BtnWrap>
      </Row>
    </Root>
  )
}

export default Navigation

const Root = styled.div``

const Row = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.index.sm} {
    justify-content: center;
    flex-wrap: wrap;
    width: 260px;
    margin: 112px auto 0;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 220px;
    margin: 101px auto 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 32px auto 0;
  }
`

const LinkWrap = styled.div`
  opacity: 0;
  margin-right: 100px;
  will-change: transform;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 86px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 72px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 58px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 35px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
    margin-right: 41px;
    margin-top: 24px;
  }
  &:nth-child(2) {
    margin-right: 50px;
    ${({ theme }) => theme.adaptive.index.xl} {
      margin-right: 44px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      margin-right: 39px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      margin-right: 38px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      margin-right: 0;
      margin-top: 24px;
    }
  }
`

const BtnWrap = styled.div`
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
`

const NavItem = styled.div<colorProps>`
  a,
  span {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    color: #ffffff;
    transition: all 0.4s;
    cursor: pointer;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 15px;
      line-height: 15px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 14px;
      line-height: 14px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 13px;
      line-height: 13px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 12px;
      line-height: 11px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      text-align: left;
      font-size: 16px;
      color: #292b33;
    }
    &:hover {
      color: #d55054;
      &:before {
        background: linear-gradient(180deg, #dc5a4c 0%, #cf4858 100%);
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      background: ${({ red }) =>
        red ? 'linear-gradient(180deg, #DC5A4C 0%, #CF4858 100%);' : '#b4b4b4'};
      transition: all 0.4s;
      ${({ theme }) => theme.adaptive.index.xl} {
        left: -11px;
      }
      ${({ theme }) => theme.adaptive.index.lg} {
        width: 3px;
        height: 3px;
        left: -8px;
      }
      ${({ theme }) => theme.adaptive.index.md} {
        width: 2px;
        height: 2px;
        left: -6px;
      }
      ${({ theme }) => theme.adaptive.index.sm} {
        left: -10px;
        width: 4px;
        height: 4px;
      }
    }
  }
`
