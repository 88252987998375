import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { drawFire, drawLines } from './draw'

const RocketFire: React.FunctionComponent = () => {
  const canvasLinesRef = useRef<any>(null)
  const canvasFireRef = useRef<any>(null)
  useEffect(() => {
    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        function (callback: any) {
          window.setTimeout(callback, 1000 / 60)
        }
      )
    })()
    window.addEventListener('resize', resizeCanvas, false)
    function resizeCanvas() {
      const canvasLines: HTMLCanvasElement = canvasLinesRef.current
      canvasLines.width = canvasLinesRef.current.offsetWidth
      canvasLines.height = canvasLinesRef.current.offsetHeight

      const canvasFire: HTMLCanvasElement = canvasFireRef.current
      canvasFire.width = canvasFireRef.current.offsetWidth
      canvasFire.height = canvasFireRef.current.offsetHeight

      drawLines(canvasLines)
      drawFire(canvasFire)
    }
    resizeCanvas()
  }, [])

  return (
    <Root>
      <CanvasLines ref={canvasLinesRef} />
      <CanvasFire ref={canvasFireRef} />
    </Root>
  )
}

export default RocketFire

const Root = styled.div`
  width: 100%;
  height: 100%;
`

const CanvasLines = styled.canvas`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`

const CanvasFire = styled.canvas`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(8px);
`
