import { createGlobalStyle } from 'styled-components'
import MMCWoff from './fonts/MMC_Regular.woff'
import MMCWoff2 from './fonts/MMC_Regular.woff2'
import MMCBoldWoff from './fonts/MMC-Bold.woff'
import MMCBoldWoff2 from './fonts/MMC-Bold.woff2'
import AldrichWoff from './fonts/Aldrich-Regular.woff'
import AldrichWoff2 from './fonts/Aldrich-Regular.woff2'

const MMC = {
  regular: {
    woff2: MMCWoff2,
    woff: MMCWoff,
  },
  bold: {
    woff2: MMCBoldWoff2,
    woff: MMCBoldWoff,
  },
}

const Aldrich = {
  regular: {
    woff2: AldrichWoff2,
    woff: AldrichWoff,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'MMC';
    src: local('MMC'),
    url(${MMC.regular.woff2}) format('woff2'),
    url(${MMC.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'MMC';
    src: local('MMC'),
    url(${MMC.bold.woff2}) format('woff2'),
    url(${MMC.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Aldrich';
    src: local('Aldrich'),
    url(${Aldrich.regular.woff2}) format('woff2'),
    url(${Aldrich.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

export default Fonts
