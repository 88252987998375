import tierOneImg from 'images/index/about/tiers/tier-1-img.svg'
import tierTwoImg from 'images/index/about/tiers/tier-2-img.svg'
import tierThreeImg from 'images/index/about/tiers/tier-3-img.svg'
import tierFourImg from 'images/index/about/tiers/tier-4-img.svg'
import tierFiveImg from 'images/index/about/tiers/tier-5-img.svg'
import { ReactComponent as TierOneIcon } from 'images/index/about/algopad/tier-1-icon.svg'
import { ReactComponent as TierTwoIcon } from 'images/index/about/algopad/tier-2-icon.svg'
import { ReactComponent as TierThreeIcon } from 'images/index/about/algopad/tier-3-icon.svg'
import { ReactComponent as TierFourIcon } from 'images/index/about/algopad/tier-4-icon.svg'
import { ReactComponent as TierFiveIcon } from 'images/index/about/algopad/tier-5-icon.svg'

const tiersList = [
  {
    name: 'General',
    desc: 'information',
    odds: '',
    pool: '',
    img: '',
    rotate: -182,
    getIcon: () => <></>,
  },
  {
    name: 'Tier V',
    desc: 'Ensign',
    odds: '+1',
    pool: 'x1.0',
    rotate: -159,
    img: tierOneImg,
    getIcon: () => <TierOneIcon />,
  },
  {
    name: 'Tier IV',
    desc: 'Lieutenant',
    odds: '+25',
    pool: 'x1.3',
    rotate: -141,
    img: tierTwoImg,
    getIcon: () => <TierTwoIcon />,
  },
  {
    name: 'Tier III',
    desc: 'Vice Admiral',
    odds: '+125',
    pool: 'x1.7',
    rotate: -54,
    img: tierThreeImg,
    getIcon: () => <TierThreeIcon />,
  },
  {
    name: 'Tier II',
    desc: 'Flagship Commander',
    odds: '+400',
    pool: 'x2.2',
    rotate: -34,
    img: tierFourImg,
    getIcon: () => <TierFourIcon />,
  },
  {
    name: 'Tier I',
    desc: 'Fleet Commander',
    odds: 'MAX',
    pool: 'x3.0',
    rotate: -15,
    img: tierFiveImg,
    getIcon: () => <TierFiveIcon />,
  },
]

export default tiersList
