export const API_URL = process.env.REACT_APP_API_URL
export const API_BASIC_AUTH_LOGIN = process.env.REACT_APP_AUTH_LOGIN
export const API_BASIC_AUTH_PASS = process.env.REACT_APP_AUTH_PASS
export const DECK_LINK = 'https://docsend.com/view/erjxxs7i3w55ffrj'
export const FAQ_LINK = 'https://hackmd.io/@AlgoDAO/FAQ'
export const FAQ_SIGMA_LINK = 'https://docsend.com/view/u4g86wvk97tdtimk'
export const DISCORD = 'https://discord.gg/wt7UFeFhdQ'
export const MEDIUM = 'https://medium.com/algodao-fi'
export const TWITTER = 'https://twitter.com/algodao_fi'
export const TELEGRAM = 'https://t.me/AlgoDAOCommunity'
export const APPLICATION_FORM = 'https://algodao.typeform.com/to/e08uYi3q'
export const APPLY_FOR_IDO_LINK = 'https://forms.gle/dPAMijGvZBStHH7x8'
