import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import logo from 'images/index/contact/logo.svg'
import { Container } from 'theme/theme'
import BtnAnimData from 'lottie/button.json'
import Lottie from 'lottie-react'
import useWindowSize from 'helpers/utils/useWindowSize'
import { APPLY_FOR_IDO_LINK } from 'configs/app'

const Contact: React.FunctionComponent = () => {
  const lottieRef = useRef(null)
  const { width } = useWindowSize()

  const onEnter = () => {
    if (width > 900) {
      // @ts-ignore
      lottieRef.current.setDirection(1)
      // @ts-ignore
      lottieRef.current.play()
    }
  }

  const onLeave = () => {
    if (width > 900) {
      // @ts-ignore
      lottieRef.current.setDirection(-1)
      // @ts-ignore
      lottieRef.current.play()
    }
  }

  useEffect(() => {
    if (width > 900) {
      // @ts-ignore
      lottieRef.current.setDirection(-1)
      // @ts-ignore
      lottieRef.current.setSpeed(1)
      // @ts-ignore
      lottieRef.current.play()
    }
  }, [])

  return (
    <Root>
      <Container>
        <Cont>
          <Title>
            contact <span>us</span>
          </Title>
          <TextBlock>
            <GrayText>
              Backed by Algorand’s key ecosystem-shaping entities,{' '}
            </GrayText>
            <Text>
              AlgoDAO is opening its doors to new and aspiring projects
              launching on Algorand.
            </Text>
            <Logo />
          </TextBlock>
          <BtnText>Apply for a place in the Incubator now.</BtnText>
          <Btns>
            <ApplyBtn
              href={APPLY_FOR_IDO_LINK}
              target={'_blank'}
              onMouseLeave={onLeave}
              onMouseEnter={onEnter}
            >
              Apply
              <ApplyTextBg />
              <Lottie
                lottieRef={lottieRef}
                animationData={BtnAnimData}
                autoplay={false}
              />
            </ApplyBtn>
            {/*<OtherBtn>other inquiries</OtherBtn>*/}
          </Btns>
        </Cont>
      </Container>
    </Root>
  )
}

export default Contact

const Root = styled.div`
  position: relative;
  width: 100%;
  padding: 69px 0 536px;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 60px 0 466px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 51px 0 398px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 44px 0 398px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 32px 0 253px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 58px 0 479px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding: 55px 0 445px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding: 37px 0 388px;
  }
`

const Cont = styled.div`
  margin-left: 702px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-left: 618px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-left: 530px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-left: 452px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-left: 337px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin: 0 auto;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 68px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 28px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 51px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 44px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 41px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 38px;
    margin-bottom: 30px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 33px;
    margin-bottom: 25px;
  }
  span {
    color: #dc4c4c;
  }
`

const TextBlock = styled.div`
  position: relative;
  font-family: 'MMC';
  width: 895px;
  height: 187px;
  padding: 28px 35px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  margin-bottom: 99px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 783px;
    height: 164px;
    padding: 25px 31px;
    border-radius: 4px;
    margin-bottom: 87px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 670px;
    height: 140px;
    padding: 21px 26px;
    border-radius: 4px;
    margin-bottom: 75px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 577px;
    height: 121px;
    padding: 18px 23px;
    border-radius: 3px;
    margin-bottom: 64px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 428px;
    height: 89px;
    padding: 13px 17px;
    border-radius: 2px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 100%;
    height: 158px;
    padding: 24px 26px;
    border-radius: 3px;
    margin-bottom: 48px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    height: 211px;
    padding: 23px 20px;
    border-radius: 3px;
    margin-bottom: 42px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    height: 185px;
    padding: 19px 17px;
    border-radius: 3px;
    margin-bottom: 30px;
  }
`

const GrayText = styled.div`
  font-size: 20px;
  line-height: 135%;
  color: #ffffff;
  opacity: 0.5;
  margin-bottom: 7px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 10px;
  }
`

const Text = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 135%;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 19px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 21px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 17px;
  }
`

const Logo = styled.div`
  position: absolute;
  bottom: 17px;
  right: 27px;
  width: 108px;
  height: 29px;
  background: url('${logo}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    bottom: 14px;
    right: 24px;
    width: 94px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    bottom: 13px;
    right: 20px;
    width: 81px;
    height: 22px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    bottom: 11px;
    right: 18px;
    width: 69px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    bottom: 8px;
    right: 13px;
    width: 52px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    bottom: 15px;
    right: 25px;
    width: 69px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    right: 241px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    bottom: 11px;
    right: 205px;
    width: 59px;
    height: 16px;
  }
`

const BtnText = styled.div`
  font-family: 'Aldrich';
  font-size: 20px;
  line-height: 138%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 28px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 17px;
    margin-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 15px;
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 13px;
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 174px;
    margin: 0 auto 16px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin: 0 auto 15px;
  }
`

const Btns = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.index.sm} {
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const ApplyTextBg = styled.div`
  position: relative;
  width: 220px;
  height: 40px;
  margin: 0 auto;

  ${({ theme }) => theme.adaptive.index.xl} {
    width: 193px;
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 165px;
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 142px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 106px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 142px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 122px;
    height: 22px;
  }
`

const ApplyBtn = styled.a`
  position: relative;
  display: block;
  width: 333px;
  height: 58px;
  padding: 16px;
  border-radius: 7px;
  outline: none;
  border: 0;
  margin-right: 50px;
  z-index: 2;

  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 290px;
    height: 50px;
    padding: 14px;
    border-radius: 6px;
    margin-right: 45px;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 250px;
    height: 44px;
    padding: 11px;
    border-radius: 5px;
    margin-right: 37px;
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 215px;
    height: 38px;
    padding: 10px;
    border-radius: 5px;
    margin-right: 32px;
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 160px;
    height: 28px;
    padding: 8px;
    border-radius: 3px;
    margin-right: 24px;
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 245px;
    height: 42px;
    padding: 12px;
    border-radius: 5px;
    margin-right: 0;
    background: #ffffff;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 290px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 250px;
    height: 36px;
    padding: 9px;
    border-radius: 4px;
    font-size: 16px;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`

const OtherBtn = styled.button`
  position: relative;
  width: 333px;
  height: 58px;
  padding: 16px;
  border: 1px solid #dddddd;
  border-radius: 7px;
  outline: none;
  z-index: 2;
  overflow: hidden;

  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 290px;
    height: 50px;
    padding: 14px;
    border-radius: 6px;
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 250px;
    height: 44px;
    padding: 11px;
    font-size: 20px;
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 215px;
    height: 38px;
    padding: 10px;
    border-radius: 5px;
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 160px;
    height: 28px;
    padding: 8px;
    border-radius: 3px;
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 245px;
    height: 42px;
    padding: 12px;
    border-radius: 5px;
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 290px;
    margin-top: 23px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 250px;
    height: 36px;
    padding: 9px;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 19px;
  }
  &:after {
    content: '';
    position: absolute;
    height: 0%;
    left: 50%;
    top: 50%;
    width: 150%;
    z-index: -1;
    transition: all 0.4s ease 0s;
    background: #ffffff;
    opacity: 0.5;
    transform: translateY(-50%) translateX(-50%) rotate(90deg);
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 200%;
    }
  }

  &:hover:after {
    opacity: 1;
    height: 600% !important;
    ${({ theme }) => theme.adaptive.index.sm} {
      height: 800% !important;
    }
  }
  &:hover {
    color: #dc4c4c;
  }
`
