import React from 'react'
import styled from 'styled-components'
import starImg from 'images/rho/start/star.svg'
import partnersList from 'store/partnersRho'
import lineImg from 'images/rho/start/line.svg'
import bg from 'images/rho/start/bg.svg'
import starImgSm from 'images/rho/start/star-sm.svg'
import starImgSmm from 'images/rho/start/star-smm.svg'
import starImgXs from 'images/rho/start/star-xs.svg'
import bgSm from 'images/rho/start/bg-sm.svg'
import bgSmm from 'images/rho/start/bg-smm.svg'
import bgXs from 'images/rho/start/bg-xs.svg'

const Start: React.FunctionComponent = () => {
  return (
    <Root>
      <Cont>
        <Row left={true}>
          <TitleWrap>
            <Bg />
            <RedTitle>Ready to</RedTitle>
            <Title>START</Title>
          </TitleWrap>
          <SubTitle>AlgoDAO’s first RHO is underway</SubTitle>
        </Row>
        <Info>
          <InfoTitle>Watch Out for AlgoDAO’s Hackathon event</InfoTitle>
          <InfoColumn>
            <Text isLarge={true}>
              AlgoDAO’s first RHO is happening with the full support of our
              ecosystem partners including; Milkomeda, BLUESHIFT, EY Parthenon,
              and many others.
            </Text>
          </InfoColumn>
          <InfoColumn>
            <Text>
              RHO_1 brings together the brightest minds in Web3 to collaborate
              and build some of the most innovative products to emerge from
              Algorand.{' '}
            </Text>
          </InfoColumn>
          <InfoColumn>
            <Text>
              Our aim is to make AlgoDAO’s RHO events become some of the biggest
              programs in Algorand’s annual calendar.{' '}
            </Text>
          </InfoColumn>
        </Info>
        <Row>
          <InfoColumn>
            <ColumnTitle>Where</ColumnTitle>
            <Text>Online and Live in Amsterdam.</Text>
            <Text>A month-long collaborative process.</Text>
            <Text>
              A three-day in-person hackathon that ends in a big Demo Day and
              celebratory dinner and party.
            </Text>
          </InfoColumn>
          <InfoColumn>
            <ColumnTitle>When</ColumnTitle>
            <Text>Autumn 2022</Text>
          </InfoColumn>
          <InfoColumn>
            <ColumnTitle>Venue</ColumnTitle>
            <Text>EY Wavespace Amsterdam</Text>
            <Text>
              A multi-purpose venue for design, tech demos, data labs,
              incubation, and workshops. The location is easily reachable and
              facility support is on standby.
            </Text>
            <Text>
              AlgoDAO’s RHOs give you a chance to potentially score
              significantly higher returns than IDO participants.
            </Text>
          </InfoColumn>
        </Row>
        <Partners>
          <PartnersTitle>Ecosystem Partners</PartnersTitle>
          <List>
            {partnersList.map((item, idx) => {
              return (
                <Item href={item.link} target={'_blank'} key={idx}>
                  <ItemImg src={item.img} />
                </Item>
              )
            })}
          </List>
        </Partners>
      </Cont>
    </Root>
  )
}

export default Start

const Root = styled.div`
  position: relative;
  width: 100%;
  margin-top: 148px;
  padding-bottom: 139px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 129px;
    padding-bottom: 122px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 133px;
    padding-bottom: 104px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 118px;
    padding-bottom: 90px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 72px;
    padding-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 26px;
    padding: 0 0 55px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-top: 263px;
    padding: 0 0 42px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-top: 256px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 52px;
    left: 0;
    background: #fafafa;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: 52px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: 40px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: 33px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: 25px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: 80px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      content: '';
      bottom: 0;
      top: unset;
      width: 100%;
      height: 100px;
    }
  }
`

const Cont = styled.div`
  width: 1613px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1412px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1210px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1099px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 812px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 530px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 335px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const TitleWrap = styled.div``

const RedTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #dc4c4c;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 82px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-top: -1px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 72px;
    margin-top: -3px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 62px;
    margin-top: -2px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 54px;
    margin-top: -3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 40px;
    margin-top: -3px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -185px;
    top: -4px;
    width: 519px;
    height: 492px;
    background: url('${starImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      left: -162px;
      top: -4px;
      width: 454px;
      height: 431px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      left: -139px;
      top: -3px;
      width: 389px;
      height: 369px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      left: -119px;
      top: -3px;
      width: 335px;
      height: 318px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      left: -89px;
      top: -2px;
      width: 249px;
      height: 236px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      left: -2px;
      top: -3px;
      width: 169px;
      height: 326px;
      background: url('${starImgSm}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      left: -19px;
      top: -3px;
      width: 169px;
      height: 396px;
      background: url('${starImgSmm}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      left: 0px;
      top: -6px;
      width: 171px;
      height: 395px;
      background: url('${starImgXs}') center no-repeat;
      background-size: cover;
    }
  }
`

const Bg = styled.div`
  position: absolute;
  top: -568px;
  left: -2061px;
  width: 3894px;
  height: 1002px;
  background: url('${bg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    top: -497px;
    left: -1804px;
    width: 3409px;
    height: 877px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    top: -426px;
    left: -1546px;
    width: 2921px;
    height: 752px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    top: -367px;
    left: -1331px;
    width: 2515px;
    height: 647px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    top: -272px;
    left: -987px;
    width: 1865px;
    height: 480px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    top: -220px;
    left: -856px;
    width: 1865px;
    height: 1005px;
    background: url('${bgSm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    top: -216px;
    left: -786px;
    width: 1865px;
    height: 1193px;
    background: url('${bgSmm}') center no-repeat;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    top: -205px;
    left: -794px;
    width: 1865px;
    height: 1221px;
    background: url('${bgXs}') center no-repeat;
    background-size: cover;
  }
`

const SubTitle = styled.div`
  position: relative;
  width: 412px;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(29, 30, 31, 0.3);
  margin-left: 132px;
  margin-top: 23px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 361px;
    font-size: 32px;
    margin-left: 115px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 309px;
    font-size: 28px;
    margin-left: 97px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 266px;
    font-size: 24px;
    margin-left: 82px;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 197px;
    font-size: 18px;
    margin-left: 60px;
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 138px;
    margin-left: 48px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 114px;
    font-size: 16px;
    margin-left: 38px;
    margin-top: 6px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: -37px;
    left: 0;
    width: 89px;
    height: 3px;
    background: url('${lineImg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 78px;
      height: 3px;
      bottom: -24px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 67px;
      height: 2px;
      bottom: -24px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 57px;
      height: 2px;
      bottom: -20px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 43px;
      height: 1px;
      bottom: -18px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      bottom: unset;
      top: -13px;
    }
  }
`

const Info = styled.div`
  margin-top: 129px;
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 113px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 96px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 69px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 46px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-left: 35px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-left: 18px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-left: 24px;
    margin-top: 29px;
  }
`

const InfoTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-bottom: 22px;
  width: 100%;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 32px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 28px;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 24px;
    margin-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 18px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 18px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 16px;
  }
`

const InfoColumn = styled.div`
  margin-right: 94px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 67px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 58px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 50px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 0;
  }
  &:nth-child(2) {
    ${({ theme }) => theme.adaptive.index.xl} {
      margin-right: 79px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      margin-right: 71px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      margin-right: 61px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      margin-right: 65px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      margin-right: 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`

const Text = styled.div<{ isLarge?: boolean }>`
  position: relative;
  width: 460px;
  font-family: 'MMC';
  font-weight: 400;
  font-size: 18px;
  line-height: 132.3%;
  color: #292b33;
  margin-left: 15px;
  margin-top: 20px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: ${({ isLarge }) => (isLarge ? '418px' : '403px')};
    margin-left: 14px;
    margin-top: 18px;
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: ${({ isLarge }) => (isLarge ? '358px' : '345px')};
    margin-left: 11px;
    margin-top: 16px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: ${({ isLarge }) => (isLarge ? '308px' : '297px')};
    margin-left: 10px;
    margin-top: 14px;
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: ${({ isLarge }) => (isLarge ? '236px' : '220px')};
    margin-left: 7px;
    margin-top: 10px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 477px;
    margin-left: 8px;
    margin-bottom: 16px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 289px;
    margin-left: 7px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 235px;
    margin-bottom: 14px;
  }
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 4px;
    height: 6px;
    background: #dc404d;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: 7px;
      left: -14px;
      width: 4px;
      height: 5px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: 9px;
      left: -11px;
      width: 3px;
      height: 5px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: 7px;
      left: -7px;
      width: 2px;
      height: 3px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      left: -8px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      left: -7px;
    }
  }
`

const ColumnTitle = styled.div`
  position: relative;
  margin-top: 180px;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 158px;
    font-size: 32px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 132px;
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 90px;
    font-size: 24px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 64px;
    font-size: 18px;
  }
`

const Row = styled.div<{ left?: boolean }>`
  position: relative;
  display: flex;
  margin-left: ${({ left }) => (left ? '92px' : '0')};
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-left: ${({ left }) => (left ? '81px' : '0')};
    ${InfoColumn} {
      &:nth-child(1) {
        margin-right: 79px;
        ${Text} {
          width: 418px;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-left: ${({ left }) => (left ? '71px' : '0')};
    ${InfoColumn} {
      &:nth-child(1) {
        margin-right: 71px;
        ${Text} {
          width: 358px;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-left: ${({ left }) => (left ? '61px' : '0')};
    ${InfoColumn} {
      &:nth-child(1) {
        margin-right: 61px;
        ${Text} {
          width: 287px;
        }
      }
      &:nth-child(2) {
        margin-right: 196px;
        ${Text} {
          width: 112px;
        }
      }
      &:nth-child(3) {
        ${Text} {
          width: 404px;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-left: ${({ left }) => (left ? '54px' : '0')};
    ${InfoColumn} {
      &:nth-child(1) {
        margin-right: 32px;
        ${Text} {
          width: 281px;
        }
      }
      &:nth-child(2) {
        margin-right: 74px;
        ${Text} {
          width: 84px;
        }
      }
      &:nth-child(3) {
        ${Text} {
          width: 320px;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-left: 0;
    flex-wrap: wrap;
    ${InfoColumn} {
      &:nth-child(1) {
        margin-right: 43px;
        ${Text} {
          width: 281px;
        }
      }
      &:nth-child(2) {
        margin-right: 0;
        ${Text} {
          width: 84px;
        }
      }
      &:nth-child(3) {
        margin-top: 24px;
        ${ColumnTitle} {
          margin-top: 0;
        }
        ${Text} {
          width: 512px;
        }
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-left: 20px;
    ${InfoColumn} {
      ${Text} {
        width: 328px !important;
      }
      ${ColumnTitle} {
        margin-top: 0 !important;
      }
      &:nth-child(1) {
        margin-top: 71px !important;
        margin-right: 0 !important;
      }
      &:nth-child(2) {
        margin-right: 0;
        margin-top: 26px !important;
      }
      &:nth-child(3) {
        margin-top: 30px !important;
      }
    }
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-left: 0;
    ${InfoColumn} {
      ${Text} {
        width: 273px !important;
      }
      &:nth-child(1) {
        margin-top: 67px !important;
      }
    }
  }
`

const Partners = styled.div`
  position: relative;
  margin-top: 46px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 7px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-top: 29px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-top: 33px;
  }
`

const PartnersTitle = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 32px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 28px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 24px;
    margin-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 18px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 26px;
  }
`

const List = styled.div`
  display: flex;
  align-items: flex-end;
  ${({ theme }) => theme.adaptive.index.smm} {
    flex-wrap: wrap;
  }
`

const Item = styled.a`
  display: block;
  width: 317px;
  height: 45px;
  margin-right: 75px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 278px;
    height: 40px;
    margin-right: 66px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 238px;
    height: 34px;
    margin-right: 56px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 205px;
    height: 30px;
    margin-right: 48px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 152px;
    height: 22px;
    margin-right: 36px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 122px;
    height: 18px;
    margin-right: 29px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-right: 18px;
  }
  &:nth-child(3) {
    width: 242px;
    height: 78px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 212px;
      height: 68px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 181px;
      height: 59px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 156px;
      height: 50px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 116px;
      height: 37px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 93px;
      height: 30px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      margin-bottom: 0;
    }
  }
  &:nth-child(4) {
    width: 251px;
    height: 47px;
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 219px;
      height: 41px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 188px;
      height: 35px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 116px;
      height: 30px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 120px;
      height: 23px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 96px;
      height: 18px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      margin-bottom: 0;
    }
  }
`

const ItemImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`
