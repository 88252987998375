//core
//import defaultPhoto from 'images/index/team/default.svg'
import doryPhoto from 'images/index/team/core/dory.jpg'
import anthonyPhoto from 'images/index/team/core/anthony.jpg'
//dao
import navroopPhoto from 'images/index/team/dao/navroop-photo.jpg'
import johnCollinsPhoto from 'images/index/team/dao/john-collins-photo.png'

//ambassadors
import gonemultichainPhoto from 'images/index/team/Ambassadors/gonemultichain.jpg'
import heisgeroPhoto from 'images/index/team/Ambassadors/heisgero.png'

interface personProps {
  img?: string
  name: string
  role?: string
  link?: string
  telegram?: string
  telegramLink?: string
}

export interface teamProps {
  [key: string]: personProps[]
}

const teamList: teamProps = {
  Core: [
    {
      img: anthonyPhoto,
      name: 'Anthony Dunne',
      role: 'Social Media Manager',
      link: '',
    },
    {
      img: doryPhoto,
      name: 'Dory',
      role: 'Operations Manager ',
      link: '',
    },
  ],
  Dao: [
    {
      img: navroopPhoto,
      name: 'Navroop Sahdev',
      role: 'Tokenomics',
      link: 'https://www.linkedin.com/in/navroopsahdev/',
    },
    {
      img: johnCollinsPhoto,
      name: 'John Collins',
      role: '',
      link: 'https://www.linkedin.com/in/johngcollins/',
    },
  ],
  Ambassadors: [
    {
      img: heisgeroPhoto,
      name: 'Heisgero',
      telegram: '@heisgero',
      telegramLink: 'https://t.me/heisgero',
    },
    {
      img: gonemultichainPhoto,
      name: 'gonemultichain',
      telegram: '@gonemultichain',
      telegramLink: 'https://t.me/gonemultichain',
    },
  ],
}

export default teamList
