import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Logo from '../Icons/Logo'
import ContactBtn from './BuyBtn'
import burgerImg from 'images/header/burger.svg'
import gsap, { Linear } from 'gsap'
import useWindowSize from '../../helpers/utils/useWindowSize'
import MobileMenu from './MobileMenu'
import { Link } from 'react-router-dom'
import { FAQ_SIGMA_LINK } from '../../configs/app'
import Navigation from './Navigation'
import { drawCanvas, FadeIn } from '../Index/Main/animations'
gsap.config({ force3D: false })

interface modalProps {
  onClick: () => void
  isMain: boolean
}

const Header: React.FunctionComponent<modalProps> = (props) => {
  const [resizeTimeout, setReszieTimeout] = useState<any>(null)
  const [isMenu, setIsMenu] = useState(false)
  const ItemRef = useRef([])
  ItemRef.current = []
  const { width } = useWindowSize()

  const addToItemRefs = (el: any) => {
    // @ts-ignore
    if (el && !ItemRef.current.includes(el)) {
      // @ts-ignore
      ItemRef.current.push(el)
    }
  }

  const onClose = () => {
    setIsMenu(false)
  }

  const FadeIn = (elem: gsap.TweenTarget, delay: number, yVal: number) => {
    gsap.fromTo(
      elem,
      { y: yVal, opacity: 0 },
      {
        delay: delay,
        y: 0,
        opacity: 1,
        duration: 0.4,
        ease: Linear.easeNone,
      }
    )
  }

  useEffect(() => {
    if (width > 900) {
      const handleResize = () => {
        clearTimeout(resizeTimeout)
        setReszieTimeout(
          setTimeout(() => {
            if (ItemRef.current) {
              let delay = 0.9
              ItemRef.current.forEach((el) => {
                delay += 0.1

                FadeIn(el, delay, -50)
              })
            }
          }, 300)
        )
      }
      handleResize()
    }
  }, [width])

  return (
    <Root>
      <Line>
        <LogoContainer ref={addToItemRefs}>
          <Link to={'/'}>
            <Logo />
          </Link>
        </LogoContainer>
        {width > 900 && (
          <Navigation isMain={props.isMain} onBtnClick={props.onClick} />
        )}
        {width <= 560 && (
          <LogoText>
            <Link to={'/'}>Algorithms of the Future</Link>
          </LogoText>
        )}
        {width <= 900 && <Burger onClick={() => setIsMenu(true)} />}
        {width <= 900 && (
          <MobileMenu
            onContact={props.onClick}
            onClick={onClose}
            open={isMenu}
            addRef={addToItemRefs}
            isMain={props.isMain}
          />
        )}
      </Line>
      {width > 560 && (
        <LogoText ref={addToItemRefs}>
          <span>Algorithms of the Future</span>
        </LogoText>
      )}
    </Root>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  padding: 64px 80px 0;
  z-index: 1;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 56px 70px 0;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding: 48px 60px 0;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 41px 50px 0;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding: 31px 40px 0;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 520px;
    margin: 0 auto;
    padding: 32px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 330px;
    padding: 28px 0 0;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
    padding: 23px 0 0;
  }
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LogoContainer = styled.div`
  opacity: 0;
  will-change: transform;
  a {
    display: block;
    width: 294px;
    height: 29px;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
    ${({ theme }) => theme.adaptive.index.xl} {
      width: 257px;
      height: 25px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      width: 221px;
      height: 22px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      width: 190px;
      height: 19px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      width: 141px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      width: 115px;
      height: 12px;
      opacity: 1;
    }
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    display: none;
  }
`

const LogoText = styled.div`
  opacity: 0;
  ${({ theme }) => theme.adaptive.index.sm} {
    opacity: 1;
  }
  span {
    display: block;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #ffffff;
    margin-top: 10px;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 14px;
      line-height: 17px;
      margin-top: 8px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 12px;
      line-height: 14px;
      margin-top: 0px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 11px;
      line-height: 13px;
      margin-top: 3px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 9px;
      line-height: 11px;
      margin-top: -3px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      font-size: 10px;
      line-height: 12px;
      margin-top: 4px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      font-size: 12px;
      line-height: 14px;
      margin-top: 0;
      max-width: 74px;
    }
  }
`

const Burger = styled.div`
  width: 22px;
  height: 14px;
  background: url('${burgerImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 29px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 26px;
    height: 18px;
  }
`
