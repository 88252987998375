import React from 'react'

interface Props {
  active?: boolean
}

const DashedLine: React.FunctionComponent<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
    >
      <circle
        opacity={props.active ? '1' : '0.4'}
        cx="28.5"
        cy="28.5"
        r="28"
        stroke={props.active ? 'url(#paint0_linear_739_574)' : '#C0C0C0'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_739_574"
          x1="57"
          y1="26"
          x2="-8"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7544F" />
          <stop offset="1" stopColor="#E2E2E2" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DashedLine
