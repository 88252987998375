import React from 'react'
import styled from 'styled-components'
import Header from '../Header'

interface Props {
  children: React.ReactNode
}

export default function PageWrapper(props: Props) {
  return (
    <Wrapper>
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  background: #272a32;
`

const Content = styled.div`
  width: 100%;
`
