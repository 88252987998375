interface colorProps {
  color: string
  breakpoint: number
}

export interface gradientListProps {
  radial?: boolean
  coordinates: {
    x0: number
    y0: number
    r0?: number
    x1: number
    y1: number
    r1?: number
  }
  colors: colorProps[]
}

const gradientList: gradientListProps | any = [
  {
    coordinates: {
      x0: 1477.7,
      y0: 33.2622,
      x1: 1480.92,
      y1: 211.938,
    },
    colors: [
      {
        color: '#F382A4',
        breakpoint: 0,
      },
      {
        color: 'rgba(249, 165, 118, 0.21)',
        breakpoint: 0.408031,
      },
      {
        color: 'rgba(249, 165, 118, 0.01)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 0,
      y0: 0,
      r0: 0,
      x1: 0,
      y1: 0,
      r1: 1,
    },
    radial: true,
    colors: [
      {
        color: '#F382A4',
        breakpoint: 0,
      },
      {
        color: 'rgba(249, 165, 118, 0.21)',
        breakpoint: 0.408031,
      },
      {
        color: 'rgba(249, 165, 118, 0.01)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1435.33,
      y0: 19.6633,
      x1: 1441.5,
      y1: 197.656,
    },
    colors: [
      {
        color: '#F382A4',
        breakpoint: 0,
      },
      {
        color: 'rgba(249, 165, 118, 0.21)',
        breakpoint: 0.408031,
      },
      {
        color: 'rgba(249, 165, 118, 0.01)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1519.68,
      y0: 18.0731,
      x1: 1521.7,
      y1: 195.731,
    },
    colors: [
      {
        color: '#ffffff',
        breakpoint: 0,
      },
      {
        color: 'rgba(249, 165, 118, 0.21)',
        breakpoint: 0.408031,
      },
      {
        color: 'rgba(249, 165, 118, 0.01)',
        breakpoint: 1,
      },
    ],
    breakpoints: [0, 0.408031, 1],
  },
  {
    coordinates: {
      x0: 1470.84,
      y0: 69.5964,
      x1: 1211.07,
      y1: 692.773,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.251326,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1324.25,
      y0: 383.018,
      x1: 1290.16,
      y1: 441.723,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.48081,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1148.77,
      y0: 568.175,
      x1: 1105.81,
      y1: 620.734,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.48081,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1483.84,
      y0: 69.5964,
      x1: 1743.6,
      y1: 692.773,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.251326,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1500.84,
      y0: 74.5358,
      x1: 1517,
      y1: 150.461,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.251326,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1415.62,
      y0: 153.985,
      x1: 1397.82,
      y1: 229.545,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.251326,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1594.53,
      y0: 268.701,
      x1: 1610.87,
      y1: 314.838,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.251326,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1759.83,
      y0: 508.5,
      x1: 1806.01,
      y1: 573.76,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.337869,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
  {
    coordinates: {
      x0: 1429.03,
      y0: 261.702,
      x1: 1420.97,
      y1: 305.229,
    },
    colors: [
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 0,
      },
      {
        color: '#D45151',
        breakpoint: 0.337869,
      },
      {
        color: 'rgba(212, 81, 81, 0)',
        breakpoint: 1,
      },
    ],
  },
]

export default gradientList
