import React from 'react'

interface VolumeProps {
  value: number
}

const Volume: React.FunctionComponent<VolumeProps> = (props) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.26966 9.21692H2.9818L6.63081 11.7398C6.72653 11.8057 6.83766 11.8436 6.95243 11.8494C7.0672 11.8552 7.18134 11.8287 7.28279 11.7727C7.38411 11.7165 7.4688 11.6328 7.52784 11.5305C7.58687 11.4282 7.61802 11.3112 7.61798 11.1921V0.658036C7.61792 0.538946 7.58671 0.422103 7.52769 0.319951C7.46866 0.217799 7.38402 0.134164 7.28279 0.0779532C7.18155 0.0217426 7.06751 -0.0049383 6.95281 0.000752174C6.83811 0.00644264 6.72705 0.0442913 6.63145 0.110267L2.9818 2.63316H1.26966C0.569444 2.63316 0 3.22373 0 3.94992V7.90017C0 8.62636 0.569444 9.21692 1.26966 9.21692ZM1.26966 3.94992H3.17416C3.19511 3.94992 3.21288 3.93938 3.2332 3.93741C3.31742 3.92839 3.39914 3.90245 3.4738 3.86104C3.4903 3.85182 3.50998 3.84984 3.52585 3.83931L6.34831 1.88788V9.9622L3.52649 8.01078C3.51062 7.99893 3.49094 7.99761 3.47443 7.98905C3.39953 7.94656 3.31697 7.92056 3.23193 7.91268C3.21161 7.91071 3.19447 7.90017 3.17416 7.90017H1.26966V3.94992Z"
        fill="#3C404A"
      />
      {props.value > 25 && (
        <path
          d="M10.792 9.52248V2.32772"
          stroke="#3C404A"
          stroke-width="0.634831"
        />
      )}

      <line
        y1="-0.317416"
        x2="3.80899"
        y2="-0.317416"
        transform="matrix(0 -1 -1 0 8.8877 7.82959)"
        stroke="#3C404A"
        stroke-width="0.634831"
      />
      {props.value > 50 && (
        <path
          d="M12.2734 10.3689V1.48127"
          stroke="#3C404A"
          stroke-width="0.634831"
        />
      )}
    </svg>
  )
}

export default Volume
