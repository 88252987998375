import gsap, { Linear, Power4 } from 'gsap'
gsap.config({ force3D: false })

export const FadeIn = (elem: gsap.DOMTarget, delay: number, yVal: number) => {
  gsap.fromTo(
    elem,
    { y: yVal, opacity: 0 },
    {
      delay: delay,
      y: 0,
      opacity: 1,
      duration: 0.4,
      ease: Linear.easeNone,
    }
  )
}

export const ScaleAnim = (
  elem: gsap.DOMTarget,
  delay: number,
  opacity: number
) => {
  gsap.fromTo(
    elem,
    { width: 0, height: 0, opacity: 0 },
    {
      delay: delay,
      width: '100%',
      height: '100%',
      opacity: opacity,
      duration: 1,
      ease: Power4.easeOut,
    }
  )
}

export const RotateAnim = (
  elem: gsap.DOMTarget,
  delay: number,
  rotate: number,
  left: boolean
) => {
  gsap.to(elem, {
    delay: delay,
    rotation: left ? `${rotate}_ccw` : `${rotate}_cw`,
    duration: 0.9,
    ease: Power4.easeOut,
  })
}

export const RotateAlgoPadBg = (elem: gsap.DOMTarget, rotate: number) => {
  gsap.fromTo(
    elem,
    { opacity: 0 },
    {
      delay: 0,
      opacity: 1,
      rotation: rotate,
      duration: 0.9,
      ease: Power4.easeOut,
    }
  )
}

export const Draw = (elem: gsap.DOMTarget) => {
  gsap.fromTo(
    elem,
    { strokeDasharray: 1000, strokeDashoffset: 1000 },
    {
      scrollTrigger: {
        trigger: elem,
        start: 'top bottom',
        scrub: true,
      },
      delay: 0,
      duration: 0.4,
      strokeDashoffset: 0,
      ease: Linear.easeNone,
    }
  )
}

export const FadeOutTier = (elem: gsap.DOMTarget, onComplete: any) => {
  gsap.fromTo(
    elem,
    { opacity: 1 },
    {
      opacity: 0,
      duration: 0.4,
      ease: Linear.easeNone,
      onComplete: onComplete,
    }
  )
}

export const FadeInTier = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    opacity: 1,
    duration: 0.4,
    ease: Linear.easeNone,
  })
}

export const FadeInStep = (elem: gsap.DOMTarget) => {
  gsap.to(elem, {
    y: 0,
    opacity: 1,
    duration: 0.4,
    ease: Linear.easeNone,
  })
}
