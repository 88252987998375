import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/theme'
import stars from 'images/rho/next/stars.svg'
import star from 'images/rho/next/star.svg'

const Next: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        <Cont>
          <TitleWrap>
            <Title>What Next?</Title>
            <SubTitle>Buy into the very first RHO pool on AlgoDAO </SubTitle>
          </TitleWrap>
          <Border />
          <Row>
            <ButtonWrap>
              <Button
                href={'https://app.algodao.fi/project/830309475'}
                target={'_blank'}
                isDisable={false}
              >
                Buy RHO-1 Tokens
              </Button>
              <Text
                href={
                  'https://medium.com/algodao-fi/mechanics-of-algodaos-rho-how-exactly-does-it-work-913313329eb5'
                }
                target={'_blank'}
              >
                How Do RHOs Work?
              </Text>
            </ButtonWrap>
            <Price>
              <Value>$0.15</Value>
              <PriceText>RHO Token Price</PriceText>
            </Price>

            <Price>
              <Value>$75,000</Value>
              <PriceText>RHO SOFT CAP</PriceText>
            </Price>
          </Row>
          <Row>
            <Price>
              <Value>$150,000</Value>
              <PriceText>RHO Pool Size</PriceText>
            </Price>
          </Row>
        </Cont>
      </Container>
    </Root>
  )
}

export default Next

const Root = styled.div`
  position: relative;
  width: 100%;
  padding-top: 205px;
  padding-bottom: 336px;
  background: #272a32;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-top: 179px;
    padding-bottom: 291px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-top: 154px;
    padding-bottom: 248px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-top: 130px;
    padding-bottom: 212px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-top: 90px;
    padding-bottom: 159px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-top: 73px;
    padding-bottom: 426px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    padding-top: 66px;
    padding-bottom: 411px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-top: 43px;
    padding-bottom: 351px;
  }
  ${Container} {
    display: flex;
    justify-content: flex-end;
  }
`

const Cont = styled.div``

const TitleWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.index.smm} {
    display: block;
  }
`

const Title = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 82px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 39px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 72px;
    margin-right: 32px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 62px;
    margin-right: 35px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 54px;
    margin-right: 30px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 40px;
    margin-right: 22px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 15px;
  }

  &:before {
    content: '';
    position: absolute;
    top: -82px;
    left: -226px;
    width: 182px;
    height: 203px;
    background: url('${stars}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -71px;
      left: -176px;
      width: 159px;
      height: 178px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -62px;
      left: -151px;
      width: 137px;
      height: 152px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -53px;
      left: -143px;
      width: 118px;
      height: 131px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -40px;
      left: -82px;
      width: 87px;
      height: 97px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: -66px;
      left: -40px;
    }
    ${({ theme }) => theme.adaptive.index.smm} {
      left: -37px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      content: unset;
    }
  }
`

const SubTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #5c5e63;
  width: 567px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 32px;
    width: 496px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 28px;
    width: 410px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 24px;
    width: 353px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 18px;
    width: 262px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 235px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    text-align: center;
  }
  &:before {
    content: '';
    position: absolute;
    top: -459px;
    right: -441px;
    width: 740px;
    height: 740px;
    background: url('${star}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -402px;
      right: -390px;
      width: 648px;
      height: 648px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -345px;
      right: -330px;
      width: 555px;
      height: 555px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -297px;
      right: -284px;
      width: 478px;
      height: 478px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -220px;
      right: -225px;
      width: 354px;
      height: 354px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      content: unset;
    }
  }
`

const Border = styled.div`
  width: 1128px;
  height: 2px;
  background: #d9d9d9;
  opacity: 0.1;
  margin-top: 24px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 987px;
    height: 2px;
    margin-top: 21px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 846px;
    height: 1px;
    margin-top: 18px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 728px;
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 540px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 520px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    margin-top: 15px;
  }
`

const ButtonWrap = styled.div`
  position: relative;
  width: 429px;
  margin-right: 75px;
  align-items: center;
  z-index: 2;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 375px;
    margin-right: 66px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 322px;
    margin-right: 56px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 277px;
    margin-right: 48px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 205px;
    margin-right: 28px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
`

const Button = styled.a<{ isDisable: boolean }>`
  position: relative;
  display: block;
  width: 429px;
  height: 56px;
  padding: 20px 0 16px;
  background: linear-gradient(
    90.32deg,
    #cf4858 -23.2%,
    #dc5a4c 52.6%,
    #cf4858 104.91%
  );
  box-shadow:
    0px 12px 30px -9px rgba(219, 84, 78, 0.28),
    0px 17px 13px -16px rgba(219, 84, 78, 0.51);
  border-radius: 5px;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.4s;
  cursor: ${({ isDisable }) => (isDisable ? 'no-drop' : 'pointer')};
  opacity: ${({ isDisable }) => (isDisable ? '0.6' : '1')};
  &:hover {
    ${({ isDisable }) => {
      if (!isDisable) {
        return css`
          background: #ffffff;
          color: #d65251;
        `
      }
    }}
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 375px;
    height: 49px;
    padding: 18px 0 14px;
    font-size: 18px;
    box-shadow:
      0px 10.5053px 26.2634px -7.87901px rgba(219, 84, 78, 0.28),
      0px 14.8826px 11.3808px -14.0071px rgba(219, 84, 78, 0.51);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 322px;
    height: 42px;
    padding: 15px 0 11px;
    font-size: 16px;
    box-shadow:
      0px 9.00051px 22.5013px -6.75038px rgba(219, 84, 78, 0.28),
      0px 12.7507px 9.75055px -12.0007px rgba(219, 84, 78, 0.51);
    border-radius: 4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 277px;
    height: 36px;
    padding: 13px 0 7px;
    font-size: 16px;
    box-shadow:
      0px 7.74891px 19.3723px -5.81168px rgba(219, 84, 78, 0.28),
      0px 10.9776px 8.39465px -10.3319px rgba(219, 84, 78, 0.51);
    border-radius: 3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 205px;
    height: 31px;
    padding: 10px 0 7px;
    font-size: 14px;
    box-shadow:
      0px 5.74812px 14.3703px -4.31109px rgba(219, 84, 78, 0.28),
      0px 8.14317px 6.22713px -7.66416px rgba(219, 84, 78, 0.51);
    border-radius: 2px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    width: 100%;
    height: 28px;
    padding: 9px 0 7px;
    font-size: 12px;
    box-shadow:
      0px 5.34141px 13.3535px -4.00605px rgba(219, 84, 78, 0.28),
      0px 7.56699px 5.78652px -7.12187px rgba(219, 84, 78, 0.51);
    border-radius: 2px;
  }
`

const Text = styled.a`
  display: block;
  width: 100%;
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #dc4c4c;
  margin-top: 25px;
  transition: 0.4s;
  &:hover {
    color: #fff;
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    margin-top: 22px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    margin-top: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-top: 9px;
  }
`

const Value = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 68px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #dc4c4c;
  margin-bottom: 5px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 60px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 52px;
    margin-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 45px;
    margin-bottom: 1px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 34px;
    margin-bottom: 0px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 30px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 26px;
  }
`

const PriceText = styled.div`
  font-family: 'Aldrich';
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  color: #8b8b8d;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
  }
`

const Price = styled.div`
  margin-right: 75px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 66px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 55px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 47px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 36px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 25px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-right: 15px;
  }
  &:last-child {
    margin: 0;
    ${Value} {
      color: #8b8b8d;
    }
  }
`

const Row = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 48px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 42px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 36px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 21px;
  }
  ${({ theme }) => theme.adaptive.index.smm} {
    flex-wrap: wrap;
    margin-top: 15px;
  }
  &:last-child {
    justify-content: flex-end;
  }
`
