import React from 'react'

interface Props {
  active?: boolean
}

const DashedLine: React.FunctionComponent<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="291"
      height="16"
      viewBox="0 0 291 16"
      fill="none"
    >
      <path
        opacity={props.active ? '1' : '0.3'}
        d="M291 8.00051L0 8.00049"
        stroke={props.active ? 'url(#paint0_linear_739_577)' : '#C0C0C0'}
        strokeWidth="16"
        strokeDasharray="1 3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_739_577"
          x1="291"
          y1="8.45665"
          x2="-40.8421"
          y2="8.4581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D7544F" />
          <stop offset="1" stopColor="#E2E2E2" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DashedLine
