import React from 'react'
import styled from 'styled-components'

const BuyBtn: React.FunctionComponent = () => {
  return (
    <Button
      href={
        'https://app.pact.fi/swap?pair=ALGO:0/ALGODAO+TOKEN:692085161(30,CONST)'
      }
      target={'_blank'}
    >
      BUy ADAO
    </Button>
  )
}

export default BuyBtn

const Button = styled.a`
  position: relative;
  display: block;
  padding: 11px;
  width: 200px;
  height: 42px;
  outline: none;
  background: #d65251;
  color: #fff;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-left: 34px;
  &:hover {
    background: #ffffff;
    color: #d65251;
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 9px;
    width: 182px;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
    margin-left: 31px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 8px;
    width: 161px;
    height: 34px;
    border-radius: 4px;
    font-size: 13px;
    margin-left: 27px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 151px;
    height: 32px;
    border-radius: 4px;
    font-size: 12px;
    margin-left: 26px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    color: #fff;
    padding: 13px;
    width: 260px;
    height: 46px;
    border-radius: 7px;
    font-size: 16px;
    box-shadow: 0px 9px 15px rgba(220, 90, 76, 0.15);
    margin: 20px auto 0;
  }
`
