import React from 'react'
import { BrowserRouter as RouterReact, Routes, Route } from 'react-router-dom'
import Index from 'components/Index'
import StartHamster from 'components/StartHamster'
import Secret from 'components/Secret'
import image2 from 'images/qr/image2.png'
import image3 from 'images/qr/image3.png'
import image4 from 'images/qr/image4.png'
import Rho from 'components/Rho'

export default function Router() {
  return (
    <RouterReact>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/rho" element={<Rho />} />
        <Route path="/20bcd90dff93ea5e912bd667e1778642" element={<Secret />} />
        <Route
          path="/16f220a5512b38b2043ede4765227ad6"
          element={<StartHamster />}
        />
        <Route
          path="/910955A907E739B81EC8855763108A29"
          element={<img width="100%" src={image2} alt="Secret" />}
        />
        <Route
          path="/C5012903A0A3DECBC28F0209778D535A"
          element={<img width="100%" src={image3} alt="Secret" />}
        />
        <Route
          path="/D861877DA56B8B4CEB35C8CBFDF65BB4"
          element={<img width="100%" src={image4} alt="Secret" />}
        />
      </Routes>
    </RouterReact>
  )
}
