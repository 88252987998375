import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import AudioPlayer from 'react-h5-audio-player'
import music from 'audios/music.mp3'
import AudioMotionAnalyzer from 'audiomotion-analyzer'
import { ReactComponent as PlayBtn } from 'images/starHamster/play.svg'
import { ReactComponent as PauseBtn } from 'images/starHamster/pause.svg'

interface playerProps {
  animStarted: boolean
}

const Player: React.FunctionComponent<playerProps> = (props) => {
  const playerRef = useRef<any>()
  const equalizerRef = useRef<any>()
  const [equalizerInited, setEqualaizerInited] = useState(false)
  const CustomIcons = {
    play: (
      <PlayButton>
        <PlayBtn />
      </PlayButton>
    ),
    pause: (
      <PlayButton>
        <PauseBtn />
      </PlayButton>
    ),
  }

  useEffect(() => {}, [])

  const handleListen = () => {
    if (
      equalizerRef.current &&
      playerRef.current.audio.current &&
      !equalizerInited
    ) {
      setEqualaizerInited(true)
      const audioMotion = new AudioMotionAnalyzer(equalizerRef.current, {
        source: playerRef.current.audio.current,
        mode: 6,
        overlay: true,
        bgAlpha: 0,
        showScaleX: false,
        showPeaks: false,
        barSpace: 0.5,
      })

      const options = {
        bgColor: 'rgba(0,0,0,0.2)', // background color (optional) - defaults to '#111'
        dir: 'h', // add this property to create a horizontal gradient (optional)
        colorStops: [
          // list your gradient colors in this array (at least 2 entries are required)
          'white', // colors may be defined in any valid CSS format
          { pos: 0.6, color: '#fff' }, // use an object to adjust the position (0 to 1) of a color
          '#fff', // colors may be defined in any valid CSS format
        ],
      }

      // @ts-ignore
      audioMotion.registerGradient('white-grad', options)
      audioMotion.gradient = 'white-grad'
    }
  }

  return (
    <PlayerWrap animStarted={props.animStarted}>
      <AudioPlayer
        ref={playerRef}
        src={music}
        showJumpControls={false}
        customIcons={CustomIcons}
        onListen={handleListen}
      />
      <Equalizer ref={equalizerRef} />
    </PlayerWrap>
  )
}

export default Player

const PlayerWrap = styled.div<{ animStarted: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 80px;
  right: 80px;
  width: 287px;
  height: 84px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 15px 30px;
  opacity: 0.3;
  transition: 0.4s;
  z-index: ${({ animStarted }) => (animStarted ? '0' : '5')};
  ${({ theme }) => theme.adaptive.index.xl} {
    bottom: 68px;
    right: 68px;
    width: 238px;
    height: 69px;
    border-radius: 4px;
    padding: 12px 25px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    bottom: 61px;
    right: 61px;
    width: 214px;
    height: 62px;
    border-radius: 4px;
    padding: 11px 23px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    bottom: 54px;
    right: 54px;
    width: 190px;
    height: 55px;
    border-radius: 3px;
    padding: 10px 20px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    bottom: 43px;
    right: 43px;
    width: 152px;
    height: 44px;
    border-radius: 3px;
    padding: 8px 16px;
  }
  &:hover {
    opacity: 1;
  }
  .rhap_progress-section,
  .rhap_volume-container,
  .rhap_additional-controls {
    display: none;
  }
  .rhap_main-controls-button {
    display: block;
  }
  .rhap_container {
    width: auto;
    background: transparent;
    padding: 0;
    box-shadow: unset;
  }
  .rhap_stacked .rhap_controls-section {
    margin-top: 0;
  }
  .rhap_play-pause-button {
    width: auto;
    height: auto;
    font-size: 0;
  }
`

const Equalizer = styled.div`
  position: relative;
  width: 152px;
  height: 54px;
  margin-left: 50px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 127px;
    height: 45px;
    margin-left: 40px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 114px;
    height: 41px;
    margin-left: 36px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 102px;
    height: 36px;
    margin-left: 32px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 81px;
    height: 29px;
    margin-left: 26px;
  }
`

const PlayButton = styled.div`
  width: 29px;
  height: 25px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 24px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 22px;
    height: 19px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 19px;
    height: 17px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 15px;
    height: 13px;
  }
  svg {
    display: block;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`
