import React from 'react'
import styled from 'styled-components'
import { getSecretFile } from 'api/secret'

function Success() {
  const handleDownloadClick = async () => {
    const response = await getSecretFile()
    if (!response.ok) return alert('Unauthorized')
    const blob = await response.blob()
    var url = window.URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.href = url
    a.download = 'music.wav'
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove() //after
  }
  return (
    <Root>
      <Text>Help me, ALGO-Wan Kenobi. You're my only hope...</Text>
      <Button onClick={handleDownloadClick}>DOWNLOAD FILE</Button>
    </Root>
  )
}

export default Success

const Root = styled.div`
  position: relative;
  font-family: 'Aldrich';
  z-index: 2;
  max-width: 540px;
  width: 100%;
  background: #3c404a;
  border-radius: 6px;
  text-align: center;
  padding: 35px 45px;
`

const Text = styled.div`
  font-size: 14px;
  color: #9d9fa4;
  margin-bottom: 18px;
  text-transform: uppercase;
`

const Button = styled.button`
  color: #ffffff;
  width: 100%;
  text-transform: uppercase;
  background: linear-gradient(122.18deg, #557fe7 -8.19%, #8ba5e7 146.11%);
  border-radius: 3px;
  padding: 10px 0;
`
