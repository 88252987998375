import React from 'react'
import styled from 'styled-components'
import btnBorder from 'images/hierarchy/btn-border.svg'

const LaunchBtn: React.FunctionComponent = () => {
  return (
    <Button href={'https://app.algodao.fi/IDO'} target={'_blank'}>
      Launch App
    </Button>
  )
}

export default LaunchBtn

const Button = styled.a`
  position: relative;
  display: block;
  padding: 11px;
  width: 200px;
  height: 42px;
  outline: none;

  background: #ffffff;
  color: #292b33;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background: #d65251;
    color: #fff;
  }
  ${({ theme }) => theme.adaptive.index.xl} {
    padding: 9px;
    width: 182px;
    height: 38px;
    border-radius: 5px;
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding: 8px;
    width: 161px;
    height: 34px;
    border-radius: 4px;
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 151px;
    height: 32px;
    border-radius: 4px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding: 13px;
    width: 260px;
    height: 46px;
    border-radius: 7px;
    font-size: 16px;
    background: linear-gradient(
      90.5deg,
      #353842 0.25%,
      #2d3139 50.65%,
      #353842 99.93%
    );
    color: #fff;
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.15);
    margin: 20px auto 0;
  }
`
